import React, { useState, useEffect, useRef } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { Formik } from 'formik';
import Select from 'react-select';
import FocusError from "../components/FocusError";
import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";
import DatePickerSelect from '../components/DatePickerSelect';
import FilePreview from '../components/FilePreview';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import Modal from 'react-modal';
Modal.setAppElement('#root');

// Custom styles for react-modal
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding 			  : '0',
    borderRadius		  : '8px',
    boxShadow			  :    '0 0 10px rgba(0,0,0,.1)',
  }
};


const api_url = process.env.REACT_APP_GROED_API_URL;


//Default state
const defaultFormState = {
	inventory: '',
	name: '',
	type: '',
	products: [],
	line_items: [],
	date: moment().add(1,'days').toDate(),
}




// Default state
// const defaultFormState = {
// 	_id: undefined,
// 	order_number: '', //(for email receipt)
// 	name: 'Louisa',
// 	status: 'ordered', //draft, ordered, delivered, canceled
// 	created_date: new Date(),
// 	delivery_date: new Date(),
// 	inventory: {
// 		_id: '',
// 		...address,
// 	},
// 	line_items: [
// 		...ingredient //(or non-food).
// 	],
// 	total_excl_vat: 0,
// }


/**
 * Save Order
 */
const saveOrder = async (access_token, order_data) => {

	let config = {headers: { 'Authorization': `Bearer ${access_token}`}}
	const axiosPromise = axios.post(`${api_url}/order`, order_data, config);	
	
	return axiosPromise;
}

export const OrderNew = (props) => {
	
	// Variables from History (react router)
	const history = useHistory();

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// For accessing Formik values outsite the Formik Component
	const formRef = useRef();

	// Form state
	const [formData, setFormData] = useState(defaultFormState);

	// STATE: Invetory data
	const [cartData, setCartData] = useState([]);

	// STATE: Accounts
	const [accountData, setAccountData] = useState([]);

	// STATE: Invetory data
	const [inventoryData, setInventoryData] = useState([]);

	// STATE: Product data
	const [productData, setProductData] = useState([]);
	const [productsLoading, setproductsLoading] = useState(false);

	// STATE: Order data
	const [orderState, setOrderState] = useState({
		selected_inventory: false,
		orders: []
	});

	// STATE: search
	const [searchState, setSearchState] = useState({
		searchTerm: '',
		category: null,
	});

	// STATE: hiddenItems
	const [hiddenItems, setHiddenItems] = useState([]);

	// State: Modal (react-modal)
	const [modalIsOpen,setIsOpen] = React.useState(false);

	/**
  	 * Get data needed for the forms from DB
  	 */
	useEffect(() => {
		
		(async () => {

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get inventories
				const inventoryResponse = await axios.get(`${api_url}/inventory`, { headers: { 'Authorization': `Bearer ${access_token}`}});	
				const inventoryData = inventoryResponse.data.inventories.sort((a, b) => (a.name > b.name) ? 1 : -1)
				setInventoryData(inventoryData);

				// Get accounts
				const accountsResponse = await axios.get(`${api_url}/account`, {headers: { 'Authorization': `Bearer ${access_token}`}});
				setAccountData(accountsResponse.data.accounts);

				
			} catch (e) {
        		console.error(e);
      		}

		})();		

	}, [getAccessTokenSilently]);


	/**
  	 * Get product data from DB
  	 */
	useEffect(() => {
		
		const selected_inventory = orderState.selected_inventory;
		if (selected_inventory) {

			setproductsLoading(true);

			(async () => {

				try {
					
					// Get access token
					const access_token = await getAccessTokenSilently();

					const ingredientsResponse = await axios.get(`${api_url}/ingredient`, {
						headers: { 'Authorization': `Bearer ${access_token}`},
						params: {
							inventories: selected_inventory.value,
						}
					});
					setProductData(ingredientsResponse.data.ingredients);
					setproductsLoading(false);
					console.log(ingredientsResponse.data.ingredients);

				} catch (e) {
		        	console.error(e);
		      	}

			})();	
		}


	}, [orderState.selected_inventory]);



	/**
  	 * Make products form ready
  	 */
	useEffect(() => {


		const form_line_items = productData.map(product => {

			const line_item = {
				...product,
				amount: 0,
			}

			return line_item;
		})

		form_line_items.sort((a, b) => (a.name > b.name) ? 1 : -1);

		setFormData(prevState => ({
			...prevState,
			inventory: orderState.selected_inventory,
			products: form_line_items,
		}))

	}, [productData]);


	/**
  	 * Change filtered list data, everytime search term or ingredient data changes
  	 */
	useEffect(() => {
	
		let hidden = [];
		
		console.log('filter results');

		for (const item of [...productData]) {

			let hide_from_search = false;
			let hide_from_category = false;

			// See if search term matches (add to hidden if it doesnt)
			if (item.name.search(new RegExp(searchState.searchTerm, "i")) === -1) {
				hide_from_search = true;
			} 

			// See if category matches
			if (searchState.category && searchState.category !== '') {
				
				if (searchState.category.label !== item.account_id.name) {
					hide_from_category = true;
				}	

			}

			if (hide_from_search || hide_from_category) {
				hidden.push(item._id);
			}

		}
		
		setHiddenItems(hidden);
	
	}, [searchState, productData]);	


	/**
  	 * Change order data everytime the cart changes
  	 */
	useEffect(() => {
	
		console.log('cart changed');
		console.log(formRef.current);
		let orders = [];

		for (const item of [...cartData]) {
		
			const supplier_id = item.supplier_id._id;

			const order_with_supplier_id = orders.findIndex((order => order.supplier._id === supplier_id));


			if (order_with_supplier_id === -1) {
				
				orders.push({
					inventory_id: formRef.current.values.inventory.value,
					inventory_name: formRef.current.values.inventory.label,
					supplier: item.supplier_id,
					date: formRef.current.values.date,
					name: formRef.current.values.name,
					comments: '',
					line_items: [item]
				})

			} else {
				orders[order_with_supplier_id].line_items.push(item);
			}

		}

		console.log(orders);

		setOrderState(prevData => ({
			...prevData,
			orders: orders
		}));
	
	}, [cartData]);	




	/**
	 * Handle start date select
	 */
	const handleDateSelect = (date, setFieldValue) => {
		setFieldValue("date", date);
	}


	// /**
 //  	 * Save Order
 //  	 */
	// const saveOrder = async (data) => {

	// 	console.log(data);

	// 	try {
	// 		// Get access token
	// 		const access_token = await getAccessTokenSilently();

	// 		let config = {headers: { 'Authorization': `Bearer ${access_token}`}}
	// 		const axiosPromise = axios.post(`${api_url}/order`, data, config);	
	// 		const response = axiosPromise;
			
	// 		return response;

	// 	} catch (e) {
	// 		console.error(e);
	// 	}
	// }


	/**
	 * Confirmation step
	 */
	const confirmOrder = () => {
		setIsOpen(true);		
	}

	/**
	 * Handle form submission
	 */
	const sendData = async () => {

		const access_token = await getAccessTokenSilently();

		console.log('sendData');

		// data.status = "draft";

		const saveOrderPromises = [];

		for (const order of orderState.orders) {
			console.log('order', order);
			
			let line_items = order.line_items.map(item => {
				return {
					item: {
						_id: item._id,
						onModel: 'Ingredient',
						name: item.name,
						sku: item.sku,
						account: item.account_id,
						inventory_unit_of_measure: item.inventory_unit_of_measure,
						pack_unit_of_measure: item.pack_unit_of_measure,
						inventory_units_per_pack: item.inventory_units_per_pack,
						price_per_pack: item.price_per_pack,
					},
					amount: item.amount,
					value: item.amount * item.price_per_pack,
				}
			});

			

			// Prepare order data
			const order_data = {
				inventory: order.inventory_id,
				name: order.name,
				comments: order.comments,
				delivery_date: order.date,
				supplier: order.supplier._id,
				status: 'ordered',
				line_items: line_items,
			}
			const orderPromise = saveOrder(access_token, order_data);
			saveOrderPromises.push(orderPromise);
		}

		Promise.all(saveOrderPromises)
			.then( values => {
				console.log(values);

				history.push(`/inventory/orders`);
			})
			.catch(error => {
				console.log(error);
			}
		);

	}

	/**
	 * Handle searchbar value
	 */
  	const handleSearchChange = (event) => {
  		const target = event.target;
  		const value = target.type === 'checkbox' ? target.checked : target.value;
  		//const name = target.name;

  		// Set state
  		setSearchState(prevState => ({
  			...prevState,
  			searchTerm: value
  		}));
  	}


  	/**
	 * Handle comments value
	 */
  	const handleCommentsChange = (event, index) => {
  		const target = event.target;
  		const value = target.type === 'checkbox' ? target.checked : target.value;

  		const orders = {...orderState}; //Copy current state
  		orders.orders[index].comments = value;
  		setOrderState(orders);
  	}



  	/**
	 * Open Modal
	 */
  	const openModal = () => {
			
  		// setModalData({
  		// 	title: `Stort lagerniveau for "${name}" !`,
  		// 	message: `Den samlede lagermængde (${total_amount}) overstiger den planlagte max (${par_level*inventory_units_per_pack}). Dobbelttjek venligst at du har tastet rigtigt. Hvis du er sikker på at din indtastning er korrekt, fortsætter du bare :)`,	
  		// })

		setIsOpen(true);
  	}

  	/**
	 * Do something after Modal open
	 */
  	const afterOpenModal = () => { }
	
	/**
	 * Close modal
	 */ 
	const closeModal = () => {
		setIsOpen(false);
	}

	

	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Inventory"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">
					
					<Modal
			          isOpen={modalIsOpen}
			          onAfterOpen={afterOpenModal}
			          onRequestClose={closeModal}
			          style={customStyles}
			          contentLabel="Example Modal"
			         >
						<div className="order-form-modal">
							
							<div className="order-form-modal-header">
								<h2 className="modal-title">Bekræft bestilling ({orderState.orders.length} ordrer)</h2>
							</div>

							<div className="order-form-modal-body">
								
								{
									orderState.orders.length <= 0 && (
										<p>Din kurv er tom</p>
									)	
								}

								{
									orderState.orders.map((order, i) => {
										console.log(order.comments);
										return (
											<FilePreview
												key={i}
												className=""
												icon={() => { return(
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M446 81.7L199.2 328.4 65.5 194.6l-52 51.9 133.8 133.8 51.9 51.9 298.7-298.6z"/></svg>
												)}}
												label={order.label}
												pretitle={`Leveringsdato: ${moment(order.date).format('DD. MMM YYYY')}`}
												title={`${order.supplier.name} (${order.line_items.length})`}
												subtitle=""
												data={order.line_items.map(line_item => {
													return {
														label: `${line_item.name} (${line_item.amount} ${line_item.pack_unit_of_measure.label})`,
														value: line_item.amount * line_item.price_per_pack,
													}

												})}
												comments={order.comments}
												commentsChange={(e) => handleCommentsChange(e, i)}
											/>
										)
									} )
								}

							</div>

							<div className="order-form-modal-footer">
								<button className="button" onClick={sendData}>Bekræft og send</button>
								<button className="button button--ghost" onClick={closeModal}>Tilbage</button>
							</div>

						</div>
			        </Modal>

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="breadcrumbs">/lager/bestil vare</div>

							<div className="page-content-header">
								<h1 className="page-title">Bestil vare</h1>
							</div>
							<br/><br/>

							<Formik
								innerRef={formRef}
								initialValues={{
									...formData
								}}
								enableReinitialize={true}

								validate={ values => {
									const errors = {};


									setOrderState(prevState => ({
										...prevState,
										selected_inventory: values.inventory,
									}));
														

									// Required fields
									const required_fields = [
										'inventory',
										'name',
										'date',
									];
									
									for(const required_field of required_fields) {
										if (values[required_field] === '') {
											errors[required_field] = 'Dette felt er påkrævet';
										}	
									}

									return errors;
								}}
							   
								onSubmit={(values, { setSubmitting }) => {
									confirmOrder(values);
							   }}
							>

							{({
								 values,
								 errors,
								 touched,
								 handleChange,
								 handleBlur,
								 handleSubmit,
								 isSubmitting,
								 setFieldValue,
								 resetForm,
								 isValid,
								 dirty,
								 /* and other goodies */
							}) => (
								<form onSubmit={handleSubmit}>
									
									<div className="field-section order-form-details-field-section">

										<div className="order-form-details-field" data-field="inventory">
											<div className="standard-field-label-container">
												<label className="standard-field-label">Lager</label>
											</div>
											<div className="standard-field-input-container">
												<Select
													styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
													placeholder="Vælg lager"
													value={values.inventory}
													onChange={option => {
														setFieldValue("inventory", option);
													}}
													onBlur={handleBlur}
													options={ inventoryData.map(inventory => ({ value: inventory._id, label: inventory.name}) ) }
												/>
											</div>
										</div>

										<div className="order-form-details-field" data-field="name">
											<div className="standard-field-label-container">
												<label className="standard-field-label">Navn</label>
											</div>
											<div className="standard-field-input-container">
												<input type="text" className="standard-field-input" name="name" placeholder="Dit navn" value={values.name} onChange={handleChange} onBlur={handleBlur} />
											</div>
										</div>

										<div className="order-form-details-field" data-field="zipcode">
											<div className="standard-field-label-container">
												<label className="standard-field-label">Dato</label>
											</div>
											<div className="standard-field-input-container">
												<input type="hidden" className="standard-field-input" name="date" value={moment(values.date).format('YYYY-MM-DD H:m')} onChange={handleChange} onBlur={handleBlur}/>
												<DatePickerSelect date={moment(values.date).toDate()} changeHandler={(date) => handleDateSelect(date, setFieldValue)}/>
											</div>	  

										</div>
									</div>

									<div className="field-section order-form-item-field-section">

										<div className="field-section order-form-line-items">
										


											<div className="filter-box">

												<div className="filter-box-field filter-box-field--search">
													
													<div className="item-list-searchfield-container">
														<div className="item-list-searchfield-icon">
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M8.8 8.1c.7-.9 1.1-2 1.1-3.1 0-2.7-2.2-5-5-5S0 2.2 0 5c0 2.7 2.2 5 5 5 1.1 0 2.2-.4 3.1-1.1l3.2 3.2.7-.7-3.2-3.3zM5 8.9c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4c-.1 2.2-1.9 4-4 4z" fill="#020302"/></svg>
														</div>
														<input type="text" className="item-list-search-input" name="searchValue" value={searchState.searchTerm} onChange={handleSearchChange} placeholder="Søg"/>
													</div>

												</div>

												<div className="filter-box-field filter-box-field--filler"></div>

												<div className="filter-box-field filter-box-field--account">
													
													<Select
														styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
														placeholder="Vælg konto"
														isClearable={true}
														onChange={option => {
															setSearchState(prevState => ({
																...prevState,
																category: option
															}));
														}}
														options={
													 		accountData.map(account => {
													 			return {
													 				value: account._id, label: account.name,
													 			}
													 		})
													 	}
													/>
													
												</div>

											</div>



											<div className="field-section order-form-line-items-table-container">

												{
													productsLoading && (
														<Loading />
													)
												}
											

												{
													values.products.length <= 0 && !orderState.selected_inventory && !productsLoading && (
														<div className="order-form-line-items-no-items"><p>Vælg lager du gerne vil bestille vare til</p></div>
													)
												}

												{
													values.products.length <= 0 && orderState.selected_inventory && !productsLoading && (
														<div className="order-form-line-items-no-items"><p>Ingen produkter knyttet til afdelingen</p></div>
													)
												}

												{
													values.products.length > 0 && !productsLoading && (
														<table className="order-form-line-items-table">

															<thead>
																<tr>
																	<th>Produkt</th>
																	<th className="order-table-column-product-count">Antal</th>
																</tr>
															</thead>

															<tbody>
															
																{
																	values.products.map((product, i) => {

																		const hideItemClass = hiddenItems.includes(product._id) ? 'order-table-row--hide' : '';

																		return (
																			<tr key={i} className={`${hideItemClass}`}>
																				<td>
																					<div className="order-table-field-block">
																						<div className="order-table-field-block-title">{product.name}</div>
																						<div className="order-table-field-block-subtitle">{product.supplier_id.name} - DKK {product.price_per_pack} / {product.pack_unit_of_measure.label}</div>
																					</div>
																				</td>	
																				<td className="order-table-column-product-count">
																					<div className="order-table-field-product-count">
																						<div className="field-with-suffix">
																							<input type="number" min="0" step="1" className="input-field-with-suffix" placeholder="Mængde" value={values.products[i].amount} name={`products[${i}].amount`} 
																								onChange={ 
																									e => {
																										
																										let cartItems = [...cartData];
																										const target = e.target;
																										const value = target.value;
																										
																										const product_index_in_cart = cartData.findIndex(p => p._id === product._id);

																										if (product_index_in_cart === -1 && value > 0) {
																											// Add item
																											cartItems.push({...product, amount: value});

																										} 

																										else if(product_index_in_cart !== -1 && value > 0) {
																											// update item.
																											cartItems[product_index_in_cart].amount = value;
																										} 

																										else if(product_index_in_cart !== -1 && value <= 0) {
																											// Remove item.
																											cartItems = cartItems.filter(item => item._id !== product._id);
																										} 

																										
																										cartItems.sort((a, b) => (a.name > b.name) ? 1 : -1)

																										setCartData(cartItems);

																										handleChange(e);
																									}
																								} 
																								onBlur={handleBlur} 
																							/>
																							<div className="field-suffix">{product.pack_unit_of_measure.label} ( {product.inventory_units_per_pack} {product.inventory_unit_of_measure.label} )</div>
																						</div>
																					</div>
																				</td>
																			</tr>
																		)
																	})
																}
																
															
															</tbody>


														</table>
													)	
												}
												

											</div>	

										</div>

										<div className="field-section order-form-checkout">
										
											<div className="field-section order-form-cart">
												<div className="order-form-cart-header">
													<h3 className="order-form-heading">
														Kurv <div className="cart-counter">({cartData.length})</div>
													</h3>
												</div>
												<div className="order-form-cart-body">
													{
														cartData.map((line_item, i) => {
															return (
																<div className="order-form-cart-line-item" key={i}>
																	<div className="order-form-cart-line-item-main">
																		<div className="order-form-cart-line-item-name">
																			{line_item.name}
																		</div>
																		<div className="order-form-cart-line-item-meta">
																			{line_item.amount} {line_item.pack_unit_of_measure.label}
																		</div>
																	</div>
																	<div className="order-form-cart-line-item-price">
																		{(line_item.amount * line_item.price_per_pack).toLocaleString("da-DK")} kr.
																	</div>
																</div>

															)
														})
													}
												</div>
												<div className="order-form-cart-footer">
													<div className="order-form-cart-footer-total">
														<div className="order-form-cart-footer-total-label">
															TOTAL
														</div>
														<div className="order-form-cart-footer-total-value">
															{cartData.reduce((acc, current_value) => {
																const cost = current_value.price_per_pack * current_value.amount;
																acc += cost;
																return acc;
															}, 0).toLocaleString("da-DK")}
														</div>	
													</div>
												</div>
											</div>
											
											<button type="submit" className="order-form-submit button button--medium" disabled={!(isValid && dirty)}>Gennemfør ordre</button>			
										</div>


									</div>
									
									
						
									<FocusError/>				
								</form>
							)}


							</Formik>

						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(OrderNew, {
  onRedirecting: () => <Loading />,
});
