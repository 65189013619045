import React from "react";
import { useHistory } from "react-router-dom";

export const PrepNoSelection = () => {

	// Variables from History (react router)
	const history = useHistory();

	// Create new ingredient
  	const newRecipe = () => {
  		//@TODO Check if unsaved edits have been to an existing document. 

  		// Go to new ingredient URL. 
  		history.push("/menu/recipes/new");
  	}

	return (
		<div className="intro-section">

			<div className="intro-section-content">
			
				<div className="intro-section-image">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M438.9 68.8c-.7-18.8-13.2-43.6-39.2-53.3-14.7-5.5-31.4-5-48.4 1.4-17.9 6.8-36.4 20.2-55 40-17.1 18.1-30.6 39-40.1 62-9.6-23-23-43.9-40.1-62-18.6-19.8-37.1-33.2-55-40-17-6.4-33.7-6.9-48.4-1.4-26 9.8-38.5 34.5-39.2 53.3-1.2 33.6 16 71.4 48.6 106.2 26.1 27.9 60 51.6 97.3 68.2-5 1.7-9.9 3.8-14.7 6.1-9.7 4.9-19.5 11.3-28 19.8-15.8 15.8-27.5 38.8-27.5 72.3 0 103.1 111.1 100 111.1 165.1 0-16.4 7-28.4 17.5-38.9 16.8-16.8 42.4-29.6 62.6-49.8 17.5-17.5 30.9-40.5 30.9-76.3 0-51.6-27.8-78.3-55.5-92.1-6.3-3.1-12.7-5.7-19.3-7.7 33.1-15.2 63.4-36 87.8-60.3 2.1-2.1 4.1-4.2 6.1-6.3 32.5-34.9 49.8-72.6 48.5-106.3zm-328.7 1.4c.1-4 2.5-10.2 7.3-15 2.2-2.2 4.9-4.1 8.1-5.3 15.6-5.9 38.8 5.9 63.7 32.2 25.4 27 40.8 60.3 45.5 99 1.4 11.1 1.6 21.2 1.4 29.4-78.1-33.9-127.5-97.2-126-140.3zm189.2 212.1c24 11.9 35.1 30.7 35.1 59.2 0 26.6-10.2 40.4-20.1 50.3-8.4 8.4-18.6 15.6-29.4 23.3-8.3 5.9-16.8 11.9-24.7 18.7-7.9-6.8-16.4-12.8-24.7-18.7-14.3-10.1-25.7-18.6-34-28.3h34.9v-27.6h-48.7c-1.1-5.3-1.7-11.2-1.7-17.8v-.5h57.3v-27.6h-52.5c2.8-6.9 6.7-13 11.9-18.2 4.9-4.9 11.1-9.3 18.4-12.9 24.4-12.1 53.6-12.1 78.2.1zm-23.2-71.8c-.2-8.2 0-18.2 1.4-29.4 4.7-38.7 20-72 45.5-99C348 55.8 371.2 44 386.8 49.9c10.1 3.8 15.2 14.3 15.4 20.3 1.6 43.1-47.9 106.4-126 140.3z"/></svg>
				</div>

				<h1 className="intro-section-title">Opskrifter</h1>

				<div className="intro-section-description">
					<p>"Opskrifter" på de retter du sælger.</p>
				</div>

				<div className="intro-section-action">
					<button className="button button--wide" onClick={ ()=> newRecipe() }>Opret Opskrift</button>
				</div>
			</div>
		</div>
	); 
};

export default PrepNoSelection;
