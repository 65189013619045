import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const api_url = process.env.REACT_APP_GROED_API_URL;

export const Inventory = (props) => {
	
	// Variables from History (react router)
	const history = useHistory();

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// STATE: Invetory data
	const [inventoryData, setInventoryData] = useState([]);

	/**
  	 * Get data from DB
  	 */
	useEffect(() => {
		
		(async () => {

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get inventories
				const inventoryResponse = await axios.get(`${api_url}/inventory`, { headers: { 'Authorization': `Bearer ${access_token}`}});	
				const inventoryData = inventoryResponse.data.inventories.sort((a, b) => (a.name > b.name) ? 1 : -1)
				setInventoryData(inventoryData);

				
			} catch (e) {
        		console.error(e);
      		}

		})();		

	}, [getAccessTokenSilently]);

	// Create new Prep
  	const newInventory = () => {
  		//@TODO Check if unsaved edits have been to an existing document. 

  		// Go to new ingredient URL. 
  		history.push("/settings/inventories/new");
  	}

	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Inventory"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">
					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="breadcrumbs">/indstillinger/lagre</div>
								

							<div className="page-content-header">
								<h1 className="page-title">Lagre</h1>

								<button type="button" className="button" onClick={ ()=> newInventory() }>Opret nyt lager</button>
							</div>
							
							
							<div className="page-description">
								<p>
									Her kan du oprette de forskellige lokationer hvor du har en lagerbeholdning som skal lagerstyres. 
								</p>
							</div>

							<div className="settings-item-list">

								<ul className="settings-items">
									{
										inventoryData.map((item, i) => {
											return (
												<li key={i} className="settings-item">
													
														<div className="settings-item-number">{i+1}</div>
														<div className="settings-item-title">
															<Link to={`/settings/inventories/${item._id}`}>{item.name}</Link>
														</div>
														{/*
														<div className="settings-item-edit">
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M146.6 368.2l10.4-99.5c1-9.4 5.4-18.5 12.5-25.6L374.8 37.7c10.3-10.3 24.5-15.5 38-13.9l1.7.2c39.4 4.9 68.8 33.2 75.1 72.2l1.3 8c2.1 12.9-2.7 26.6-12.7 36.6L273.7 345.2c-7 7-16.1 11.4-25.5 12.4l-101.6 10.6zM407.7 63.5c-.7 0-2.7.5-4.6 2.5L197.7 271.4c-.7.7-.9 1.3-1 1.5l-5.2 50.4 52.7-5.5c.1 0 .7-.3 1.3-.9l204.4-204.4c1-1 1.3-1.9 1.4-2.3l-1.2-7.7c-3.4-21.6-18.6-36.1-40.5-38.8l-1.7-.2h-.2z"/><path d="M428.2 490.7H54.9c-20.3 0-36.7-16.5-36.7-36.7V81.1c0-20.3 16.5-36.7 36.7-36.7h178v40H58.2v366.4H425V301.3h40V454c0 20.2-16.5 36.7-36.8 36.7z"/></svg>
														</div>
														*/}
												</li>
											)
										})
									}
								</ul>

							</div>





						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Inventory, {
  onRedirecting: () => <Loading />,
});
