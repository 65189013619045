import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const Login = () => {

	const {
	  isAuthenticated,
	  loginWithRedirect,
	  //logout,
	} = useAuth0();

	/**
	 * componentDidMount hook
	 */ 
	useEffect(() => {
	  document.body.classList.add('page-template-login-page');

	  // returned function will be called on component unmount 
	  return () => {
	    document.body.classList.remove('page-template-login-page');
	  }
	}, [])


	return (
		<div className="login-split-view">


			<div className="login-split-view-main">

				<div className="login-split-view-main-content">
					
					<div className="login-logo">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.1 48.7"><path d="M40.1 0v10.7h-3.3c-.8 0-1.2-.2-1.3-.7s-.4-1.9-.7-4.5H23.4v38.2s.3 0 .8.1c1.8.3 2.9.5 3.4.7.5.1.7.5.7 1.2v2.9H11.8v-2.9c0-.7.4-1.2 1.2-1.4s2-.4 3.7-.7V5.5H5.2c-.2 2.6-.4 4.1-.6 4.6-.1.5-.5.7-1.4.7H0V0h40.1z" fill="#020302"/></svg>
					</div>

					<h1 className="login-title">Inventory management for restaurants</h1>

					<div className="login-description">
						<p>
						Understand your menu in terms of cost-benefit, 
						nutritional composition and environmental impact. 
						</p>
						<p>
						Handle ordering, price-checking Inventory counts within a single application
						</p>
						<p>
						Analyze waste, and find out where to best gain value from your efforts.
						</p>
					</div>

					{isAuthenticated && (
						<Link className="button button--light button--wide" activeClassName="current-menu-item" to="/dashboard">Go to dashboard</Link>
					)}

					{!isAuthenticated && (
						<button className="button button--light button--wide" onClick={ () => loginWithRedirect({})}>Login</button>
					)}

				</div>

			</div>

			<div className="login-split-view-side">
				
			</div>
			
		</div>
	);
};

export default Login;