import React from "react";
import { NavLink } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";



export const Navigation = (props) => {
	
	const {
	  logout,
	} = useAuth0();


	const toggleNavigationClass = () => {
		document.body.classList.toggle('navigation-open');
	}


  	const logoutWithRedirect = () => logout({
  		returnTo: window.location.origin,
  	});


	return (
		<div className="navigation-container">
			<div className="navigation-bar-mobile">
				<div className="navigation-bar-mobile-trigger-container">
					<div className="navigation-bar-mobile-trigger" onClick={() => toggleNavigationClass()}>

						<div className="navigation-bar-mobile-trigger-line-wrapper">
							<div className="navigation-bar-mobile-trigger-line"></div>
							<div className="navigation-bar-mobile-trigger-line"></div>
							<div className="navigation-bar-mobile-trigger-line"></div>
						</div>
					</div>
				</div>
				
				<div className="navigation-bar-mobile-heading">{props.page_title}</div>
			</div>

			<div className="navigation">
				
				<div className="menu-items-container">
					<ul>
						<li className="menu-item">
							<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/dashboard">
								<div className="menu-item-icon">										
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M382.5 8.2H128.6C61.9 8.2 7.3 62.8 7.3 129.5v253.9c0 66.7 54.6 121.3 121.3 121.3h253.9c66.7 0 121.3-54.6 121.3-121.3V129.5C503.7 62.8 449.2 8.2 382.5 8.2zM77.2 78.1c13.8-13.8 32.1-21.4 51.4-21.4h253.9c19.3 0 37.6 7.6 51.4 21.4 13.8 13.8 21.4 32.1 21.4 51.4v42.1H55.8v-42.1c0-19.3 7.6-37.6 21.4-51.4zm0 356.6c-13.8-13.8-21.4-32.1-21.4-51.4V220.1h107.3v236.1h-34.5c-19.3 0-37.6-7.6-51.4-21.5zm356.6 0c-13.8 13.8-32.1 21.4-51.4 21.4H211.6v-236h243.7v163.3c-.1 19.3-7.7 37.5-21.5 51.3z"/></svg>
								</div>
								<div className="menu-item-label">Dashboard</div>
							</NavLink>
						</li>
						<li className="menu-item submenu-container-open">
							<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/menu/ingredients" isActive={(match, location) => location.pathname.startsWith('/menu') } >
								<div className="menu-item-icon">
									<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500.71 506.99"><path d="M494.78 231.18c-7.19-15.87-20.7-28.63-39.08-36.89-19.34-8.69-44.42-12.66-74.54-11.79a227 227 0 00-80.1 17.15 227 227 0 0017.15-80.1c.87-30.12-3.09-55.2-11.78-74.54-8.26-18.38-21-31.89-36.89-39.08-28-12.7-57.28-3.15-72.61 11.09-27.35 25.48-43.4 68.58-45.2 121.48-1.44 42.41 6.59 87.52 22.84 129.81a136.53 136.53 0 00-16.34-6.69 118.14 118.14 0 00-37.57-6.44c-24.79 0-52 8.87-78.26 35.12C-38.52 371.24 51.06 455.94 0 507c12.84-12.84 27.81-16.79 44.36-16.79 26.32 0 56.52 10 88.22 10 27.46 0 56-7.54 84.16-35.65 40.41-40.42 39.62-83.16 28.62-115.79a138.11 138.11 0 00-9.16-21.19c37.94 14.08 78 21.57 116.23 21.57 3.27 0 6.53 0 9.77-.17 52.91-1.79 96-17.84 121.47-45.19 14.26-15.29 23.81-44.59 11.11-72.61zM224.72 46.93c3.29-3.06 10-6 17.46-6a25.41 25.41 0 0110.52 2.21c16.87 7.65 25.87 35.08 24.71 75.26-1.19 41.13-15.31 79.27-42 113.36a221.24 221.24 0 01-22 24.14c-34.59-87.88-23.77-176.4 11.31-208.97zm-36.89 388.81c-20.88 20.88-39.67 23.7-55.3 23.7-13.14 0-26.85-2.33-41.35-4.8-11.12-1.88-22.52-3.82-34.1-4.72-.9-11.57-2.83-22.95-4.72-34.09-3.25-19.15-5.57-34.82-4.51-48.9l27.38 27.37 21.64-21.64-38.2-38.16a95.18 95.18 0 0112.59-15.33l.39-.37 44.95 45 21.64-21.65-41.18-41.23a58.92 58.92 0 0123.6-4.92 77.86 77.86 0 0124.58 4.32 98.06 98.06 0 0161.43 61.43c9.46 28.17 3.47 51.68-18.84 73.99zm266-159.74c-32.61 35.08-121.09 45.89-208.94 11.23a221.24 221.24 0 0124.14-22c34.09-26.66 72.23-40.78 113.36-42 40.19-1.15 67.61 7.84 75.26 24.71 4.92 11.03.61 23.25-3.86 28.06z" fill="#272525"/></svg>
								</div>
								<div className="menu-item-label">Menu</div>								
							</NavLink>
							<div className="submenu-container">
								<ul className="submenu">
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/menu/ingredients">Ingredienser</NavLink>	
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/menu/prep">Prep</NavLink>	
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/menu/recipes">Opskrifter</NavLink>
									</li>
								</ul>
							</div>
						</li>

						<li className="menu-item">
							<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/inventory/status" isActive={(match, location) => location.pathname.startsWith('/inventory') }>
								<div className="menu-item-icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M445.4 104.4L292.6 16.2c-22.6-13-50.6-13-73.1 0L66.6 104.4C44 117.4 30 141.7 30 167.8v176.5c0 26 14 50.3 36.6 63.3l152.8 88.2c11.3 6.5 23.9 9.8 36.6 9.8 12.6 0 25.3-3.3 36.6-9.8l152.8-88.2c22.6-13 36.6-37.3 36.6-63.3V167.8c0-26.1-14-50.4-36.6-63.4zm-205.6-53c5-2.9 10.6-4.3 16.2-4.3s11.2 1.4 16.2 4.3l148.7 85.9-43.5 25.1-164.9-95.2 27.3-15.8zm107.9 202.2v-27l15.8-9.1v27l-15.8 9.1zM256 232.5L91.1 137.3l46.3-26.8 164.9 95.2-46.3 26.8zm76.9-44.4L168 92.9l14-8.1L346.9 180l-14 8.1zm-246 184.2c-10-5.8-16.2-16.5-16.2-28.1V172.5l164.9 95.2v190.4L86.9 372.3zm338.2 0l-148.7 85.9V267.8l40.8-23.6v62.2l76.9-44.5v-62.1l47.2-27.3v171.7c0 11.6-6.2 22.4-16.2 28.1z"/></svg>
								</div>
								<div className="menu-item-label">Lager</div>
							</NavLink>
							<div className="submenu-container">
								<ul className="submenu">
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/inventory/status">Lagerbeholdning</NavLink>	
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/inventory/counts">Optællinger</NavLink>	
									</li>
									{/*
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/inventory/events">Lager events</NavLink>
									</li>
									*/}
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/inventory/order">Bestil vare</NavLink>
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/inventory/orders">Ordrer</NavLink>
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/inventory/receive-orders">Modtag vare</NavLink>
									</li>
									{/*
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/inventory/move">Flyt vare</NavLink>
									</li>
									*/}
								</ul>
							</div>
						</li>
						<li className="menu-item submenu-container-open">
							<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/reports/stock-reconciliation" isActive={(match, location) => location.pathname.startsWith('/reports') } >
								<div className="menu-item-icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M506.1 486.4H370.9V25.6h135.2v460.8zm-95.2-40h55.2V65.6h-55.2v380.8zM141.1 486.4H5.9V236h135.2v250.4zm-95.2-40h55.2V276H45.9v170.4zM323.6 486.4H188.4V125.5h135.2v360.9zm-95.2-40h55.2V165.5h-55.2v280.9z"/></svg>
								</div>
								<div className="menu-item-label">Rapporter</div>								
							</NavLink>
							<div className="submenu-container">
								<ul className="submenu">
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/reports/stock-reconciliation">Lagerafstemning</NavLink>
									</li>
								</ul>
							</div>
						</li>

						<li className="menu-item">
							<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/settings/inventories" isActive={(match, location) => location.pathname.startsWith('/settings') }>
								<div className="menu-item-icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 506.1c-36.5 0-68.4-20.8-83.1-54.2-7.8-17.6-25.8-28-45-26-36.3 4-70.2-13.3-88.5-44.9-18.3-31.6-16.2-69.6 5.4-99.1 11.4-15.5 11.4-36.4 0-51.9-21.6-29.5-23.6-67.4-5.4-99.1S91.6 82.1 127.9 86c19.1 2.1 37.2-8.4 45-26C187.6 26.7 219.5 5.9 256 5.9c36.5 0 68.4 20.8 83.1 54.2 7.8 17.6 25.8 28 45 26 36.3-4 70.2 13.3 88.5 44.9 18.3 31.6 16.2 69.6-5.4 99.1-11.4 15.5-11.4 36.4 0 51.9 21.6 29.5 23.6 67.4 5.4 99.1-16.5 28.6-45.9 45.4-78.2 45.4-3.4 0-6.8-.2-10.3-.6-19.1-2.1-37.2 8.4-45 26-14.7 33.4-46.6 54.2-83.1 54.2zM132.8 378.8c35.7 0 68.3 20.9 83 54.2 7.2 16.4 22.3 26.2 40.2 26.2 17.9 0 33-9.8 40.2-26.2 16-36.4 53.4-58 92.9-53.7 17.8 1.9 33.8-6.2 42.8-21.7s8-33.5-2.6-47.9c-23.5-32.1-23.5-75.2 0-107.3 10.6-14.5 11.6-32.4 2.6-47.9s-25-23.7-42.8-21.7c-3.3.4-6.7.5-10 .5-35.7 0-68.3-20.9-83-54.2-7.1-16.5-22.2-26.3-40.1-26.3-17.9 0-33 9.8-40.2 26.2-16.1 36.4-53.4 58-92.9 53.7-17.9-2-33.9 6.2-42.9 21.7s-8 33.5 2.6 47.9c23.5 32.1 23.5 75.2 0 107.3-10.6 14.5-11.5 32.5-2.6 48 9 15.5 25 23.7 42.8 21.7 3.4-.3 6.7-.5 10-.5z"/><path d="M256 337.6c-45 0-81.6-36.6-81.6-81.6s36.6-81.6 81.6-81.6 81.6 36.6 81.6 81.6-36.6 81.6-81.6 81.6zm0-125.7c-24.3 0-44.1 19.8-44.1 44.1s19.8 44.1 44.1 44.1 44.1-19.8 44.1-44.1-19.8-44.1-44.1-44.1z"/></svg>
								</div>
								<div className="menu-item-label">Indstillinger</div>
							</NavLink>
							<div className="submenu-container">
								<ul className="submenu">
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/settings/inventories">Lagre</NavLink>
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/settings/suppliers">Leverandører</NavLink>	
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/settings/categories">Kategorier</NavLink>	
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/settings/units">Egne enheder</NavLink>	
									</li>
								</ul>
							</div>
						</li>
						
						<li className="menu-item">
							<button href="#" className="menu-item-anchor" onClick={() => logoutWithRedirect()}>
								<div className="menu-item-icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M252.7 229.4c-13.5 0-24.4-10.9-24.4-24.4V43.3c0-13.5 10.9-24.4 24.4-24.4s24.4 10.9 24.4 24.4V205c0 13.4-10.9 24.4-24.4 24.4z"/><path d="M256 493.2c-58.4 0-113.4-23-154.7-64.6-41.2-41.6-64-96.9-64-155.8 0-65.6 28.7-127.4 78.8-169.5 10.3-8.7 25.8-7.3 34.4 3 8.7 10.3 7.3 25.8-3 34.4-39 32.7-61.4 80.8-61.4 132 0 94.6 76.1 171.5 169.7 171.5s169.7-76.9 169.7-171.5c0-51.2-22.4-99.3-61.4-132-10.3-8.7-11.7-24.1-3-34.4 8.7-10.3 24.1-11.7 34.4-3 50.1 42 78.8 103.8 78.8 169.5 0 58.8-22.7 114.1-64 155.8-40.9 41.6-95.9 64.6-154.3 64.6z"/></svg>
								</div>
								<div className="menu-item-label">Log ud</div>
							</button>
						</li>

						
					</ul>
				</div>

			</div>
		</div>			
	); 
};

export default Navigation;
