import React, { useState, useEffect } from "react";
import { Route, Switch, Link, useHistory } from "react-router-dom";
import axios from 'axios';

import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

import IngredientNoSelection from "../components/IngredientNoSelection";
import IngredientForm from "../components/IngredientForm";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

// import LocalIngredientsData from "../data/ingredients";
//console.log(LocalIngredientsData.ingredients);

const api_url = process.env.REACT_APP_GROED_API_URL;


export const Ingredients = (props) => {

	// Variables from History (react router)
	const history = useHistory();

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// STATE: search
	const [searchState, setSearchState] = useState({
		searchTerm: '',
	});

	// STATE: Recipe
	// const [recipeData, setRecipeData] = useState([]);
	// const [activeRecipeData, setActiveRecipeData] = useState([]);

	// // STATE: Prep
	// const [prepData, setPrepData] = useState([]);

	
	// STATE: ingredients
	const [ingredientData, setIngredientData] = useState([]);
	const [activeIngredientData, setActiveIngredientData] = useState([]);

	// STATE: suppliers
	const [supplierData, setSupplierData] = useState([]);

	// STATE: Units
	const [unitData, setUnitData] = useState([]);

	// STATE: Accounts
	const [accountData, setAccountData] = useState([]);

	// STATE: Inventories
	const [inventoryData, setInventoryData] = useState([]);


	/**
  	 * Get ingredients from DB
  	 */
	useEffect(() => {
		
		(async () => {

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// // Get recipes
				// const recipeResponse = await axios.get(`${api_url}/recipe`, { headers: { 'Authorization': `Bearer ${access_token}`}});	
				// setRecipeData(recipeResponse.data.recipes);
				// setActiveRecipeData(recipeResponse.data.recipes);

				// // Get prep
				// const prepResponse = await axios.get(`${api_url}/prep`, {headers: { 'Authorization': `Bearer ${access_token}`}});	
				// setPrepData(prepResponse.data.prep);

				// Get ingredients
				const ingredientsResponse = await axios.get(`${api_url}/ingredient`, {headers: { 'Authorization': `Bearer ${access_token}`}});
				const sortedIngredients = ingredientsResponse.data.ingredients.sort((a, b) => a.name > b.name ? 1 : -1);
				setIngredientData(sortedIngredients);

				// Get accounts
				const accountsResponse = await axios.get(`${api_url}/account`, {headers: { 'Authorization': `Bearer ${access_token}`}});
				setAccountData(accountsResponse.data.accounts);

				// Get suppliers
				const supplierResponse = await axios.get(`${api_url}/supplier`, {headers: { 'Authorization': `Bearer ${access_token}`}});	
				setSupplierData(supplierResponse.data.suppliers);

				// Get units
				const unitResponse = await axios.get(`${api_url}/unit`, {headers: { 'Authorization': `Bearer ${access_token}`}});	
				setUnitData(unitResponse.data.units);

				// Get inventories
				const inventoriesResponse = await axios.get(`${api_url}/inventory`, {headers: { 'Authorization': `Bearer ${access_token}`}});
				setInventoryData(inventoriesResponse.data.inventories);

				setIsLoading(false);

			} catch (e) {
        		console.error(e);
      		}

		})();
		
	}, [getAccessTokenSilently]);


	/**
  	 * Change filtered list data, everytime search term or ingredient data changes
  	 */
	useEffect(() => {
		const filteredIngredientData = ingredientData.filter( ingredient => ingredient.name.search(new RegExp(searchState.searchTerm, "i")) !== -1);
		setActiveIngredientData(filteredIngredientData);
	}, [searchState, ingredientData]);	

	// Create new ingredient
  	const newIngredient = () => {
  		//@TODO Check if unsaved edits have been to an existing document. 

  		// Go to new ingredient URL. 
  		history.push("/menu/ingredients/new");
  	}

  	const getIngredients = async () => {
  			//console.log('getIngredients');
  		try {
  			// Get access token
  			const access_token = await getAccessTokenSilently();

  			let config = {
  				headers: { 'Authorization': `Bearer ${access_token}`},
  				params: {
  					//department_ids: this.state.active_department_ids,
  					// start_date: moment(this.state.active_start_date).format('YYYY-MM-DD 00:00'),
  				}
  			}
  			const axiosPromise = axios.get(`${api_url}/ingredient`, config);	
  			const response = axiosPromise;

  			return response
  			
  	  
  		} catch (e) {
  	    	console.error(e);
  		}
  	}

	const saveIngredient = async (data) => {
		console.log('saveIngredients');
		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {
				headers: { 'Authorization': `Bearer ${access_token}`},
				params: {
					//department_ids: this.state.active_department_ids,
					// start_date: moment(this.state.active_start_date).format('YYYY-MM-DD 00:00'),
				}
			}
			const axiosPromise = axios.post(`${api_url}/ingredient`, data, config);	
			const response = axiosPromise;
			
			return response;

		} catch (e) {
			console.error(e);
		}
	}

	const deleteIngredient = async (data) => {
		console.log('deleteIngredients');
		
		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {
				headers: { 'Authorization': `Bearer ${access_token}`},
				data: {
					_id: data._id,
				}
			}
			const axiosPromise = axios.delete(`${api_url}/ingredient`, config);	
			const response = axiosPromise;

			return response;
			      
		} catch (e) {
			console.error(e);
		}
	}
  	
	/**
	 * Handle searchbar value
	 */
  	const handleSearchChange = (event) => {
  		const target = event.target;
  		const value = target.type === 'checkbox' ? target.checked : target.value;
  		//const name = target.name;

  		// Set state
  		setSearchState({
  			searchTerm: value
  		});
  	}


	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Ingredienser"/>
			</div>
			
			<div className="app-content app-content--with-panel">
				
				<div className="app-panel">

					<div className="item-list-searchbar">
						<div className="item-list-searchfield-container">
							<div className="item-list-searchfield-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M8.8 8.1c.7-.9 1.1-2 1.1-3.1 0-2.7-2.2-5-5-5S0 2.2 0 5c0 2.7 2.2 5 5 5 1.1 0 2.2-.4 3.1-1.1l3.2 3.2.7-.7-3.2-3.3zM5 8.9c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4c-.1 2.2-1.9 4-4 4z" fill="#020302"/></svg>
							</div>
							<input type="text" className="item-list-search-input" name="searchValue" value={searchState.searchTerm} onChange={handleSearchChange} placeholder="Søg"/>
						</div>
						
					</div>


					<div className="item-list-scrollable">
							
							{
								isLoading ? (
									<ul className="item-list">
										{
											['','','','','','','','','',''].map((x, i) => {
												return (
													<li key={i} className="item-list-item">
														<div className="item-list-item-skeleton"></div>
													</li>	
												)
											})
										}
									</ul>
								) : (
									<ul className="item-list">
										{
											activeIngredientData.map((ingredient, i) => {

												let selectedClass = '';
												if (props.location.pathname.includes(ingredient._id)) {
													selectedClass = 'item-list-item--active';	
												}
												
												return (

													<li key={i} className={`item-list-item ${selectedClass}`}>
														<Link to={`/menu/ingredients/${ingredient._id}`}>
															
															<div className="item-list-item-title">{ingredient.name}</div>
															<div className="item-list-item-subtitle">
																{
																	ingredient.brand && (
																		ingredient.brand
																	)														
																}


																{
																	ingredient.pack_unit_of_measure && ingredient.pack_unit_of_measure.label && (
																		` (${ingredient.pack_unit_of_measure.label})`
																	)														
																}

															</div>

														</Link>
													</li>
												)
											})
										}
									</ul>
								)
							}

						
					</div>

					<div className="item-list-primary-button-container">
						<button className="item-list-primary-button" onClick={ ()=> newIngredient() }>Opret ny ingrediens</button>
					</div>
					

				</div>

				<div className="page-content">
					<Switch>
					  <Route path="/menu/ingredients" exact component={IngredientNoSelection} />
					  
						<Route path="/menu/ingredients/new" 
							exact 
					  		render={(props) => 
					  		<IngredientForm {...props} 
					  			ingredients={ingredientData}
					  			suppliers={supplierData}
					  			accounts={accountData}
					  			units={unitData}
					  			inventoryData={inventoryData}
					  			getIngredients={getIngredients}
					  			setIngredientData={setIngredientData}
					  			saveIngredient={saveIngredient}
					  			deleteIngredient={deleteIngredient}
					  			getAccessTokenSilently={getAccessTokenSilently}
					  		/>
						}/>
					  
						<Route path="/menu/ingredients/:id" 
							exact 
					  		render={(props) => 
					  		<IngredientForm {...props} 
					  			ingredients={ingredientData}
					  			suppliers={supplierData}
					  			accounts={accountData}
					  			units={unitData}
					  			inventoryData={inventoryData}
					  			getIngredients={getIngredients}
					  			setIngredientData={setIngredientData}
					  			saveIngredient={saveIngredient}
					  			deleteIngredient={deleteIngredient}
					  			getAccessTokenSilently={getAccessTokenSilently}
					  		/>
						}/>

					</Switch>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Ingredients, {
  onRedirecting: () => <Loading />,
});
