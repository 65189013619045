import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';

import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table';

const api_url = process.env.REACT_APP_GROED_API_URL;


/**
 * Load last two counts from a department
 */
const loadLastTwoCounts = async (access_token, inventory_id) => {

	// Get departments data
	const axiosPromise = await axios.get(`${api_url}/countsAggregatedByAccounts`, { 
		headers: { 'Authorization': `Bearer ${access_token}`},
		params: {
			inventory_ids: [inventory_id],
			limit: 2,
		}
	});

	return axiosPromise;		
}



export const StockReconciliation = () => {
	
	// Variables from History (react router)
	const history = useHistory();

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// STATE: Count data
	const [inventoryData, setInventoryData] = useState([]);	

	// STATE: Count data
	const [countData, setCountData] = useState([]);	

	// STATE: Count data
	const [tableData, setTableData] = useState([]);	

	/**
  	 * Get data from DB
  	 */
	useEffect(() => {
		(async () => {

			try {
				
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get inventories
				const inventoryResponse = await axios.get(`${api_url}/inventory`, { headers: { 'Authorization': `Bearer ${access_token}`}});	
				const inventoryData = inventoryResponse.data.inventories.sort((a, b) => (a.name > b.name) ? 1 : -1)

				// set inventories
				setInventoryData(inventoryData);
				
			} catch (e) {
        		console.error(e);
      		}

		})();		

	}, [getAccessTokenSilently]);


	/**
  	 * Get relevant counts, whenever the inventory data changes.
  	 */
	useEffect(() => {

		/**
		 * Load counts
		 */
		const loadAllCounts = async () => {

			// Get access token
			const access_token = await getAccessTokenSilently();

			// Setup array variables
			let counts = [];
			let promises = [];

			// Create data promises for each inventory
			for (const inventory of inventoryData) {
				const inventoryPromise = loadLastTwoCounts(access_token, inventory._id);
				promises.push(inventoryPromise);
			}

			// Wait for all promises to finish
			Promise.all(promises)
				.then( values => {
					// For each inventory call
					for (const value of values) {
						
						// For each inventory count
						for (const count of value.data.counts) {
							counts.push(count);
						}

					}
					// Set count data
					setCountData(counts);
					
				})
				.catch(error => {
					setIsLoading(false);
					console.log(error);
				})
		}
		loadAllCounts();


	}, [inventoryData]);


	/**
  	 * Prepare table data, whenever the count data changes
  	 */
	useEffect(() => {

		// setup empty variable
		let data = [];
		let handled_accounts = [];

		// For each inventory
		for (const inventory of inventoryData) {
			
			// Find counts from this inventory
			const counts = countData.filter(count => count.inventory._id === inventory._id);

			// If no counts are found, then skip this inventory (consider just making a non-adjustment for this department).
			if (!counts.length > 0) { continue; }

			// If there's only one (this is the first one), then do an adjustment from 0
			if (counts.length === 1) { 

				// Current count is the only count
				const current_count = counts[0];

				// For each account in this count
				for (const account of current_count.accounts) {
					
					data.push({
						inventory: inventory.name,
						department_number: inventory.department_number,
						account: account.account.name,
						account_number: account.account.account_number,
						date: moment(current_count.date).format('DD-MM-YYYY'),
						this_count: account.total,
						last_count: 0,
						value_regulation: 0 - account.total,
					})

					// Mark account as handled
					handled_accounts.push({department_number: inventory.department_number, ...account});
				}

			}

			// If theres 2 accounts, we'll need to figure out the regulation.
			if (counts.length === 2) { 

				// Get the counts
				const current_count = counts[0];
				const prev_count = counts[1];

				// For each account in the newset count
				for (const account of current_count.accounts) {
			
					// Find the same account from previous count based on the name (not the number)
					const prev_count_account = prev_count.accounts.find(prev_account => prev_account.account.name === account.account.name && prev_account.account.account_number === account.account.account_number);

					// Check if account with same department and number already exists. 
					const existing_account_index = data.findIndex(entry => entry.department_number === inventory.department_number && entry.account_number === account.account.account_number);

					// Mark account as handled
					handled_accounts.push({department_number: inventory.department_number, ...account});
					
					//If it doesnt already exist, we add a new entry
					if (existing_account_index === -1 ) {

						let regulation = 0;
						let last_count = 0;
						if (prev_count_account) {
							regulation =  prev_count_account.total - account.total;
							last_count = prev_count_account.total;
						}

						data.push({
							inventory: inventory.name,
							department_number: inventory.department_number,
							account: account.account.name,
							account_number: account.account.account_number,
							date: moment(current_count.date).format('DD-MM-YYYY'),
							this_count: account.total,
							last_count: last_count,
							value_regulation: regulation,
						})	

					} else {
						// Else we add to the existing values
						console.log(prev_count_account)
						data[existing_account_index].account += ', ' +  account.account.name;
						data[existing_account_index].this_count += account.total;
						
						if (prev_count_account) {
							data[existing_account_index].last_count += prev_count_account.total;
						}

						data[existing_account_index].value_regulation = data[existing_account_index].last_count - data[existing_account_index].this_count;
					}
				}

				// Also find "Unique account" in the previous count. If they exist, they need to be regulated to 0.
				for (const account of prev_count.accounts) {

					// Check if account with same department and number already exists. 
					const existing_account_index = handled_accounts.findIndex(entry => entry.department_number === inventory.department_number && entry.account.name === account.account.name && entry.account.account_number === account.account.account_number);
					
					//If it doesnt already exist, we add a new entry
					if (existing_account_index === -1 ) {
						
						// Mark account as handled
						handled_accounts.push({department_number: inventory.department_number, ...account});

						// Push data.
						data.push({
							inventory: inventory.name,
							department_number: inventory.department_number,
							account: account.account.name,
							account_number: account.account.account_number,
							date: moment(current_count.date).format('DD-MM-YYYY'),
							this_count: 0,
							last_count: account.total,
							value_regulation: 0 + account.total,
						})

					}
				}

			}

		}


		// Format table data for presentation (and export)
		data = data.map(entry => {
			return {
				inventory: entry.inventory,
				department_number: entry.department_number,
				account: entry.account,
				account_number: entry.account_number,
				date: entry.date,
				this_count: entry.this_count.toLocaleString("da-DK"),
				last_count: entry.last_count.toLocaleString("da-DK"),
				value_regulation: entry.value_regulation.toLocaleString("da-DK"),
			}
		})

		setTableData(data);	

	}, [countData]);


	useEffect(() => {
		console.log('tableData changed');
		// Set Is loading to false
		if (tableData.length > 0) {
			setIsLoading(false);	
		}
	}, [tableData]);

	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Inventory"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">
					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="page-content-header">
								<h1 className="page-title">Lagerafstemning</h1>
							</div>

							<div className="page-description">
								<p>
									Her kan du se lagerreguleringen for de seneste 2 optællinger for hvert lager pr. konto.
								</p>
							</div>



							<div className="stock-reconciliation-table">
								
								<MaterialTable
									title="Produkter"
									columns={[
										{ title: 'Seneste optælling', field: 'date', type: 'date' },
										{ title: 'Lager', field: 'inventory', type: 'string' },
										{ title: 'Afdelingsnummer', field: 'department_number', type: 'numeric' },
										{ title: 'Kategori', field: 'account', type: 'string' },
										{ title: 'Kontonummer', field: 'account_number', type: 'numeric' },
										{ title: 'Sidste optælling', field: 'last_count', type: 'numeric' },
										{ title: 'Nyeste optælling', field: 'this_count', type: 'numeric' },
										{ title: 'Regulering', field: 'value_regulation', type: 'numeric' },




										// { title: 'Type', field: 'type', type: 'string' },
										// { title: 'Dato', field: 'date', type: 'date' }, // helst sidste dag i optællingsmåned. 
										// { title: 'Konto/Kunde/Leverandør', field: 'account', type: 'numeric' }, // Varegruppen/ kategorien
										// { title: 'Bilagsnr.', field: 'appendix_number', type: 'numeric' },
										// { title: 'Tekst', field: 'text', type: 'string' },
										// { title: 'Beløb', field: 'amount', type: 'numeric',
										// 	render: (row) => {
										// 		const amount = row.amount;
										// 		return (
										// 			row.amount.toLocaleString("da-DK")
										// 		)
										// 	} 
										// },
										// { title: 'Valuta', field: 'currency', type: 'string' },
										// { title: 'Momskode', field: 'vat_code', type: 'string' },
										// { title: 'Momskonto', field: 'vat_account', type: 'string' },
										// { title: 'Momskonto modkonto', field: 'vat_reverse_account', type: 'string' },
										// { title: 'Fakturanr.', field: 'invoice_number', type: 'numeric' },
										// { title: 'Forfaldsdato', field: 'due_date', type: 'date' },
										// { title: 'Afdeling navn', field: 'department_name', type: 'numeric' },
										// { title: 'Afdeling (tillægsmodul)', field: 'department_number', type: 'numeric' },
									]}
									data={tableData}
									x-components={{ Container: props => <Paper {...props} elevation={0}/> }} // This removes the drop shadow 
									isLoading={isLoading}
									options={{
											search: false,
											pagination: false,
											pageSize: 10,
											pageSizeOptions: [100],
											exportButton: true,
											exportAllData: true,
											filtering: false,
											toolbar: true,
									}}

									/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(StockReconciliation, {
  onRedirecting: () => <Loading />,
});
