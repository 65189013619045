import React, { useState } from "react";
//import { Link, NavLink } from "react-router-dom";

export const FilePreview = (props) => {


	// STATE: Accounts
	const [isOpen, setIsOpen] = useState(false);	


	// Open table
  	const openTable = () => {
  	
  		const toggle = !isOpen;
  		setIsOpen(toggle);
  	}


  	const total = props.data.reduce((accumulator, currentValue) => {
  		return accumulator + currentValue.value;
  	}, 0);

	return (
		<div className={"file-preview " + (isOpen ? 'file-preview--open ' : 'file-preview--closed ') + (props.className ? props.className : '')}>
			<div className="file-preview-header">
				
				{
					props.icon && (
						<div className="file-preview-icon-container">
							<div className="file-preview-icon">
								{props.icon()}
							</div>
						</div>
					)
				}
					
				{
					props.label && (
						<div className="file-preview-label">
							{props.label}
						</div>
					)
				}
				
				<div className="file-preview-title-block">
					<div className="file-preview-pretitle">
						{props.pretitle}
					</div>
					<div className="file-preview-title">
						{props.title}
					</div>
					<div className="file-preview-subtitle">
						{props.subtitle}
					</div>
					
					{
						props.actions.length > 0 && (
							<div className="file-preview-actions">
								{
									
									props.actions.map((action, i) => {

										return (
											<div key={i} className="file-preview-action" onClick={() => action.action()}>{action.label}</div>
										)

									})

								}
							</div>
						)
					}
				
				</div>

				<div className="file-preview-open-trigger-container">
					<div className="file-preview-open-trigger" onClick={() => openTable()}>
						<div className="file-preview-open-trigger-icon"></div>
					</div>
				</div>

			</div>

			<div className="file-preview-body">
				{

					props.data.map((entry, i) => {

						return (
							<div key={i} className="file-preview-row">
								<div className="file-preview-row-header">{entry.label}</div>
								<div className="file-preview-row-value">{entry.value.toLocaleString("da-DK")} kr.</div>
							</div>
						)
					})
				}
			</div>
			<div className="file-preview-footer">
				<div className="file-preview-row">
					<div className="file-preview-row-header">Total</div>
					<div className="file-preview-row-value">{ total.toLocaleString("da-DK") } kr.</div>
				</div>
			</div>

			{
				props.comments !== false && props.comments !== '' && !props.commentsChange && (
					<div className="file-preview-footer-ekstra">
						<div className="file-preview-comments">
							<p><strong>Kommentarer:</strong> {props.comments}</p>
						</div>
					</div>
				)
			}

			{
				props.comments !== false && props.commentsChange && (
					<div className="file-preview-footer-ekstra">
						<div className="file-preview-comments">
							<h3 className="file-preview-comments-heading">Kommentarer</h3>
							<textarea name="comments" id="" rows="3" value={props.comments} onChange={props.commentsChange} onBlur={props.commentsBlur}></textarea>
						</div>
					</div>
				)
			}

			
		</div>
	); 
};

export default FilePreview;

FilePreview.defaultProps = {
	className: '',
	icon: '',
	label: '',
	pretitle: 'pretitle',
	title: 'Title',
	subtitle: 'Subtitle',
	data: [
		{
			label: 'Row 1',
			value: 200,
		},
		{
			label: 'Row 2',
			value: 500,
		}
	],
	actions: [],
	comments: false,
	commentsChange: false,
}