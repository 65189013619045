import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';
import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";
import FilePreview from '../components/FilePreview';

import OrdersNoEntries from "../components/OrdersNoEntries";

const api_url = process.env.REACT_APP_GROED_API_URL;

export const Orders = () => {
	
	// Variables from History (react router)
	const history = useHistory();

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// STATE: Invetory data
	const [orderData, setOrderData] = useState([]);

	/**
  	 * Get data from DB
  	 */
	useEffect(() => {
		setIsLoading(false);
		(async () => {

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get counts Aggregated by accounts (categories)
				const orderAggregatedResponse = await axios.get(`${api_url}/ordersAggregatedByAccounts`, { headers: { 'Authorization': `Bearer ${access_token}`}});
				const orderData = orderAggregatedResponse.data.orders;
				console.log(orderData);
				setIsLoading(false);
				setOrderData(orderData);

				
			} catch (e) {
        		console.error(e);
      		}

		})();		

	}, [getAccessTokenSilently]);

	// Create new Prep
  	const newOrder = () => {
  		//@TODO Check if unsaved edits have been to an existing document. 

  		// Go to new ingredient URL. 
  		history.push("/inventory/order");
  	}

  	// Open table
  	const openTable = () => {
  	
  	}


  	if (isLoading) {
  		return (
  			<div className="app-container">
  				<div className="app-sidebar">
  					<BrandBar/>
  					<Navigation page_title="Inventory"/>
  				</div>
  				
  				<div className="app-content">
  					<div className="page-content">
  						<Loading />
					</div>
				</div>
			</div>
  		);	
  	}

  	const goToOrder = (data) => {
  		console.log('go to order', data);
  	}

  	const recieveOrder = () => {
  		console.log('Recieve order');	
  	}

  	const cancelOrder = () => {
  		console.log('Cancel order');	
  	}

	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Inventory"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">
					
					{
						orderData.length === 0 ? (
							<OrdersNoEntries/>
						) : (
							<div className="page-content-inner">
								<div className="page-content-inner-centering--wide">
									<div className="breadcrumbs">/lager/Modtag ordre</div>
									
									<div className="page-content-header">
										<h1 className="page-title">Ordrer</h1>

										<button type="button" className="button" onClick={ ()=> newOrder() }>Ny Ordre</button>
									</div>
									<div className="page-description">
										<p>
											Her kan du se en oversigt over dine ordrer.
										</p>
									</div>

									<div className="file-preview-list">
										{
											orderData.map((order, i) => {
											
												let status = '';
												let className = '';
												if (order.status === 'ordered') {
													status = 'Afventer levering';
													className = 'file-preview--ordered';
												} else if(order.status === 'delivered') {
													status = 'Leveret';
													className = 'file-preview--delivered';
												} else if(order.status === 'canceled') {
													status = 'Annulleret';
													className = 'file-preview--canceled';
												} else if(order.status === 'booked') {
													status = 'Lagerført';
													className = 'file-preview--booked';
												}

												const line_item_data = order.line_items.map(item => {
													return {
														label: item.item.name,
														value: item.value
													}
												})
												const count_string = line_item_data.length === 1 ? 'vare' : 'varer';


												return (
												
													<FilePreview 
														key={i}
														className={className}
														icon={() => { return(
															<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512"><g id="Group_88" transform="translate(-65.101 -16.651)"><path id="Rectangle_419" d="M278.3 199.7h148v42h-148z"/><path id="Rectangle_420" d="M215 199.7h41.9v42H215z"/><path id="Rectangle_421" d="M278.3 271.1h148v42h-148z"/><path id="Rectangle_422" d="M215 271.1h41.9v42H215z" /><path id="Rectangle_423" d="M278.3 342.6h148v42h-148z" /><path id="Rectangle_424" d="M215 342.6h41.9v42H215z" /><path id="Path_327" d="M479 55.3h-53v-8.7c-.2-10.4-7.3-19.4-17.3-22.1-4.4-1.4-8.9-2-13.5-2H244.4c-4.2 0-8.4.6-12.4 1.8-9.9 2.7-16.8 11.6-16.9 21.9v9.2h-56.2c-21.3 0-38.5 17.2-38.6 38.5v391.2c0 21.3 17.3 38.5 38.5 38.5h323.5c21.3 0 38.5-17.3 38.5-38.5v-388c.1-23-18.7-41.8-41.8-41.8zM257.1 65.4c0-.5 2.8-1 6.2-1h113.4c4.1 0 7.4.5 7.4 1.2v21.8c0 .5-2.8 1-6.2 1H263.3c-3.4 0-6.2-.4-6.2-1v-22zm221.8 416.3H162.2V97.3H215v9.2c.1 10.2 7 19.2 16.9 21.9 4 1.3 8.2 1.9 12.4 1.8h152.6c4.2 0 8.4-.6 12.4-1.8 9.9-2.7 16.8-11.6 16.9-21.9v-9.2H479l-.1 384.4z"/></g></svg>
														)}}
														label={status}
														pretitle={`#${order._id} | Leveringsdato: ${moment(order.delivery_date).format('DD. MMM YYYY')}`}
														title={`${order.inventory.name} — ${order.supplier.name} (${order.line_items.length} ${count_string})`}
														subtitle={`bestilling oprettet d. ${moment(order.created_date).format('DD. MMM YYYY [kl.] HH:mm')} af ${order.name}`}
														data={line_item_data}
														actions={[
															// {
															// 	label: 'Se ordre',
															// 	action: () => { goToOrder('test') },
															// 	color: '#245463'
															// },
															{
																label: 'Modtag varer',
																action: () => { recieveOrder('test') },
																color: '#245463'
															},
															{
																label: 'Annuller',
																action: () => { cancelOrder('test') },
																color: '#245463'
															}
														]}
														comments={order.comments}
													/>
												)
											})
										}

									</div>

								</div>
							</div>
						)
					}
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Orders, {
  onRedirecting: () => <Loading />,
});
