import React, { useState, useEffect, Fragment } from "react";
import axios from 'axios';
import { useHistory, Link, } from "react-router-dom";
import { Formik, Field } from 'formik';
import Select from 'react-select'
import FocusError from './FocusError';
import countries from '../data/countries.json';
import RepeatableUnits from '../components/RepeatableUnits';

const api_url = process.env.REACT_APP_GROED_API_URL;

const country_options = countries.countries.map(country => {
	return {value: country.alpha3, label: country.name}
})

// Default state
const defaultFormState = {
	_id: undefined,
	
	// Base data
	supplier: '',
	account: '',
	name: '',
	sku: '',
	brand: '',

	// Inventory
	pack_unit_of_measure: '',
	inventory_unit_of_measure: '',
	inventory_units_per_pack: '',
	grams_per_inventory_unit: '',
	packs_per_case: '',
	
	// minimum_on_hand: '',
	par_level: '',
	custom_units: [],
	price_per_pack: '',
	waste_percentage: '',


	inventories: [],

	// Nutritional information
	energy_kj: '',
	energy_kcal: '',
	fat: '',
	saturated_fat: '',
	carbohydrate: '',
	sugar: '',
	protein: '',
	salt: '',
	fiber: '',

	vitamin_a: '',
	vitamin_b6: '',
	vitamin_b12: '',
	vitamin_c: '',
	vitamin_d: '',
	calcium: '',
	iron: '',
	magnesium: '',

	// Allergies
	allergies: [],

	country_of_origin: '',
	co2_per_inventory_unit: '',
	classifications: [],
	type_of_transportation: '',

}

export const IngredientForm = (props) => {

	// Variables from History (react router)
	const history = useHistory();

	// Form state
	const [formData, setFormData] = useState(defaultFormState);

	// Form state
	const [formChange, setFormChange] = useState(false);

	// Form state
	const [formSaved, setFormSaved] = useState(false);

	// STATE: Related items (preps and recipes that contains this ingredient)
	const [relatedPrepData, setRelatedPrepData] = useState([]);
	const [relatedRecipesData, setRelatedRecipesData] = useState([]);

	/**
	 * Check for change in param ID and ingredients
	 */ 
	useEffect(() => {
		
		// Figure out if this an existing item, or a new one. 
		if (props.match.params.id) {

			// Find data in props.ingredients
			const currentIngredient = props.ingredients.find(ingredient => ingredient._id === props.match.params.id);

			if (currentIngredient) {
				// Populate formData state with data
				
				if (currentIngredient.supplier_id && currentIngredient.supplier_id._id) {
					currentIngredient.supplier = {
						value: currentIngredient.supplier_id._id,
						label: currentIngredient.supplier_id.name,
					}
				}

				if (currentIngredient.account_id && currentIngredient.account_id._id) {
					currentIngredient.account = {
						value: currentIngredient.account_id._id,
						label: currentIngredient.account_id.name,
					}
				}

				if (currentIngredient.country_of_origin && currentIngredient.country_of_origin.alpha3 && currentIngredient.country_of_origin.name) {
					currentIngredient.country_of_origin = {
						value: currentIngredient.country_of_origin.alpha3,
						label: currentIngredient.country_of_origin.name,
					}
				}

				if (currentIngredient.type_of_transportation && currentIngredient.type_of_transportation.label && currentIngredient.type_of_transportation.name) {
					currentIngredient.country_of_origin = {
						value: currentIngredient.type_of_transportation.value,
						label: currentIngredient.type_of_transportation.label,
					}
				}
				
				// Remove all null values from DB, since we don't want to set field values to null. 
				// There may be a better way to handle this, But probably a good idea to keep this just to ensure, that we don't set Formik values to null or undefined. Better to use empty string.
				for (var propName in currentIngredient) { 
				    if (currentIngredient[propName] === null || currentIngredient[propName] === undefined) {
				      delete currentIngredient[propName];
				    }
				}

				setFormData({...defaultFormState, ...currentIngredient});
			} else {
				// @TODO 404 Doesnt exist!?
			}

		} else {
			// New document
			setFormData({...defaultFormState});
		}

	}, [props.ingredients, props.match.params.id]);


	// Find related items
	useEffect(() => {
		
		(async () => {
			try {

				// Get access token
				const access_token = await props.getAccessTokenSilently();


				if (props.match.params.id) {
					
					// Get related items
					const relatedPrepResponse = await axios.get(`${api_url}/prep`, {
						headers: { 'Authorization': `Bearer ${access_token}`},
						params: {
							contains: [props.match.params.id],
						}
					});
					setRelatedPrepData(relatedPrepResponse.data.prep);

					const relatedRecipesResponse = await axios.get(`${api_url}/recipe`, {
						headers: { 'Authorization': `Bearer ${access_token}`},
						params: {
							contains: [props.match.params.id],
						}
					});
					setRelatedRecipesData(relatedRecipesResponse.data.recipes);

				}

			} catch (e) {
        		console.error(e);
      		}

		})();
		
	}, [props.getAccessTokenSilently, props.match.params.id]);	


	/**
	 * Handle form submission
	 */
	const sendData = async (data) => {

		console.log('sendData');

		// Send form data
		const submitResponse = await props.saveIngredient(data);


		// If everything goes well, Redirect to new item URL
		if (submitResponse && submitResponse.status === 200) {
			setFormChange(false);
			formJustSaved();

			// Reload data
			const dataResponse = await props.getIngredients();
			const sorted = dataResponse.data.ingredients.sort((a, b) => a.name > b.name ? 1 : -1);
			props.setIngredientData(sorted);

			const id = submitResponse.data.ingredient._id;
			history.push(`/menu/ingredients/${id}`);
		} else {
			// Error message?
		}

	}

	/**
	 * Handle Delete
	 */
	const handleDelete = async (event) => {
		event.preventDefault();
		
		// Ask if user is sure they want to delete. No going back! 
		if (window.confirm("Er du helt sikker på at du vil slette?")) {
			// Send form data
			const deleteResponse = await props.deleteIngredient({_id: formData._id});

			// Reload data
			const dataResponse = await props.getIngredients();
			const sorted = dataResponse.data.ingredients.sort((a, b) => a.name > b.name ? 1 : -1);
			props.setIngredientData(sorted);

			// If everything goes well, Redirect to ingredients front page
			if (deleteResponse.status === 200) {
				history.push("/menu/ingredients");
			}	
		}

	}

	const handleFormChange = () => {
		console.log('change made');
		setFormChange(true);
	}

	const cancelChanges = (resetForm) => {
		setFormChange(false);
		resetForm();
		
	}

	const formJustSaved = () => {
		setFormSaved(true);
		setTimeout(() => {
			//alert(JSON.stringify(values, null, 2));
			setFormSaved(false);
		}, 1000);

	}

	return (
		<div className="page-content-inner">

			<div className="page-content-inner-centering">

				{
					props.match.params.id ? (
						<div className="breadcrumbs">/menu/ingredient/{formData.name}</div>
					) : (
						<div className="breadcrumbs">/menu/ingredient/ny ingrediens</div>
					)
				}
				

				<Formik
					initialValues={{ 
			       		_id: props.match.params.id || '',
			       		// name: formData.name,
			       		// supplier: formData.supplier,
			       		// account: formData.category,
			       		// sku: formData.sku,
			       		...formData
					}}
			       

			       	enableReinitialize={true}

					validate={ values => {
						const errors = {};

						console.log(values);

						// Required fields
						let required_fields = [
						'name', 
						'supplier',
						'account',
						'sku',
						
						'pack_unit_of_measure',
						'inventory_unit_of_measure',
						'inventory_units_per_pack',
						'price_per_pack',
						'waste_percentage',

						'energy_kj',
						'energy_kcal',
						'fat',
						'saturated_fat',
						'carbohydrate',
						'sugar',
						'protein',
						'salt',
						'fiber',
						];

						if (values.inventory_unit_of_measure.value !== 'g') {
							required_fields.push('grams_per_inventory_unit');
						}


						for(const required_field of required_fields) {
							if (values[required_field] === '') {
								errors[required_field] = 'Dette felt er påkrævet';
							}	
						}


						// Number validation
						const numbers_fields = [
							'co2_per_inventory_unit',
							'inventory_units_per_pack',
							'price_per_pack',
							'waste_percentage',
							'energy_kj',
							'energy_kcal',
							'fat',
							'saturated_fat',
							'carbohydrate',
							'sugar',
							'protein',
							'salt',
							'fiber',
						]

						for(const number_field of numbers_fields) {
							if ( isNaN(values[number_field])) {
								errors[number_field] = 'Dette felt skal være et tal';
							}	
						}



						// console.log(values.waste_percentage);
						// if (values.waste_percentage === '') {
						// 	errors.waste_percentage = 'Dette felt er påkrævet';
						//}	


			         	
			         	return errors;
					}}
			       
					onSubmit={(values, { setSubmitting }) => {
					

						//console.log(typeof values.supplier_id.value, values.supplier_id.value);
						
						// only send the ID to  DB
						if (values.supplier.value) {
							values.supplier_id = values.supplier.value;	
						}
							
						if (values.account.value) {
							values.account_id = values.account.value;	
						}

						if (values.country_of_origin.value) {
							values.country_of_origin.alpha3 = values.country_of_origin.value;
							values.country_of_origin.name = values.country_of_origin.label;	
						}

						if (values.inventory_unit_of_measure.value === 'g') {
							values.grams_per_inventory_unit = 1;
						}


						sendData(values);

						setTimeout(() => {
							//alert(JSON.stringify(values, null, 2));
							setSubmitting(false);
						}, 2000);
			       }}
			    >

		    	{({
	    	         values,
	    	         errors,
	    	         touched,
	    	         handleChange,
	    	         handleBlur,
	    	         handleSubmit,
	    	         isSubmitting,
	    	         setFieldValue,
	    	         resetForm,
	    	         /* and other goodies */
	    	    }) => (
	    	        <form onSubmit={handleSubmit} onChange={() => handleFormChange()}>
	    	        
	    	        	<div className="title-field" data-field="name">
	    	        		<label className="title-field-label">Navn</label>
	    	        		<div className="title-field-input-container">
	    	        			<input type="text" className="title-field-input" placeholder="Indtast navn" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
	    	        		</div>
	    	        		{errors.name && touched.name && ( <div className="standard-field-input-error">{errors.name}</div> )}
	    	        	</div>


	    	        	<div className="field-section standard-field-section">

		    	        	<div className="standard-field" data-field="supplier">
		    	        		<div className="standard-field-label-container">
		    	        			<label className="standard-field-label">Leverandør</label>
		    	        		</div>
		    	        		<div className="standard-field-input-container">
		    	        			 <Select
		    	        			 	styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
		    	        			 	placeholder="Vælg leverandør"
		    	        				value={ values.supplier }
		    	        				onChange={option => {
		    	        					setFieldValue("supplier", option);
		    	        					handleFormChange();
		    	        				}}
		    	        				onBlur={handleBlur}
		    	        			 	options={props.suppliers.map(supplier => {
											return {
												value: supplier._id, label: supplier.name,
											}
										})} 
		    	        			 />
		    	        			 {errors.supplier && touched.supplier && ( <div className="standard-field-input-error">{errors.supplier}</div> )}
		    	        		</div>
		    	        	</div>

		    	        
		    	        	<div className="standard-field" data-field="account">
		    	        		<div className="standard-field-label-container">
		    	        			<label className="standard-field-label">Konto</label>
		    	        		</div>
		    	        		<div className="standard-field-input-container">
		    	        			<Select
		    	        				styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
		    	        				placeholder="Vælg konto"
		    	        				value={values.account}
		    	        				onChange={option => {
		    	        					setFieldValue("account", option);
		    	        					handleFormChange();
		    	        				}}
		    	        				onBlur={handleBlur}
		    	        				options={
		    	        			 		props.accounts.map(account => {
		    	        			 			return {
		    	        			 				value: account._id, label: account.name,
		    	        			 			}
		    	        			 		})
		    	        			 	}
		    	        			/>
		    	        			{errors.account && touched.account && ( <div className="standard-field-input-error">{errors.account}</div> )}
		    	        		</div>
		    	        	</div>
		    	        	
		    	       

		    	        	<div className="standard-field" data-field="sku">
		    	        		<div className="standard-field-label-container">
		    	        			<label className="standard-field-label">Leverandør SKU</label>
		    	        		</div>
		    	        		<div className="standard-field-input-container">
		    	        			<input type="text" className="standard-field-input" placeholder="Indtast navn" name="sku" value={values.sku} onChange={handleChange} onBlur={handleBlur}/>
		    	        			{errors.sku && touched.sku && ( <div className="standard-field-input-error">{errors.sku}</div> )}
		    	        		</div>	    	        		
		    	        	</div>

		    	        	<div className="standard-field" data-field="brand">
		    	        		<div className="standard-field-label-container">
		    	        			<label className="standard-field-label">Mærke</label>
		    	        		</div>
		    	        		<div className="standard-field-input-container">
		    	        			<input type="text" className="standard-field-input" placeholder="Indtast mærke" name="brand" value={values.brand} onChange={handleChange} onBlur={handleBlur}/>
		    	        			{errors.brand && touched.brand && ( <div className="standard-field-input-error">{errors.brand}</div> )}
		    	        		</div>	    	        		
		    	        	</div>

	    	        	</div>

	    	        	<div className="field-section standard-field-section">

	    	        		<div className="field-section-header">LAGER</div>

	    	        		<div className="standard-field" data-field="pack_unit_of_measure">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Pakkeenhed</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='Den enhed som varen kommer i. Hvis ikke enheden er vægt eller volumetric, så brug "stk".'>i</div>
	    	        				</div>	
	    	        			</div>
	    	        			<div className="standard-field-input-container">
	    	        				<Select
	    	        					styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
		    	        				placeholder="Vælg pakkeenhed"
		    	        				value={values.pack_unit_of_measure}
		    	        				onChange={option => {
		    	        					setFieldValue("pack_unit_of_measure", option)
		    	        					handleFormChange();
		    	        				}}
		    	        				onBlur={handleBlur}
		    	        				options={[
		    	        					{ value: 'stk', label: 'Stk'},
		    	        					{ value: 'g', label: 'g'},
		    	        					{ value: 'kg', label: 'Kg'},
		    	        					{ value: 'ml', label: 'ml'},
		    	        					{ value: 'cl', label: 'cl'},
		    	        					{ value: 'l', label: 'L'},

		    	        					{ value: 'KRT', label: 'KRT'},
		    	        					{ value: 'BK', label: 'BK'},
		    	        					{ value: 'PS', label: 'PS'},
		    	        					{ value: 'PK', label: 'PK'},
		    	        					{ value: 'DS', label: 'DS'},
		    	        					{ value: 'GL', label: 'GL'},
		    	        					{ value: 'FL', label: 'FL'},
		    	        					{ value: 'DK', label: 'DK'},
		    	        					{ value: 'BT', label: 'BT'},
		    	        					{ value: 'SP', label: 'SP'},
		    	        					{ value: 'SÆK', label: 'SÆK'},
		    	        					{ value: 'ÆSK', label: 'ÆSK'},
		    	        					{ value: 'Index 1L', label: 'Index 1L'},
		    	        					{ value: 'Index 3L', label: 'Index 3L'},
		    	        					{ value: 'BDT', label: 'BUNDT'},
		    	        					{ value: 'Kantine 1/3', label: 'Kantine 1/3'},
		    	        					{ value: 'Kantine 1/6', label: 'Kantine 1/6'},
		    	        				]} 
		    	        			/>
		    	        			{errors.pack_unit_of_measure && touched.pack_unit_of_measure && ( <div className="standard-field-input-error">{errors.pack_unit_of_measure}</div> )}
	    	        			</div>
	    	        		</div>

	    	        		<div className="standard-field" data-field="inventory_unit_of_measure">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Lagerenhed</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='Den mindste enhed som mængden på lageret måles i.'>i</div>
	    	        				</div>
	    	        			</div>
	    	        			<div className="standard-field-input-container">
	    	        				<Select 
	    	        					styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
		    	        				placeholder="Vælg lagerenhed"
		    	        				value={values.inventory_unit_of_measure}
		    	        				onChange={option => {
		    	        					setFieldValue("inventory_unit_of_measure", option);
		    	        					handleFormChange();
		    	        				}}
		    	        				onBlur={handleBlur}
		    	        				options={[
		    	        					{ value: 'stk', label: 'Stk'},
		    	        					{ value: 'g', label: 'g'},
		    	        					{ value: 'ml', label: 'ml'},
		    	        				]} 
		    	        			/>
		    	        			{errors.inventory_unit_of_measure && touched.inventory_unit_of_measure && ( <div className="standard-field-input-error">{errors.inventory_unit_of_measure}</div> )}
	    	        			</div>
	    	        		</div>

	    	        		{

	    	        			values.inventory_unit_of_measure.value !== 'g' && (

			    	        		<div className="standard-field" data-field="grams_per_inventory_unit">
			    	        			<div className="standard-field-label-container">
			    	        				<label className="standard-field-label">Antal gram pr. lagerenhed</label>
			    	        				<div className="standard-field-label-tooltipper">
			    	        					<div className="standard-field-label-tooltip-icon" data-text='Hvor mange gram vejer 1 lagerenhed? Dette er vigtigt i forbindelse med næringsberegning'>i</div>
			    	        				</div>
			    	        			</div>
			    	        			<div className="standard-field-input-container">
			    	        				<input type="number" step="1" className="standard-field-input" placeholder="Antal" name="grams_per_inventory_unit" value={values.grams_per_inventory_unit} onChange={handleChange} onBlur={handleBlur}/>
											{errors.grams_per_inventory_unit && touched.grams_per_inventory_unit && ( <div className="standard-field-input-error">{errors.grams_per_inventory_unit}</div> )}	    	        			
			    	        			</div>
			    	        		</div>

	    	        			)

	    	        		}



	    	        		<div className="standard-field" data-field="inventory_units_per_pack">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">lagerenheder pr. pakke</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='Hvor meget af lagerenheden er der i hver pakkeenhed?'>i</div>
	    	        				</div>
	    	        			</div>
	    	        			<div className="standard-field-input-container">
	    	        				<input type="number" step="1" className="standard-field-input" placeholder="Antal" name="inventory_units_per_pack" value={values.inventory_units_per_pack} onChange={handleChange} onBlur={handleBlur}/>
									{errors.inventory_units_per_pack && touched.inventory_units_per_pack && ( <div className="standard-field-input-error">{errors.inventory_units_per_pack}</div> )}	    	        			
	    	        			</div>
	    	        		</div>


	    	        		{/*
	    	        		<div className="standard-field-clear"></div>
	    	        		
	    	        		<div className="standard-field">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Minumum antal pakker på lager</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='Når der er mindre end denne mængde på lager, skal der bestilles hjem, manuelt eller automatisk.'>i</div>
	    	        				</div>
	    	        			</div>
	    	        			<div className="standard-field-input-container">
	    	        				<input type="number" className="standard-field-input" placeholder="Antal"/>
									{errors.minimum_on_hand && touched.minimum_on_hand && ( <div className="standard-field-input-error">{errors.minimum_on_hand}</div> )}
	    	        			</div>
	    	        		</div>
	    	        		*/}

	    	        		
	    	        		

	    	        		<div className="standard-field" data-field="price_per_pack">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Pris per pakke</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='Pris for den mængde der udgør 1 pakkeenhed'>i</div>
	    	        				</div>
	    	        			</div>
	    	        			<div className="standard-field-input-container">
	    	        				<input type="number" step="0.01" className="standard-field-input" placeholder="Pris" name="price_per_pack" value={values.price_per_pack} onChange={handleChange} onBlur={handleBlur}/>
									{errors.price_per_pack && touched.price_per_pack && ( <div className="standard-field-input-error">{errors.price_per_pack}</div> )}
	    	        			</div>
	    	        		</div>

	    	        		<div className="standard-field" data-field="waste_percentage">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Spildprocent</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='Den generelle spildprocent for denne vare'>i</div>
	    	        				</div>
	    	        			</div>
	    	        			<div className="standard-field-input-container">
	    	        				<input type="number" step="1" className="standard-field-input" placeholder="Procent" name="waste_percentage" value={values.waste_percentage} onChange={handleChange} onBlur={handleBlur}/>
									{errors.waste_percentage && touched.waste_percentage && ( <div className="standard-field-input-error">{errors.waste_percentage}</div> )}
	    	        			</div>
	    	        		</div>
							
							<div className="standard-field">
	    	        			<div className="standard-field-label-container" data-field="par_level">
	    	        				<label className="standard-field-label">PAR Niveau</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='Periodic automatic replenishment: Antal pakkeenheder der bør rammes ved nye bestillinger'>i</div>
	    	        				</div>
	    	        			</div>
	    	        			<div className="standard-field-input-container">
	    	        				<input type="number" className="standard-field-input" placeholder="Antal" name="par_level" value={values.par_level} onChange={handleChange} onBlur={handleBlur}/>
									{errors.par_level && touched.par_level && ( <div className="standard-field-input-error">{errors.par_level}</div> )}
	    	        			</div>
	    	        		</div>
	    	        		{/*
	    	        		<div className="standard-field standard-field--wide standard-field--disabled" data-field="">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Optællingsenheder (kommer snart)</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='Enheder som skal kunne bruges til optællinger i forbindelse med status'>i</div>
	    	        				</div>
	    	        			</div>
	    	        			<div className="block-select-container">
	    	        				<ul className="block-select">
	    	        					<li className="block-select-item">
	    									<label className="block-select-item-container">
												<Field type="checkbox" name="count_units"/>
	    									  	<div className="block-select-item-element">ml</div>
	    									</label>
	    								</li>
	    								<li className="block-select-item">
	    									<label className="block-select-item-container">
												<Field type="checkbox" name="count_units"/>
	    									  	<div className="block-select-item-element">L</div>
	    									</label>
	    								</li>
	    	        				</ul>
	    	        			</div>
	    	        		</div>
	    	        		*/}

	    	        		<div className="standard-field standard-field--wide" data-field="">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Brugerdefinerede optællingsenheder</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='(Max 2) enheder som skal kunne bruges til optællinger i forbindelse med status (ud over lager og pakkeenhed)'>i</div>
	    	        				</div>
	    	        			</div>
							
	    	        			<RepeatableUnits 
	    	        				handleChange={handleChange} 
	    	        				handleBlur={handleBlur} 
	    	        				handleFormChange={handleFormChange}
	    	        				custom_units={values.custom_units}
	    	        				setFieldValue={setFieldValue}
	    	        				values={values}
	    	        				units={props.units}
	    	        			/>
	    	        			<br/>
	    	        		</div>

	    	        		<div className="standard-field standard-field--wide" data-field="">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Lagre</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='Enheder som skal kunne bruges til optællinger i forbindelse med status'>i</div>
	    	        				</div>
	    	        			</div>
	    	        			<div className="block-select-container">
	    	        				<ul className="block-select">
	    	        					{
	    	        						props.inventoryData.map((inventory, i) => {

	    	        							return (
	    	        								<li key={i} className="block-select-item">
	    	        									<label className="block-select-item-container">
															<Field type="checkbox" name="inventories" value={inventory._id} />
	    	        									  	<div className="block-select-item-element">{inventory.name}</div>
	    	        									</label>
	    	        								</li>
	    	        							)
	    	        						})

	    	        					}
	    	        				</ul>
	    	        			</div>
	    	        		</div>

	    	        	</div>


	    	        	<div className="field-section standard-field-section">

	    	        		<div className="field-section-header">Næringsindhold pr 100g</div>

	    	        		<div className="standard-field standard-field--wide">
	    	        			
	    	        			<table className="table-fields">
		    	        		    <tbody>
		    	        		        <tr className="table-field-row" data-field="energy_kj">
		    	        		            <th className="table-field-header">
		    	        		            	Energi
		    	        		            	{errors.energy_kj && touched.energy_kj && ( <span className="table-field-input-error">{errors.energy_kj}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="energy_kj" value={values.energy_kj} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">kj</td>
		    	        		        </tr>

		    	        		        <tr className="table-field-row" data-field="energy_kcal">
		    	        		            <th className="table-field-header">
		    	        		            	Energi
		    	        		            	{errors.energy_kcal && touched.energy_kcal && ( <span className="table-field-input-error">{errors.energy_kcal}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="energy_kcal" value={values.energy_kcal} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">kcal</td>
		    	        		        </tr>
		    	        		        <tr className="table-field-row" data-field="fat">
		    	        		            <th className="table-field-header">
		    	        		            	Fedt
		    	        		            	{errors.fat && touched.fat && ( <span className="table-field-input-error">{errors.fat}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="fat" value={values.fat} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">g</td>
		    	        		        </tr>
		    	        		        <tr className="table-field-row" data-field="saturated_fat">
		    	        		            <th className="table-field-header">
		    	        		            	- heraf mættede fedtsyrer
		    	        		            	{errors.saturated_fat && touched.saturated_fat && ( <span className="table-field-input-error">{errors.saturated_fat}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="saturated_fat" value={values.saturated_fat} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">g</td>
		    	        		        </tr>
		    	        		        <tr className="table-field-row" data-field="carbohydrate">
		    	        		            <th className="table-field-header">
		    	        		            	Kulhydrater
		    	        		            	{errors.carbohydrate && touched.carbohydrate && ( <span className="table-field-input-error">{errors.carbohydrate}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="carbohydrate" value={values.carbohydrate} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">g</td>
		    	        		        </tr>
		    	        		        <tr className="table-field-row" data-field="sugar">
		    	        		            <th className="table-field-header">
		    	        		            	- heraf sukkerarter
		    	        		            	{errors.sugar && touched.sugar && ( <span className="table-field-input-error">{errors.sugar}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="sugar" value={values.sugar} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">g</td>
		    	        		        </tr>
		    	        		        <tr className="table-field-row" data-field="protein">
		    	        		            <th className="table-field-header">
		    	        		            	Protein
		    	        		            	{errors.protein && touched.protein && ( <span className="table-field-input-error">{errors.protein}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="protein" value={values.protein} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">g</td>
		    	        		        </tr>
		    	        		        <tr className="table-field-row" data-field="salt">
		    	        		            <th className="table-field-header">
		    	        		            	Salt
		    	        		            	{errors.salt && touched.salt && ( <span className="table-field-input-error">{errors.salt}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="salt" value={values.salt} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">g</td>
		    	        		        </tr>
		    	        		        <tr className="table-field-row" data-field="fiber">
		    	        		            <th className="table-field-header">
		    	        		            	Kostfibre
		    	        		            	{errors.fiber && touched.fiber && ( <span className="table-field-input-error">{errors.fiber}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="fiber" value={values.fiber} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">g</td>
		    	        		        </tr>
		    	        		    </tbody>
		    	        		</table>

		    	        		<br/><br/>
		    	        		<table className="table-fields">
		    	        		    <tbody>
		    	        		        <tr className="table-field-row">
		    	        		            <th className="table-field-header">
		    	        		            	Vitamin A
		    	        		            	{errors.vitamin_a && touched.vitamin_a && ( <span className="table-field-input-error">{errors.vitamin_a}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="1" className="table-field-input" name="vitamin_a" value={values.vitamin_a} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">IU</td>
		    	        		        </tr>

		    	        		        <tr className="table-field-row">
		    	        		            <th className="table-field-header">
		    	        		            	Vitamin B6 (1)
		    	        		            	{errors.vitamin_b6 && touched.vitamin_b6 && ( <span className="table-field-input-error">{errors.vitamin_b6}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="vitamin_b6" value={values.vitamin_b6} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">mg</td>
		    	        		        </tr>
		    	        		       
		    	        		        <tr className="table-field-row">
		    	        		            <th className="table-field-header">
		    	        		            	Vitamin B12 (Kobalamin)
		    	        		            	{errors.vitamin_b12 && touched.vitamin_b12 && ( <span className="table-field-input-error">{errors.vitamin_b12}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="vitamin_b12" value={values.vitamin_b12} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">µg</td>
		    	        		        </tr>
		    	        		       
		    	        		        <tr className="table-field-row">
		    	        		            <th className="table-field-header">
		    	        		            	Vitamin C
		    	        		            	{errors.vitamin_c && touched.vitamin_c && ( <span className="table-field-input-error">{errors.vitamin_c}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="vitamin_c" value={values.vitamin_c} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">mg</td>
		    	        		        </tr>
		    	        		        
		    	        		        <tr className="table-field-row">
		    	        		            <th className="table-field-header">
		    	        		            	Vitamin D
		    	        		            	{errors.vitamin_d && touched.vitamin_d && ( <span className="table-field-input-error">{errors.vitamin_d}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="vitamin_d" value={values.vitamin_d} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">mg</td>
		    	        		        </tr>
		    	        		       
		    	        		        <tr className="table-field-row">
		    	        		            <th className="table-field-header">
		    	        		            	Kalcium
		    	        		            	{errors.calcium && touched.calcium && ( <span className="table-field-input-error">{errors.calcium}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="calcium" value={values.calcium} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">mg</td>
		    	        		        </tr>
		    	        		       
		    	        		        <tr className="table-field-row">
		    	        		            <th className="table-field-header">
		    	        		            	Jern
		    	        		            	{errors.iron && touched.iron && ( <span className="table-field-input-error">{errors.iron}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="iron" value={values.iron} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">mg</td>
		    	        		        </tr>
		    	        		        
		    	        		        <tr className="table-field-row">
		    	        		            <th className="table-field-header">
		    	        		            	Magnesium
		    	        		            	{errors.magnesium && touched.magnesium && ( <span className="table-field-input-error">{errors.magnesium}</span> )}
		    	        		            </th>
		    	        		            <td className="table-field-input-column">
		    	        		            	<input type="number" step="0.1" className="table-field-input" name="magnesium" value={values.magnesium} onChange={handleChange} onBlur={handleBlur}/>
		    	        		            </td>
		    	        		            <td className="table-field-suffix">mg</td>
		    	        		        </tr>
		    	        		        
		    	        		    </tbody>
		    	        		</table>
	    	        		</div>

	    	        	</div>

	    	        
	    	        	<div className="field-section standard-field-section">

	    	        		<div className="field-section-header">Allergener</div>

	    	        		<div className="standard-field standard-field--wide" data-field="allergies">
	    	        			
	    	        			<ul className="checkbox-field-input-container">
									<li className="checkbox-field-input-item">
		    	        				<label>
		    	        					<Field type="checkbox" name="allergies" value="Gluten" />
		    	        					Gluten
		    	        				</label>
	    	        				</li>

									<li className="checkbox-field-input-item">
	    	        				    <label>
	    	        				    	<Field type="checkbox" name="allergies" value="Egg" />
		    	        					Æg
		    	        				</label>
	    	        				</li>

	    	        				<li className="checkbox-field-input-item">
	    	        				    <label>
	    	        				    	<Field type="checkbox" name="allergies" value="Fish" />
		    	        					Fisk
		    	        				</label>
	    	        				</li>

	    	        				<li className="checkbox-field-input-item">
	    	        				    <label>
	    	        				    	<Field type="checkbox" name="allergies" value="Soy" />
		    	        					Soja
		    	        				</label>
	    	        				</li>

	    	        				<li className="checkbox-field-input-item">
	    	        				    <label>
	    	        				    	<Field type="checkbox" name="allergies" value="Dairy" />
		    	        					Majeriprodukter
		    	        				</label>
	    	        				</li>

	    	        				<li className="checkbox-field-input-item">
	    	        				    <label>
	    	        				    	<Field type="checkbox" name="allergies" value="Sesame seeds" />
		    	        					Sesamfrø
		    	        				</label>
	    	        				</li>
	    	        				<li className="checkbox-field-input-item">
	    	        				    <label>
	    	        				    	<Field type="checkbox" name="allergies" value="Nuts" />
		    	        					Nødder
		    	        				</label>
	    	        				</li>
	    	        				<li className="checkbox-field-input-item">
		    	        				<label>
		    	        					<Field type="checkbox" name="allergies" value="Shellfish" />
		    	        					Skaldyr
		    	        				</label>
	    	        				</li>
	    	        				<li className="checkbox-field-input-item">
	    	        				    <label>
	    	        				    	<Field type="checkbox" name="allergies" value="Yeast" />
		    	        					Gær
		    	        				</label>
	    	        				</li>
	    	        			</ul>

	    	        		</div>
	    	        	</div>


	    	        	<div className="field-section standard-field-section">

	    	        		<div className="field-section-header">Bærerdygtighed</div>


	    	        		<div className="standard-field" data-field="country_of_origin">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Oprindelsesland</label>
	    	        			</div>
	    	        			<div className="standard-field-input-container">
	    	        				<Select
	    	        					styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
	    	        					placeholder="Vælg land"
	    	        					value={values.country_of_origin}
	    	        					onChange={option => {
	    	        						setFieldValue("country_of_origin", option);
	    	        						handleFormChange();
	    	        					}}
	    	        					onBlur={handleBlur}
	    	        					options={country_options} 
	    	        				/>
	    	        			</div>
	    	        		</div>

	    	        		<div className="standard-field" data-field="co2_per_inventory_unit">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Co2-afstryk pr lagerenhed</label>
	    	        			</div>
	    	        			<div className="standard-field-input-container">
	    	        				<input type="number" step="any" className="standard-field-input" placeholder="" name="co2_per_inventory_unit" value={values.co2_per_inventory_unit} onChange={handleChange} onBlur={handleBlur}/>
	    	        				{errors.co2_per_inventory_unit && touched.co2_per_inventory_unit && ( <div className="standard-field-input-error">{errors.co2_per_inventory_unit}</div> )}
	    	        			</div>	    	        		
	    	        		</div>

	    	        		

	    	        		<div className="standard-field standard-field--wide">

	    	        			<div className="standard-field-label-container" data-field="classifications">
	    	        				<label className="standard-field-label">Klassificeringer</label>
	    	        			</div>
	    	        			
	    	        			<ul className="checkbox-field-input-container">
	    	        				<li className="checkbox-field-input-item">
	    	        					<label>
	    	        						<Field type="checkbox" name="classifications" value="animal product" />
		    	        					Animalsk
		    	        				</label>
	    	        				</li>
	    	        				<li className="checkbox-field-input-item">
	    	        					<label>
	    	        						<Field type="checkbox" name="classifications" value="Organic EU" />
		    	        					Økologisk (eu)
		    	        				</label>
	    	        				</li>
	    	        				<li className="checkbox-field-input-item">
	    	        					<label>
	    	        						<Field type="checkbox" name="classifications" value="Organic DK" />
		    	        					Statskontrolleret Dansk Økologisk
		    	        				</label>
	    	        				</li>
	    	        				<li className="checkbox-field-input-item">
	    	        					<label>
	    	        						<Field type="checkbox" name="classifications" value="Biodynamic" />
		    	        					Biodynamisk
		    	        				</label>
	    	        				</li>

	    	        				<li className="checkbox-field-input-item">
	    	        					<label>
	    	        						<Field type="checkbox" name="classifications" value="Whole_grain" />
		    	        					Fuldkornsmærket
		    	        				</label>
	    	        				</li>
	    	        				<li className="checkbox-field-input-item">
	    	        					<label>
	    	        						<Field type="checkbox" name="classifications" value="Noglehulsmaerket" />
		    	        					Nøglehulsmærket
		    	        				</label>
	    	        				</li>

	    	        				<li className="checkbox-field-input-item">
	    	        					<label>
	    	        						<Field type="checkbox" name="classifications" value="Halal" />
		    	        					Halal
		    	        				</label>
	    	        				</li>
	    	        				<li className="checkbox-field-input-item">
	    	        					<label>
	    	        						<Field type="checkbox" name="classifications" value="Vegan" />
		    	        					Vegansk
		    	        				</label>
	    	        				</li>
	    	        				<li className="checkbox-field-input-item">
	    	        					<label>
	    	        						<Field type="checkbox" name="classifications" value="Fair trade" />
		    	        					Fair trade
		    	        				</label>
	    	        				</li>

	    	        			</ul>
	    	        		</div>

	    	        		<div className="standard-field">
	    	        			<div className="standard-field-label-container" data-field="type_of_transportation">
	    	        				<label className="standard-field-label">Primær transporttype</label>
	    	        			</div>
	    	        			<div className="standard-field-input-container">
	    	        				<Select
	    	        					styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
	    	        					placeholder="Vælg land"
	    	        					value={values.type_of_transportation}
	    	        					onChange={option => {
	    	        						setFieldValue("type_of_transportation", option);
	    	        						handleFormChange();
	    	        					}}
	    	        					onBlur={handleBlur}
	    	        					options={[
	    	        						{value: 'ship', label: 'Skib'},
	    	        						{value: 'car', label: 'Bil'},
	    	        						{value: 'plane', label: 'Fly'},
	    	        						{value: 'no answer', label: 'Ved ikke'}
	    	        					]} 
	    	        				/>
	    	        			</div>
	    	        		</div>

	    	        	</div>
	    	        	

	    	        	{/*
	    	        	<div className="field-section field-section-submit">
	    	        		<button type="submit" className="standard-submit button button--medium" disabled={isSubmitting}>GEM</button>
	    	        		<p className="standard-submit-update">Sidst opdateret d. 12 august 2020 kl. 11:35</p>
	    	        	</div>
	    	        	*/}
	    	        	

	    	        	{
	    	        		formChange === true ? (
	    	        			<div className="fixed-action-bar">
	    	        				<button type="button" className="button button--ghost button--medium" onClick={() => cancelChanges(resetForm)}>ANNULER</button>
	    	        				<button type="submit" className="standard-submit button button--medium" disabled={isSubmitting}>GEM</button>
	    	        			</div>
	    	        		) : (
	    	        			<div className="fixed-action-bar fixed-action-bar--hidden">
	    	        				<button type="button" className="button button--ghost button button--medium" onClick={() => cancelChanges(resetForm)}>ANNULER</button>
	    	        				<button type="submit" className="standard-submit button button--medium" disabled={isSubmitting}>GEM</button>
	    	        			</div>
	    	        		)	
	    	        	}

	    	        	{
	    	        		formSaved === true ? (
	    	        			<div className="fixed-action-bar fixed-action-bar--success">Gemt!</div>
	    	        		) : (
	    	        			<div className="fixed-action-bar fixed-action-bar--success fixed-action-bar--hidden">Gemt!</div>
	    	        		)	
	    	        	}
	    	        	
	    	        
	    	        	<FocusError />

	    	         </form>
	    	       )}


				</Formik>

			

				{
					props.match.params.id && (
						<div className="danger-zone">

							<div className="danger-zone-section-heading">
								<h2 className="danger-zone-section-title">Danger Zone</h2>
							</div>

							
								

								{
									relatedPrepData.length === 0 && relatedRecipesData.length === 0 ? (
										<div className="danger-zone-content">
											<h3 className="danger-zone-title">Slet denne ingredients</h3>
												<p>Når du sletter den, vil du ikke kunne få den tilbage. Du vil være nødt til at oprette den igen forfra</p>
												<button className="button button--warning" onClick={handleDelete}>SLET</button>
										</div>
									) : (
										<div className="danger-zone-content">
											<h3 className="danger-zone-title">Slet denne ingredients</h3>
											<p>Denne ingredient kan ikke slettes, da den er benyttet i følgende opskrifter. Fjern først denne ingredient fra opskrifter, før du kan slette den.</p>
											
											
											{
												relatedPrepData.length > 0 && (
													<Fragment>
														<h4>Prep</h4>
														<ul>
															{
																relatedPrepData.map((item, i) => {
																	return (
																		<li key={i}>
																			<Link to={`/menu/prep/${item._id}`}>
																				{item.name}
																			</Link>
																		</li>
																	)
																})
															}
														</ul>
													</Fragment>

												)
											}
											

											{
												relatedRecipesData.length > 0 && (
													<Fragment>
														<h4>Opskrifter</h4>
														<ul>
															{
																relatedRecipesData.map((item, i) => {
																	return (
																		<li key={i}>
																			<Link to={`/menu/recipes/${item._id}`}>
																				{item.name}
																			</Link>
																		</li>
																	)
																})
															}
														</ul>
													</Fragment>

												)
											}

											<button className="button button--warning" onClick={handleDelete} disabled="disabled">SLET</button>
										</div>
									)

								}
							
						</div>
					)
				}

				<div className="form-padding"></div>

			</div>
		</div>

	); 
};

export default IngredientForm;
