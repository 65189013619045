import React from "react";
import Select from 'react-select'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


export const RepeatableItemsTable = (props) => {

	/**
	 * Add row to the table
	 */
	const addTableRow = () => {

		// Create new items array
		let units = Array.from(props.custom_units);
		
		// Add a new item
		units.push({
			//_id: dragId,
			value: '',
			name: '',
			inventory_units_per_unit: '',
		})

		// Update form values
		props.setFieldValue('custom_units', units);
		
		// Mark form as changed
		props.handleFormChange();
	}

	/**
	 * Delete specified row
	 */
	const deleteTableRow = (rowId) => {

		// Create new array of items based on existing (before drag)		
		let units = Array.from(props.custom_units);

		const unitIndex = units.findIndex((unit, i) => i === rowId);
		console.log(unitIndex);

		units.splice(unitIndex, 1);

		// Update form values
		props.setFieldValue('custom_units', units);

		props.handleFormChange();
	}


	/**
	 * Handle rearranging of items after dropping an item.
	 */
	const onDragEnd = (result) => {
		const {destination, source, draggableId} = result;

		// Dropped outside droppable area
		if (!destination) {
			return;
		}

		// Dropped at the same location as it was
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		// Create new array of items based on existing (before drag)		
		let units = Array.from(props.custom_units);

		// Find the dragged item
		//let draggedItem = units.find((unit, index) => index === draggableId);

		let draggedItem = units[draggableId];

		// Remove the dragged item from array;
		units.splice(source.index, 1);

		// Add the dragged item into the new position
		units.splice(destination.index, 0, draggedItem);

		// Update items state
		props.setFieldValue('custom_units', units);

		// Mark form change
		props.handleFormChange();
	}


	
	

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			
			<table className="recipe-table">
				{/*
				<thead>
					<tr>
						<th>Ingrediens</th>
						<th>Mængde</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				*/}

				<Droppable droppableId="RepeatableUnits">
					{provided => (
						<tbody ref={provided.innerRef} {...provided.droppableProps}>
							
							{
								props.custom_units.map((unit, index) => {

									let select_value = '';
									if (unit.name !== '') {
										select_value = unit;	
									} 

									return (
										<Draggable key={index} draggableId={index.toString()} index={index}>
											{provided => (
												<tr className="recipe-table-row" {...provided.draggableProps} ref={provided.innerRef}>
													<td className="recipe-table-count_unit_name">
														<Select
															styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
															placeholder="Vælg enhed"
															value={select_value}
															onChange={option => {
																props.setFieldValue(`custom_units[${index}]`, option);
																props.handleFormChange();
															}}
															onBlur={props.handleBlur}
															options={props.units} 
														/>
													</td>
													
													
													<td className="recipe-table-count_unit_amount">
														<div className="field-with-suffix">
															<input type="number" step="1" className="input-field-with-suffix" placeholder="Mængde" value={unit.inventory_units_per_unit} name={`custom_units[${index}].inventory_units_per_unit`} onChange={props.handleChange} onBlur={props.handleBlur} />
															<div className="field-suffix">{props.values.inventory_unit_of_measure.label}</div>
														</div>
													</td>

													<td className="recipe-table-remove">
														<button type="button" className="recipe-table-remove-button" onClick={() => deleteTableRow(index)}>–</button>
													</td>
													<td className="recipe-table-drag-handle" {...provided.dragHandleProps}>
														<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512"><g id="Group_61"><path id="Rectangle_147" d="M135.1 15.6h80.3v80.3h-80.3z"/><path id="Rectangle_148" d="M135.1 216.3h80.3v80.3h-80.3z"/><path id="Rectangle_149"  d="M135.1 417h80.3v80.3h-80.3z"/></g><g id="Group_64" transform="translate(4)"><path id="Rectangle_147-2" d="M291.6 15.6h80.3v80.3h-80.3z"/><path id="Rectangle_148-2" d="M291.6 216.3h80.3v80.3h-80.3z"/><path id="Rectangle_149-2" d="M291.6 417h80.3v80.3h-80.3z"/></g></svg>
													</td>
												</tr>
											)}		
										</Draggable>
									)
								})
							}
							
							{provided.placeholder}
						</tbody>
					)}
				</Droppable>

			</table>
			{
				props.custom_units.length < 2 && (
					<button type="button" className="recipe-table-add-row" onClick={() => addTableRow()}>+</button>
				)
			}
			


			
		</DragDropContext>
	); 
};

export default RepeatableItemsTable;
