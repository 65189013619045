import React from 'react';
import { Router, Route, Switch } from "react-router-dom";

import Loading from "./components/Loading";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Ingredients from "./pages/Ingredients";
import Prep from "./pages/Prep";
import Recipes from "./pages/Recipes";
import Inventory from "./pages/Inventory";


import Counts from "./pages/Counts";
import Count from "./pages/Count";
import CountNew from "./pages/CountNew";
//import Settings from "./pages/Settings";
import Inventories from "./pages/Inventories";
import InventoriesEdit from "./pages/InventoriesEdit";
import Suppliers from "./pages/Suppliers";
import SuppliersEdit from "./pages/SuppliersEdit";
import Categories from "./pages/Categories";
import CategoriesEdit from "./pages/CategoriesEdit";
import Units from "./pages/Units";
import UnitsEdit from "./pages/UnitsEdit";
import OrderNew from "./pages/OrderNew";
import Orders from "./pages/Orders";

import StockReconciliation from "./pages/StockReconciliation";


import { useAuth0 } from "@auth0/auth0-react";
import history from "./history";

import ScrollToTop from "./components/ScrollToTop";

function App() {
	
	const { isLoading, error } = useAuth0();

	//console.log(useLocation());

	if (error) {
	  return <div>Oops... {error.message}</div>;
	}

	if (isLoading) {
	  return <Loading />;
	}

	return (
		<Router history={history}>
			<div className="App">
				<ScrollToTop/>
				<Switch>
					<Route path="/" exact component={Login} />
					<Route path="/dashboard" component={Dashboard} />

					<Route path="/menu/ingredients" component={Ingredients} />

					<Route path="/menu/ingredients/new" component={Ingredients} />
					<Route path="/menu/ingredients/:id" component={Ingredients} />


					<Route path="/menu/prep" component={Prep} />
				  	<Route path="/menu/recipes" component={Recipes} />
				  
				  	<Route path="/inventory/status" component={Inventory} />
				  
					<Route path="/inventory/counts" exact component={Counts} />
					<Route path="/inventory/counts/new" exact component={CountNew} />
					<Route path="/inventory/counts/:id" component={Count} />


					<Route path="/inventory/order" exact component={OrderNew} />
					<Route path="/inventory/orders" exact component={Orders} />

					<Route path="/reports/stock-reconciliation" exact component={StockReconciliation} />


					<Route path="/settings/inventories" exact component={Inventories} />
					<Route path="/settings/inventories/new" component={InventoriesEdit} />
					<Route path="/settings/inventories/:id" component={InventoriesEdit} />


					<Route path="/settings/suppliers" exact component={Suppliers} />
					<Route path="/settings/suppliers/new" component={SuppliersEdit} />
					<Route path="/settings/suppliers/:id" component={SuppliersEdit} />

					<Route path="/settings/categories" exact component={Categories} />
					<Route path="/settings/categories/new" component={CategoriesEdit} />
					<Route path="/settings/categories/:id" component={CategoriesEdit} />

					<Route path="/settings/units" exact component={Units} />
					<Route path="/settings/units/new" component={UnitsEdit} />
					<Route path="/settings/units/:id" component={UnitsEdit} />
				</Switch>
			</div>
		</Router>
		
  );
}

export default App;
