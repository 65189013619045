import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import { Formik } from 'formik';
import Select from 'react-select';
import FocusError from "../components/FocusError";
import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

const api_url = process.env.REACT_APP_GROED_API_URL;

// Default state
const defaultFormState = {
	inventory: '',
	type: '',
	items: [],
	date: new Date(),
}

export const InventoryEdit = (props) => {
	
	// Variables from History (react router)
	const history = useHistory();

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// STATE: Invetory data
	const [inventoryData, setInventoryData] = useState([]);

	/**
  	 * Get data from DB
  	 */
	useEffect(() => {
		
		(async () => {

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get inventories
				const inventoryResponse = await axios.get(`${api_url}/inventory`, { headers: { 'Authorization': `Bearer ${access_token}`}});	
				const inventoryData = inventoryResponse.data.inventories.sort((a, b) => (a.name > b.name) ? 1 : -1)
				setInventoryData(inventoryData);

				
			} catch (e) {
        		console.error(e);
      		}

		})();		

	}, [getAccessTokenSilently]);

  	/**
  	 * Save inventory
  	 */
	const saveCount = async (data) => {

		console.log(data);

		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {headers: { 'Authorization': `Bearer ${access_token}`}}
			const axiosPromise = axios.post(`${api_url}/count`, data, config);	
			const response = axiosPromise;
			
			return response;

		} catch (e) {
			console.error(e);
		}
	}


	/**
	 * Handle form submission
	 */
	const sendData = async (data) => {

		console.log('sendData');

		data.status = "draft";

		// Send form data
		const submitResponse = await saveCount(data);

		// If everything goes well, Redirect to new item URL
		if (submitResponse && submitResponse.status === 200) {

			const id = submitResponse.data.count._id;
			history.push(`/inventory/counts/${id}`);

		} else {
			// Error message?
		}
	}



	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Inventory"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">
					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="breadcrumbs">/lager/optællinger/ny optælling</div>

							<div className="page-content-header">
								<h1 className="page-title">Ny Optælling</h1>
							</div>
							<br/><br/>

							<Formik
								initialValues={{
									...defaultFormState
								}}
								enableReinitialize={true}

								validate={ values => {
									const errors = {};

									// Required fields
									const required_fields = [
										'inventory',
										'type',
									];
									
									for(const required_field of required_fields) {
										if (values[required_field] === '') {
											errors[required_field] = 'Dette felt er påkrævet';
										}	
									}

									return errors;
								}}
							   
								onSubmit={(values, { setSubmitting }) => {
										
									// Copy values
									let submitted_values = {...values};
									
									if (values.inventory.value) {
										submitted_values.inventory = values.inventory.value;
									}

									if (values.type.value) {
										submitted_values.type = values.type.value;
									}

									// Send data
									sendData(submitted_values);

									setTimeout(() => {
										//alert(JSON.stringify(values, null, 2));
										setSubmitting(false);
									}, 2000);
							   }}
							>

							{({
								 values,
								 errors,
								 touched,
								 handleChange,
								 handleBlur,
								 handleSubmit,
								 isSubmitting,
								 setFieldValue,
								 resetForm,
								 isValid,
								 dirty,
								 /* and other goodies */
							}) => (
								<form onSubmit={handleSubmit}>
									
									<div className="field-section standard-field-section">

										<div className="standard-field" data-field="inventory">
											<div className="standard-field-label-container">
												<label className="standard-field-label">Lager</label>
											</div>
											<div className="standard-field-input-container">
												<Select
													styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
													placeholder="Vælg lager"
													value={values.inventory}
													onChange={option => {
														setFieldValue("inventory", option);
													}}
													onBlur={handleBlur}
													options={ inventoryData.map(inventory => ({ value: inventory._id, label: inventory.name}) ) }
												/>
											</div>
										</div>

										<div className="standard-field" data-field="type">
											<div className="standard-field-label-container">
												<label className="standard-field-label">Tidspunkt</label>
											</div>
											<div className="standard-field-input-container">
												<Select
													styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
													placeholder="Vælg tidspunkt"
													value={values.type}
													onChange={option => {
														setFieldValue("type", option);
													}}
													onBlur={handleBlur}
													options={[
														{
															value: 'before-opening',
															label: 'Før åben',
														},
														{
															value: 'after-closing',
															label: 'Efter luk',
														}
													]}
												/>
											</div>
										</div>

										<div className="standard-field" data-field="zipcode">
											<div className="standard-field-label-container">
												<label className="standard-field-label">Dato</label>
											</div>
											<div className="standard-field-input-container">
												<input type="hidden" className="standard-field-input" name="date" value={moment(values.date).format('YYYY-MM-DD H:m')} onChange={handleChange} onBlur={handleBlur}/>
												<input type="text" className="standard-field-input" name="date" value={moment(values.date).format('DD/MM/YYYY')} readOnly disabled/>
											</div>	    	        		
										</div>

									</div>
									
									<button type="submit" className="standard-submit button button--medium" disabled={!(isValid && dirty)}>Start optælling</button>			
						
									<FocusError/>				
								</form>
							)}


							</Formik>

							<div className="form-padding"></div>

						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(InventoryEdit, {
  onRedirecting: () => <Loading />,
});
