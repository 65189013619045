import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Formik } from 'formik';
import Select from 'react-select';
import FocusError from "../components/FocusError";
import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

const order_methods = [
	{value: 'none', label: 'None'},
	{value: 'email', label: 'E-mail'},
	{value: 'bc-catering-ftp', label: 'BC Catering FTP integration'}
]	
const api_url = process.env.REACT_APP_GROED_API_URL;

// Default state
const defaultFormState = {
	_id: undefined,
	// Base data
	name: '',
}

export const SuppliersEdit = (props) => {
	
	// Variables from History (react router)
	const history = useHistory();

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Form state
	const [formData, setFormData] = useState(defaultFormState);

	// Form state
	const [formChange, setFormChange] = useState(false);

	// Form state
	const [formSaved, setFormSaved] = useState(false);

	// STATE: Invetory data
	const [supplierData, setSupplierData] = useState([]);

	/**
	 * Check for change in param ID and prep
	 */ 
	useEffect(() => {
		
		// Figure out if this an existing item, or a new one. 
		if (props.match.params.id) {

			(async () => {

				try {
					// Get access token
					const access_token = await getAccessTokenSilently();

					// Get inventories
					const supplierResponse = await axios.get(`${api_url}/supplier`, { headers: { 'Authorization': `Bearer ${access_token}`}});	
					const supplierData = supplierResponse.data.suppliers.sort((a, b) => (a.name > b.name) ? 1 : -1)
					setSupplierData(supplierData);

					
				} catch (e) {
	        		console.error(e);
	      		}

			})();

		} else {
			// New document
			setFormData({...defaultFormState});
		}

	}, [props.match.params.id]);


	/**
	 * Check for changes inventoryData
	 */ 
	useEffect(() => {
		
		if (props.match.params.id) {
			// Find data in props.prep
			const currentSupplier = supplierData.find(supplier => supplier._id === props.match.params.id);

			// Populate formData state with data
			if (currentSupplier) {
				
				// Remove all null values from DB, since we don't want to set field values to null. 
				// There may be a better way to handle this, But probably a good idea to keep this just to ensure, that we don't set Formik values to null or undefined. Better to use empty string.
				for (var propName in currentSupplier) { 
					if (currentSupplier[propName] === null) {
					  delete currentSupplier[propName];
					}
				}

				setFormData({...defaultFormState, ...currentSupplier});
			} else {
				// @TODO 404 Doesnt exist!?
			}

		}

	}, [supplierData]);


	/**
  	 * Get suppliers
  	 */
  	// const getSuppliers = async () => {

  	// 	try {
  	// 		// Get access token
  	// 		const access_token = await getAccessTokenSilently();

  	// 		let config = {headers: { 'Authorization': `Bearer ${access_token}`}}
  	// 		const axiosPromise = axios.get(`${api_url}/supplier`, config);	
  	// 		const response = axiosPromise;

  	// 		return response
  			
  	  
  	// 	} catch (e) {
  	//     	console.error(e);
  	// 	}
  	// }

  	/**
  	 * Save inventory
  	 */
	const saveSupplier = async (data) => {

		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {headers: { 'Authorization': `Bearer ${access_token}`}}
			const axiosPromise = axios.post(`${api_url}/supplier`, data, config);	
			const response = axiosPromise;
			
			return response;

		} catch (e) {
			console.error(e);
		}
	}

	/**
  	 * Delete inventory
  	 */
	const deleteSupplier = async (data) => {
	
		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {
				headers: { 'Authorization': `Bearer ${access_token}`},
				data: { _id: data._id }
			}
			const axiosPromise = axios.delete(`${api_url}/supplier`, config);	
			const response = axiosPromise;

			return response;
			      
		} catch (e) {
			console.error(e);
		}
	}


	/**
	 * Indicate that form has changes
	 */
	const handleFormChange = () => {
		console.log('change made');
		setFormChange(true);
	}

	/**
	 * Revert changes made
	 */
	const cancelChanges = (resetForm) => {
		setFormChange(false);
		resetForm();
	}

	/**
	 * Indicate that the form was saved successfully
	 */
	// const formJustSaved = () => {
	// 	setFormSaved(true);
	// 	setTimeout(() => {
	// 		//alert(JSON.stringify(values, null, 2));
	// 		setFormSaved(false);
	// 	}, 1000);
	// }

	/**
	 * Handle form submission
	 */
	const sendData = async (data) => {

		console.log('sendData');

		// Send form data
		const submitResponse = await saveSupplier(data);


		// If everything goes well, Redirect to new item URL
		if (submitResponse && submitResponse.status === 200) {
			// setFormChange(false);
			// formJustSaved();

			// // Reload data
			// const dataResponse = await getInvetories();
			// setInventoryData(dataResponse.data.inventories);

			// const id = submitResponse.data.inventory._id;
			// history.push(`/settings/inventories/${id}`);
			history.push(`/settings/suppliers/`);
		} else {
			// Error message?
		}
	}

	/**
	 * Handle Delete
	 */
	const handleDelete = async (event) => {
		event.preventDefault();
		
		// Ask if user is sure they want to delete. No going back! 
		if (window.confirm("Er du helt sikker på at du vil slette?")) {
			// Send form data
			const deleteResponse = await deleteSupplier({_id: formData._id});

			// If everything goes well, Redirect to prep front page
			if (deleteResponse.status === 200) {
				history.push("/settings/suppliers");
			}	
		}

	}

	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Inventory"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">
					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							{
								props.match.params.id ? (
									<div className="breadcrumbs">/indstillinger/lagre/{formData.name}</div>
								) : (
									<div className="breadcrumbs">/indstillinger/lagre/nyt lager</div>
								)
							}


							<Formik
								initialValues={{ 
									_id: props.match.params.id || '',
									// name: formData.name,
									// supplier: formData.supplier,
									// account: formData.category,
									// sku: formData.sku,
									...formData
								}}

								enableReinitialize={true}

								validate={ values => {


									const errors = {};

									// Required fields
									const required_fields = [
									'name',
									];

									for(const required_field of required_fields) {
										if (values[required_field] === '') {
											errors[required_field] = 'Dette felt er påkrævet';
										}	
									}

									return errors;
								}}
							   
								onSubmit={(values, { setSubmitting }) => {
										
									// Copy values
									let submitted_values = {...values};
									
									if (submitted_values.order_method.value) {
										submitted_values.order_method = submitted_values.order_method.value;
									}

									// Send data
									sendData(submitted_values);

									// setTimeout(() => {
									// 	//alert(JSON.stringify(values, null, 2));
									// 	setSubmitting(false);
									// }, 2000);
							   }}
							>

							{({
								 values,
								 errors,
								 touched,
								 handleChange,
								 handleBlur,
								 handleSubmit,
								 isSubmitting,
								 setFieldValue,
								 resetForm,
								 /* and other goodies */
							}) => (
								<form onSubmit={handleSubmit} onChange={() => handleFormChange()}>
									
									<div className="title-field" data-field="name">
										<label className="title-field-label">Navn</label>
										<div className="title-field-input-container">
											<input type="text" className="title-field-input" placeholder="Indtast navn" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
										</div>
										{errors.name && touched.name && ( <div className="standard-field-input-error">{errors.name}</div> )}
									</div>

									<div className="field-section standard-field-section">

										<div className="standard-field" data-field="minimum_order_value">
											<div className="standard-field-label-container">
												<label className="standard-field-label">Minimumsbeløb per bestilling</label>
											</div>
											<div className="standard-field-input-container">
												<input type="number" className="standard-field-input" placeholder="Ingen" name="minimum_order_value" value={values.minimum_order_value} onChange={handleChange} onBlur={handleBlur}/>
												{errors.minimum_order_value && touched.minimum_order_value && ( <div className="standard-field-input-error">{errors.minimum_order_value}</div> )}
											</div>	    	        		
										</div>

										<div className="standard-field" data-field="order_email">
											<div className="standard-field-label-container">
												<label className="standard-field-label">E-mail til bestillinger</label>
											</div>
											<div className="standard-field-input-container">
												<input type="text" className="standard-field-input" placeholder="Indtast email" name="order_email" value={values.order_email} onChange={handleChange} onBlur={handleBlur}/>
												{errors.order_email && touched.order_email && ( <div className="standard-field-input-error">{errors.order_email}</div> )}
											</div>	    	        		
										</div>

										<div className="standard-field" data-field="order_method">
											<div className="standard-field-label-container">
												<label className="standard-field-label">Bestillingsmetode</label>
											</div>
											<div className="standard-field-input-container">
												<Select
													styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
													placeholder="Vælg metode"
													value={ order_methods.find(m => m.value === values.order_method) }
													onChange={option => {
														setFieldValue("order_method", option);
														handleFormChange();
													}}
													onBlur={handleBlur}
													options={order_methods} 
												/>
											</div>
										</div>

									</div>


									{
										formChange === true ? (
											<div className="fixed-action-bar fixed-action-bar--wide">
												<button type="button" className="button button--ghost button--medium" onClick={() => cancelChanges(resetForm)}>ANNULER</button>
												<button type="submit" className="standard-submit button button--medium" disabled={isSubmitting}>GEM</button>
											</div>
										) : (
											<div className="fixed-action-bar fixed-action-bar--wide fixed-action-bar--hidden">
												<button type="button" className="button button--ghost button button--medium" onClick={() => cancelChanges(resetForm)}>ANNULER</button>
												<button type="submit" className="standard-submit button button--medium" disabled={isSubmitting}>GEM</button>
											</div>
										)	
									}

									{
										formSaved === true ? (
											<div className="fixed-action-bar fixed-action-bar--wide fixed-action-bar--success">Gemt!</div>
										) : (
											<div className="fixed-action-bar fixed-action-bar--wide fixed-action-bar--success fixed-action-bar--hidden">Gemt!</div>
										)	
									}
									
									<FocusError/>				
								</form>
							)}


							</Formik>

							{
								props.match.params.id && (
									<div className="danger-zone">

										<div className="danger-zone-section-heading">
											<h2 className="danger-zone-section-title">Danger Zone</h2>
										</div>

										<div className="danger-zone-content">
											<h3 className="danger-zone-title">Slet lager</h3>
											<p>Når du sletter den, vil du ikke kunne få den tilbage. Du vil være nødt til at oprette den igen forfra</p>
											<button className="button button--warning" onClick={handleDelete}>SLET</button>
										</div>
									</div>
								)
							}


							<div className="form-padding"></div>

						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(SuppliersEdit, {
  onRedirecting: () => <Loading />,
});
