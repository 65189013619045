import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Formik } from 'formik';
import Select from 'react-select';
import FocusError from "../components/FocusError";
import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";


import countries from '../data/countries.json';
const country_options = countries.countries.map(country => {
	return {value: country.alpha3, label: country.name}
})

const api_url = process.env.REACT_APP_GROED_API_URL;

// Default state
const defaultFormState = {

	_id: undefined,

	// Base data
	name: '',
	address: '',
	zipcode: '',
	city: '',
	country: '',
	department_number: '',
}

export const InventoryEdit = (props) => {
	
	// Variables from History (react router)
	const history = useHistory();

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Form state
	const [formData, setFormData] = useState(defaultFormState);

	// Form state
	const [formChange, setFormChange] = useState(false);

	// Form state
	const [formSaved, setFormSaved] = useState(false);

	// STATE: Invetory data
	const [inventoryData, setInventoryData] = useState([]);

	/**
	 * Check for change in param ID and prep
	 */ 
	useEffect(() => {
		
		// Figure out if this an existing item, or a new one. 
		if (props.match.params.id) {

			(async () => {

				try {
					// Get access token
					const access_token = await getAccessTokenSilently();

					// Get inventories
					const inventoryResponse = await axios.get(`${api_url}/inventory`, { headers: { 'Authorization': `Bearer ${access_token}`}});	
					const inventoryData = inventoryResponse.data.inventories.sort((a, b) => (a.name > b.name) ? 1 : -1)
					setInventoryData(inventoryData);

					
				} catch (e) {
	        		console.error(e);
	      		}

			})();

		} else {
			// New document
			setFormData({...defaultFormState});
		}

	}, [props.match.params.id]);


	/**
	 * Check for changes inventoryData
	 */ 
	useEffect(() => {
		
		if (props.match.params.id) {
			// Find data in props.prep
			const currentInventory = inventoryData.find(inventory => inventory._id === props.match.params.id);
			console.log(inventoryData);
			// Populate formData state with data
			if (currentInventory) {
				
				if (currentInventory.country && currentInventory.country.alpha3 && currentInventory.country.name) {
					currentInventory.country = {
						value: currentInventory.country.alpha3,
						label: currentInventory.country.name,
					}
				}

				// Remove all null values from DB, since we don't want to set field values to null. 
				// There may be a better way to handle this, But probably a good idea to keep this just to ensure, that we don't set Formik values to null or undefined. Better to use empty string.
				for (var propName in currentInventory) { 
					if (currentInventory[propName] === null || currentInventory[propName] === undefined) {
					  delete currentInventory[propName];
					}
				}

				setFormData({...defaultFormState, ...currentInventory});
			} else {
				// @TODO 404 Doesnt exist!?
			}

		}

	}, [inventoryData]);


	/**
  	 * Get Inventories
  	 */
  	// const getInvetories = async () => {

  	// 	try {
  	// 		// Get access token
  	// 		const access_token = await getAccessTokenSilently();

  	// 		let config = {headers: { 'Authorization': `Bearer ${access_token}`}}
  	// 		const axiosPromise = axios.get(`${api_url}/inventory`, config);	
  	// 		const response = axiosPromise;

  	// 		return response
  			
  	  
  	// 	} catch (e) {
  	//     	console.error(e);
  	// 	}
  	// }

  	/**
  	 * Save inventory
  	 */
	const saveInventory = async (data) => {

		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {headers: { 'Authorization': `Bearer ${access_token}`}}
			const axiosPromise = axios.post(`${api_url}/inventory`, data, config);	
			const response = axiosPromise;
			
			return response;

		} catch (e) {
			console.error(e);
		}
	}

	/**
  	 * Delete inventory
  	 */
	const deleteInventory = async (data) => {
	
		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {
				headers: { 'Authorization': `Bearer ${access_token}`},
				data: { _id: data._id }
			}
			const axiosPromise = axios.delete(`${api_url}/inventory`, config);	
			const response = axiosPromise;

			return response;
			      
		} catch (e) {
			console.error(e);
		}
	}


	/**
	 * Indicate that form has changes
	 */
	const handleFormChange = () => {
		console.log('change made');
		setFormChange(true);
	}

	/**
	 * Revert changes made
	 */
	const cancelChanges = (resetForm) => {
		setFormChange(false);
		resetForm();
	}

	/**
	 * Indicate that the form was saved successfully
	 */
	// const formJustSaved = () => {
	// 	setFormSaved(true);
	// 	setTimeout(() => {
	// 		//alert(JSON.stringify(values, null, 2));
	// 		setFormSaved(false);
	// 	}, 1000);
	// }

	/**
	 * Handle form submission
	 */
	const sendData = async (data) => {

		console.log('sendData');

		// Send form data
		const submitResponse = await saveInventory(data);


		// If everything goes well, Redirect to new item URL
		if (submitResponse && submitResponse.status === 200) {
			// setFormChange(false);
			// formJustSaved();

			// // Reload data
			// const dataResponse = await getInvetories();
			// setInventoryData(dataResponse.data.inventories);

			// const id = submitResponse.data.inventory._id;
			// history.push(`/settings/inventories/${id}`);
			history.push(`/settings/inventories/`);
		} else {
			// Error message?
		}
	}

	/**
	 * Handle Delete
	 */
	const handleDelete = async (event) => {
		event.preventDefault();
		
		// Ask if user is sure they want to delete. No going back! 
		if (window.confirm("Er du helt sikker på at du vil slette?")) {
			// Send form data
			const deleteResponse = await deleteInventory({_id: formData._id});

			// If everything goes well, Redirect to prep front page
			if (deleteResponse.status === 200) {
				history.push("/settings/inventories");
			}	
		}

	}

	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Inventory"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">
					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							{
								props.match.params.id ? (
									<div className="breadcrumbs">/indstillinger/lagre/{formData.name}</div>
								) : (
									<div className="breadcrumbs">/indstillinger/lagre/nyt lager</div>
								)
							}


							<Formik
								initialValues={{ 
									_id: props.match.params.id || '',
									// name: formData.name,
									// supplier: formData.supplier,
									// account: formData.category,
									// sku: formData.sku,
									...formData
								}}

								enableReinitialize={true}

								validate={ values => {


									const errors = {};

									// Required fields
									const required_fields = [
									'name',
									'address',
									'zipcode',
									'city',
									'country',
									];

									for(const required_field of required_fields) {
										if (values[required_field] === '') {
											errors[required_field] = 'Dette felt er påkrævet';
										}	
									}

									return errors;
								}}
							   
								onSubmit={(values, { setSubmitting }) => {
										
									// Copy values
									let submitted_values = {...values};
									
									console.log(values);

									if (submitted_values.country.value) {
										submitted_values.country.alpha3 = submitted_values.country.value;
										submitted_values.country.name = submitted_values.country.label;	
									}

									console.log(submitted_values);

									// Send data
									sendData(submitted_values);

									// setTimeout(() => {
									// 	//alert(JSON.stringify(values, null, 2));
									// 	setSubmitting(false);
									// }, 2000);
							   }}
							>

							{({
								 values,
								 errors,
								 touched,
								 handleChange,
								 handleBlur,
								 handleSubmit,
								 isSubmitting,
								 setFieldValue,
								 resetForm,
								 /* and other goodies */
							}) => (
								<form onSubmit={handleSubmit} onChange={() => handleFormChange()}>
									
									<div className="title-field" data-field="name">
										<label className="title-field-label">Navn</label>
										<div className="title-field-input-container">
											<input type="text" className="title-field-input" placeholder="Indtast navn" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
										</div>
										{errors.name && touched.name && ( <div className="standard-field-input-error">{errors.name}</div> )}
									</div>

									<div className="field-section standard-field-section">

										<div className="standard-field" data-field="address">
											<div className="standard-field-label-container">
												<label className="standard-field-label">Adresse</label>
											</div>
											<div className="standard-field-input-container">
												<input type="text" className="standard-field-input" placeholder="Indtast adresse" name="address" value={values.address} onChange={handleChange} onBlur={handleBlur}/>
												{errors.address && touched.address && ( <div className="standard-field-input-error">{errors.address}</div> )}
											</div>	    	        		
										</div>

										<div className="standard-field" data-field="zipcode">
											<div className="standard-field-label-container">
												<label className="standard-field-label">Postnummer</label>
											</div>
											<div className="standard-field-input-container">
												<input type="text" className="standard-field-input" placeholder="Indtast postnummer" name="zipcode" value={values.zipcode} onChange={handleChange} onBlur={handleBlur}/>
												{errors.zipcode && touched.zipcode && ( <div className="standard-field-input-error">{errors.zipcode}</div> )}
											</div>	    	        		
										</div>

										<div className="standard-field" data-field="city">
											<div className="standard-field-label-container">
												<label className="standard-field-label">By</label>
											</div>
											<div className="standard-field-input-container">
												<input type="text" className="standard-field-input" placeholder="Indtast by" name="city" value={values.city} onChange={handleChange} onBlur={handleBlur}/>
												{errors.city && touched.city && ( <div className="standard-field-input-error">{errors.city}</div> )}
											</div>	    	        		
										</div>

										<div className="standard-field" data-field="country">
											<div className="standard-field-label-container">
												<label className="standard-field-label">Land</label>
											</div>
											<div className="standard-field-input-container">
												<Select
													styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
													placeholder="Vælg land"
													value={values.country}
													onChange={option => {
														setFieldValue("country", option);
														handleFormChange();
													}}
													onBlur={handleBlur}
													options={country_options} 
												/>
											</div>
										</div>

										<div className="standard-field" data-field="department_number">
											<div className="standard-field-label-container">
												<label className="standard-field-label">Afdelings nr.</label>
											</div>
											<div className="standard-field-input-container">
												<input type="text" className="standard-field-input" placeholder="Indtast by" name="department_number" value={values.department_number} onChange={handleChange} onBlur={handleBlur}/>
												{errors.department_number && touched.department_number && ( <div className="standard-field-input-error">{errors.department_number}</div> )}
											</div>	    	        		
										</div>

										<div className="standard-field" data-field="email">
											<div className="standard-field-label-container">
												<label className="standard-field-label">E-mail</label>
											</div>
											<div className="standard-field-input-container">
												<input type="text" className="standard-field-input" placeholder="Indtast email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur}/>
												{errors.email && touched.email && ( <div className="standard-field-input-error">{errors.email}</div> )}
											</div>	    	        		
										</div>

									</div>

									{
										formChange === true ? (
											<div className="fixed-action-bar fixed-action-bar--wide">
												<button type="button" className="button button--ghost button--medium" onClick={() => cancelChanges(resetForm)}>ANNULER</button>
												<button type="submit" className="standard-submit button button--medium" disabled={isSubmitting}>GEM</button>
											</div>
										) : (
											<div className="fixed-action-bar fixed-action-bar--wide fixed-action-bar--hidden">
												<button type="button" className="button button--ghost button button--medium" onClick={() => cancelChanges(resetForm)}>ANNULER</button>
												<button type="submit" className="standard-submit button button--medium" disabled={isSubmitting}>GEM</button>
											</div>
										)	
									}

									{
										formSaved === true ? (
											<div className="fixed-action-bar fixed-action-bar--wide fixed-action-bar--success">Gemt!</div>
										) : (
											<div className="fixed-action-bar fixed-action-bar--wide fixed-action-bar--success fixed-action-bar--hidden">Gemt!</div>
										)	
									}
									
									<FocusError/>				
								</form>
							)}


							</Formik>

							{
								props.match.params.id && (
									<div className="danger-zone">

										<div className="danger-zone-section-heading">
											<h2 className="danger-zone-section-title">Danger Zone</h2>
										</div>

										<div className="danger-zone-content">
											<h3 className="danger-zone-title">Slet lager</h3>
											<p>Når du sletter den, vil du ikke kunne få den tilbage. Du vil være nødt til at oprette den igen forfra</p>
											<button className="button button--warning" onClick={handleDelete}>SLET</button>
										</div>
									</div>
								)
							}


							<div className="form-padding"></div>

						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(InventoryEdit, {
  onRedirecting: () => <Loading />,
});
