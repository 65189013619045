import React from "react";
//import { Link, NavLink } from "react-router-dom";

export const BrandBar = () => {

	return (
		<div className="brand-bar">
			<div className="brand-bar-logo">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.1 48.7"><path d="M40.1 0v10.7h-3.3c-.8 0-1.2-.2-1.3-.7s-.4-1.9-.7-4.5H23.4v38.2s.3 0 .8.1c1.8.3 2.9.5 3.4.7.5.1.7.5.7 1.2v2.9H11.8v-2.9c0-.7.4-1.2 1.2-1.4s2-.4 3.7-.7V5.5H5.2c-.2 2.6-.4 4.1-.6 4.6-.1.5-.5.7-1.4.7H0V0h40.1z" fill="#020302"/></svg>
			</div>
		</div>		
	); 
};

export default BrandBar;
