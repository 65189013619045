import React from "react";

import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

import { withAuthenticationRequired } from "@auth0/auth0-react";



export const Inventory = () => {
	
	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Inventory"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">
					<div className="page-content-inner">
						<h1>Lagerbeholdning</h1>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Inventory, {
  onRedirecting: () => <Loading />,
});
