import React, { useState, useEffect, Fragment } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import { Formik } from 'formik';
import Select from 'react-select';
import FocusError from "../components/FocusError";
import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

import Modal from 'react-modal';
Modal.setAppElement('#root');

// Custom styles for react-modal
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding 			  : '0',
    borderRadius		  : '8px',
    boxShadow			  :    '0 0 10px rgba(0,0,0,.1)',
  }
};

// API Url
const api_url = process.env.REACT_APP_GROED_API_URL;

// Default form state
const defaultFormState = {
	date: new Date(),
	type: '',
	inventory: {},
	status: 'draft',
	items: [],
	
}

export const Count = (props) => {
	
	// Variables from History (react router)
	const history = useHistory();

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Form state
	const [formData, setFormData] = useState(defaultFormState);

	// Form state
	const [formChange, setFormChange] = useState(false);

	// Form state
	const [formSaving, setFormSaving] = useState(false);

	// Form state
	const [formSaved, setFormSaved] = useState(false);

	// STATE: search
	const [searchState, setSearchState] = useState({
		searchTerm: '',
	});

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// STATE: search
	const [hiddenItems, setHiddenItems] = useState([]);

	// STATE: Invetory data
	const [currentCountData, setCurrentCountData] = useState({defaultFormState});

	// STATE: Accounts
	const [accountData, setAccountData] = useState([]);

	// STATE: ingredients
	const [ingredientData, setIngredientData] = useState([]);

	// STATE: Prep
	const [prepData, setPrepData] = useState([]);

	// State: Modal (react-modal)
	const [modalIsOpen,setIsOpen] = React.useState(false);
  	const [modalData,setModalData] = React.useState({
  		title: 'Title',
  		message: 'message...',
  	});

	/**
  	 * Get data from DB
  	 */
	useEffect(() => {
		
		(async () => {

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get inventories
				const countResponse = await axios.get(`${api_url}/count`, { 
					headers: { 'Authorization': `Bearer ${access_token}`},
					params: {
						_id: props.match.params.id,
					}
				});
				const countData = countResponse.data.counts.sort((a, b) => (a.name > b.name) ? 1 : -1)
				console.log(countData);

				const currentCount = countData.find(countData => countData._id === props.match.params.id);
				setCurrentCountData(currentCount);
				
				setFormData({...defaultFormState, ...currentCount});

				// Get accounts
				const accountsResponse = await axios.get(`${api_url}/account`, {headers: { 'Authorization': `Bearer ${access_token}`}});
				setAccountData(accountsResponse.data.accounts);

				// Get ingredients
				if (currentCount) {
					const ingredientsResponse = await axios.get(`${api_url}/ingredient`, {
						headers: { 'Authorization': `Bearer ${access_token}`},
						params: {
							inventories: currentCount.inventory._id,
						}
					});
					setIngredientData(ingredientsResponse.data.ingredients);
				}

				// Get prep
				if (currentCount) {
					const prepResponse = await axios.get(`${api_url}/prep`, {
						headers: { 'Authorization': `Bearer ${access_token}`},
						params: {
							inventories: currentCount.inventory._id,
						}
					});	
					setPrepData(prepResponse.data.prep);
				}

				setIsLoading(false);
				
			} catch (e) {
        		console.error(e);
      		}

		})();		

	}, [getAccessTokenSilently]);

	/**
  	 * Get data from DB
  	 */
	useEffect(() => {
		
		let items = [];


		const ingredients = ingredientData.map(ingredient => {
			ingredient.onModel = 'Ingredient';
			return ingredient
		});

		const prep = prepData.map(prep => {
			prep.onModel = 'Prep';
			return prep
		});

		const library_items = [...ingredients, ...prep];

		if (currentCountData.status !== 'completed') {

			items = library_items.map(library_item => {

				// Check if this item is alreaddy saved in DB of current count
				const current_item = currentCountData.items.find(item => item.item._id === library_item._id);

				// Default inventory unit setup
				let inventory_count_unit = {
					unit: library_item.inventory_unit_of_measure,
					amount: '',
					inventory_units_per_unit: 1, // will always be 1 on the inventory_count_unit, since this is the base count.
					//inventory_unit_amount: 0,
				}

				// Override with data from db, if exists
				if (current_item && current_item.inventory_count_unit) {
					//inventory_count_unit = current_item.inventory_count_unit;
					inventory_count_unit.amount = current_item.inventory_count_unit.amount;
					if (inventory_count_unit.amount === null) {
						inventory_count_unit.amount = '';
					}
				}


				// Default pack unit
				let pack_count_unit = {
					unit: library_item.pack_unit_of_measure,
					amount: '',
					inventory_units_per_unit: library_item.inventory_units_per_pack,
					//inventory_unit_amount: 0,
				}
				// Override with data from db, if exists
				if (current_item && current_item.pack_count_unit) {
					//pack_count_unit = current_item.pack_count_unit;
					pack_count_unit.amount = current_item.pack_count_unit.amount;
					
					if (pack_count_unit.amount === null) {
						pack_count_unit.amount = '';
					}
				}

				// Default custom units
				let custom_units = library_item.custom_units;
				custom_units = custom_units.map(custom_unit => {
					return {
						...custom_unit,
						unit: {
							value: custom_unit.value,
							label: custom_unit.label,
						},
						amount: '',
					}
				})

				// Override with data from db, if exists
				if (current_item && current_item.custom_count_units) {

					custom_units = current_item.custom_count_units;

					custom_units = custom_units.map(custom_unit => {
						if (custom_unit.amount === null) {
							custom_unit.amount = '';
						}
						return custom_unit;
					});

				}

				const item_obj = {
					item: {
						_id: library_item._id,
						name: library_item.name,
						description: library_item.brand,
						sku: library_item.sku,
						account: library_item.account_id,
						inventory_unit_of_measure: library_item.inventory_unit_of_measure,
						pack_unit_of_measure: library_item.pack_unit_of_measure,
						inventory_units_per_pack: library_item.inventory_units_per_pack,
						price_per_pack: library_item.price_per_pack || 0,
						onModel: library_item.onModel,
					},
					inventory_count_unit: inventory_count_unit,
					pack_count_unit: pack_count_unit,
					custom_count_units: custom_units
				}
				
				return item_obj;
			});

		} else {
			items = currentCountData.items;
		}

		items.sort((a, b) => a.item.name > b.item.name ? 1 : -1);

		setFormData({...formData, items});

	}, [ingredientData, prepData]);


	/**
  	 * Change filtered list data, everytime search term or ingredient data changes
  	 */
	useEffect(() => {
	
		let hidden = [];
		
		for (const item of [...formData.items]) {
			if (item.item.name.search(new RegExp(searchState.searchTerm, "i")) === -1) {
				hidden.push(item.item._id);
			} 
		}
		setHiddenItems(hidden);
	
	}, [searchState]);	

	const handleFormChange = () => {
		console.log('change made');
		setFormChange(true);
	}

	/**
	 * Show saved bar upon succesful save
	 */
	const formJustSaved = () => {
		setFormSaving(false);
		setFormSaved(true);
		setTimeout(() => {
			//alert(JSON.stringify(values, null, 2));
			setFormSaved(false);
		}, 1000);
	}

  	/**
  	 * Save inventory
  	 */
	const saveCount = async (data) => {
		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {headers: { 'Authorization': `Bearer ${access_token}`}}
			const axiosPromise = axios.post(`${api_url}/count`, data, config);	
			const response = axiosPromise;
			
			return response;

		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * Handle saves. 
	 * Note: This runs both on save AND on completion
	 */
	const handleSave = (data, setSubmitting, completed = false) => {

		// Copy values
		let submitted_values = {...data, _id: currentCountData._id};
	
		// Set indventory and type
		submitted_values.inventory = currentCountData.inventory._id;
		submitted_values.type = currentCountData.type;

		// Format item data correctly
		submitted_values.items = data.items.map(item => {

			const obj = {
				...item,
				amount: calculateItemTotalAmount(item),
				value: calculateItemTotalValue(item),
			}

			return obj;

		});	

		// If we're "completing" the count on save.
		if (completed) {
			submitted_values.status = 'completed';
			submitted_values.date_completed = new Date();
		} else {
			submitted_values.status = 'draft';
		}
		
		// Set saving state
		setFormSaving(true);
		
		// Send data
		sendData(submitted_values, completed);

		// Formik: setSubmitting
		setTimeout(() => {
			setSubmitting(false);
		}, 2000);
	}	


	/**
	 * Send data to API
	 */
	const sendData = async (data, completed = false) => {

		// Send form data
		const submitResponse = await saveCount(data);

		// If everything goes well, Redirect to new item URL
		if (submitResponse && submitResponse.status === 200) {
			setFormChange(false);
			formJustSaved();

			const id = submitResponse.data.count._id;
			if (completed) {
				history.push(`/inventory/counts`);
			} else {
				history.push(`/inventory/counts/${id}`);	
			}
			

		} else {
			// Error message?
		}
	}

	/**
	 * Handle searchbar value
	 */
  	const handleSearchChange = (event) => {
  		const target = event.target;
  		const value = target.type === 'checkbox' ? target.checked : target.value;
  		//const name = target.name;

  		// Set state
  		setSearchState({
  			searchTerm: value
  		});
  	}

  	/**
  	 * Calculate total amount, combining the different count units
  	 */
  	const calculateItemTotalAmount = (item) => {

  		let amount = 0;

  		if (item.inventory_count_unit) {
			amount += item.inventory_count_unit.amount * item.inventory_count_unit.inventory_units_per_unit;
  		}

  		if (item.pack_count_unit) {
			amount += item.pack_count_unit.amount * item.pack_count_unit.inventory_units_per_unit;
  		}

  		if (item.custom_count_units) {
  			for (const custom_count_unit of item.custom_count_units) {
  				amount += custom_count_unit.amount * custom_count_unit.inventory_units_per_unit;
  			}
  		}

  		return amount;
  	}

  	/**
  	 * Calculate total value per item
  	 */
  	const calculateItemTotalValue = (item) => {

  		const amount = calculateItemTotalAmount(item);
  		
  		let price_per_inventory_unit = 0;
  		if (item.item && item.item.price_per_pack) {
  			price_per_inventory_unit = item.item.price_per_pack / item.item.inventory_units_per_pack;
  		}

  		const value = amount * price_per_inventory_unit;

  		return parseFloat(value.toFixed(2));
  	}

  	/** 
  	 * Calculate count total
  	 */
  	const calculateTotalCountValue = (items) => {

  		let value = 0;

  		for (const item of items) {
  			value += calculateItemTotalValue(item);
  		}

  		return parseFloat(value.toFixed(2));
  	}

  	/**
  	 * Custom blur event, that checks PAR before return Formiks handleBlur
  	 */
  	const customBlur = (e, handleBlur, par_level, inventory_units_per_pack, total_amount, name) => {
  		if (par_level > 0) {

  			const total_par = par_level*inventory_units_per_pack;

  			if (total_amount > total_par) {
  				openModal(par_level, inventory_units_per_pack, total_amount, name)
  			}

  		}

  		return handleBlur;
  	}

	/**
	 * Open Modal
	 */
  	const openModal = (par_level, inventory_units_per_pack, total_amount, name) => {
			
  		setModalData({
  			title: `Stort lagerniveau for "${name}" !`,
  			message: `Den samlede lagermængde (${total_amount}) overstiger den planlagte max (${par_level*inventory_units_per_pack}). Dobbelttjek venligst at du har tastet rigtigt. Hvis du er sikker på at din indtastning er korrekt, fortsætter du bare :)`,	
  		})

		setIsOpen(true);
  	}

  	/**
	 * Do something after Modal open
	 */
  	const afterOpenModal = () => { }
	
	/**
	 * Close modal
	 */ 
	const closeModal = () => {
		setIsOpen(false);
	}


	/**
	 * If data is still loading
	 */
  	if (isLoading) {
  		return (
  			<div className="app-container">
  				<div className="app-sidebar">
  					<BrandBar/>
  					<Navigation page_title="Count"/>
  				</div>
  				
  				<div className="app-content">
  					<div className="page-content">
  						<Loading />
					</div>
				</div>
			</div>
  		);	
  	}

  	/**
	 * If data has loaded
	 */
	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Count"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">
			        
			        <Modal
			          isOpen={modalIsOpen}
			          onAfterOpen={afterOpenModal}
			          onRequestClose={closeModal}
			          style={customStyles}
			          contentLabel="Example Modal"
			         >
						<div className="modal-content">
							<h2 className="modal-title">{modalData.title}</h2>
							<p className="modal-message">{modalData.message}</p>
							<button className="button modal-button" onClick={closeModal}>OK</button>
						</div>
			        </Modal>

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							
							{
								props.match.params.id && currentCountData.inventory && currentCountData.inventory.name ? (
									<div className="breadcrumbs">/lager/optællinger/{currentCountData.inventory.name} ({moment(currentCountData.date).format('DD/MM/YYYY HH:mm')})</div>
								) : (
									<div className="breadcrumbs">/lager/optællinger/ny optælling</div>
								)
							}

							<div className="page-pretitle">{moment(currentCountData.date).format('DD/MM/YYYY HH:mm')}</div>
							{
								currentCountData.inventory && currentCountData.inventory.name && (
									<h1 className="page-title">Optælling for {currentCountData.inventory.name}, { currentCountData.type === 'after-closing' ? 'Efter luk' : 'Før åben'}</h1>
								)	
							}
							

							<br/><br/>

							<div className="filter-box">

								<div className="filter-box-field filter-box-field--search">
									
									<div className="item-list-searchfield-container">
										<div className="item-list-searchfield-icon">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M8.8 8.1c.7-.9 1.1-2 1.1-3.1 0-2.7-2.2-5-5-5S0 2.2 0 5c0 2.7 2.2 5 5 5 1.1 0 2.2-.4 3.1-1.1l3.2 3.2.7-.7-3.2-3.3zM5 8.9c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4c-.1 2.2-1.9 4-4 4z" fill="#020302"/></svg>
										</div>
										<input type="text" className="item-list-search-input" name="searchValue" value={searchState.searchTerm} onChange={handleSearchChange} placeholder="Søg"/>
									</div>

								</div>

								<div className="filter-box-field filter-box-field--filler"></div>

								<div className="filter-box-field filter-box-field--account">
									<Select
										styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
										placeholder="Vælg konto"
										isClearable={true}
										onChange={option => {
											
											let hidden = [];
											
											for (const item of [...formData.items]) {
												if (option && item.item.account.name !== option.label) {
													hidden.push(item.item._id);
												} 
											}
											setHiddenItems(hidden);

										}}
										options={
									 		accountData.map(account => {
									 			return {
									 				value: account._id, label: account.name,
									 			}
									 		})
									 	}
									/>
								</div>

							</div>


							<Formik
								initialValues={{
									...formData
								}}
								enableReinitialize={true}

								validate={ values => {
									const errors = {};

									// Required fields
									let required_fields = [];
								
									// Handle normal required fields
									for(const required_field of required_fields) {
										if (values[required_field] === '') {
											errors[required_field] = 'Dette felt er påkrævet';
										}	
									}

									// Handle required fields for each item unit count.
									for (const [item_index, item] of values.items.entries()) {

										// Handle pack unit validation
										if (item.pack_count_unit.amount === '') {
											errors[`item-${item_index}-pack_count_unit-amount`] = 'Dette felt er påkrævet';
										}

										for (const [count_unit_index, count_unit] of item.custom_count_units.entries()) {
											if (count_unit.amount === '') {
												errors[`values.items[${item_index}].custom_count_units[${count_unit_index}].amount`] = 'Dette felt er påkrævet';
											}		
										}

									}

									return errors;
								}}
							   
								onSubmit={(values, { setSubmitting }) => {
									
									// Ask if user is sure they want to delete. No going back! 
									if (window.confirm("Er du helt sikker på at du vil afslutte? Du kan ikke redigere optællingen bagefter, så afslut først når du er helt sikker.")) {

										if (currentCountData.status !== 'completed') {

											// Copy values
											let submitted_values = {...values};

											// Send data
											console.log('submit', values);
											const completed = true;
											handleSave(values, setSubmitting, completed);

											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												setSubmitting(false);
											}, 2000);

										}
									}

							   }}

							>

							{({
								 values,
								 errors,
								 touched,
								 handleChange,
								 handleBlur,
								 handleSubmit,
								 isSubmitting,
								 setSubmitting,
								 setFieldValue,
								 resetForm,
								 isValid,
								 dirty,
								 /* and other goodies */
							}) => (
								<form onSubmit={handleSubmit} onChange={() => handleFormChange()}>
									
									<div className="count-table-container">
										<table className="count-table">
											<thead>
												<tr>
													<th></th>
													<th>Produkt</th>
													<th>Enhed 1 (pakke)</th>
													<th>Enhed 2</th>
													<th>Enhed 3</th>
													<th>Samlet</th>
													<th>Værdi</th>
													
												</tr>
											</thead>
											<tbody>
												{
													values.items.map((item, i) => {

														// default Par level. 0 = no par level
														let par_level = 0

														if (item.item.onModel === 'Ingredient') {
															const original_ingredient = ingredientData.find(ingredient => ingredient._id === item.item._id);
															if (original_ingredient) {
																par_level = original_ingredient.par_level;
															}
														}

														if (item.item.onModel === 'Prep') {
															const original_prep = prepData.find(prep => prep._id === item.item._id);
															if (original_prep) {
																par_level = original_prep.par_level;
															}
														}

														// Ready check array
														let readyCheck = []

														// Check if pack unit is set
														if (values.items[i].pack_count_unit.amount === '' || errors[`item-${i}-pack_count_unit-amount`]) {
															readyCheck.push('pack_count_unit not ready');
														}

														// Check for custom unit values
														for (const [count_unit_index, count_unit] of item.custom_count_units.entries()) {
															if (values.items[i].custom_count_units[count_unit_index].amount === '' || errors[`values.items[${i}].custom_count_units[${count_unit_index}].amount`]) {
																readyCheck.push(`custom_count_units[${count_unit_index}] not ready`);
															}															
														}

														// Make ready check boolean
														const isReady = readyCheck.length === 0;
														const readyClass = isReady ? 'count-table-row-ready' : '';
														const disabled = currentCountData.status === 'completed';
														
														const hideItemClass = hiddenItems.includes(item.item._id) ? 'count-table-row--hide' : '';

														const total_amount = calculateItemTotalAmount(item);

														let isOverPar = false;
														if (par_level > 0) {
															const total_par = par_level*item.item.inventory_units_per_pack;
															if (total_amount > total_par) {
																isOverPar = true;
															}
														}

														return (
															<tr key={i} className={`${readyClass} ${hideItemClass}`}>
																{
																	isReady && isOverPar && (
																		<td className="count-table-row-checkmark" title="OK, men høj lagermængde!">
																			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="checkmark-warning"><path d="M255.5 17.7c-131.8 0-238.7 106.9-238.7 238.7s106.9 238.7 238.7 238.7 238.7-106.9 238.7-238.7S387.3 17.7 255.5 17.7zm-19.9 336.6l-39.7-39.7-54.4-54.4 39.7-39.7 54.4 54.4 100.3-100.3 39.7 39.7-140 140z" fill="#020302"/></svg>
																		</td>
																	) 
																}
																{
																	isReady && !isOverPar && (
																		<td className="count-table-row-checkmark" title="Nice!">
																			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M255.5 17.7c-131.8 0-238.7 106.9-238.7 238.7s106.9 238.7 238.7 238.7 238.7-106.9 238.7-238.7S387.3 17.7 255.5 17.7zm-19.9 336.6l-39.7-39.7-54.4-54.4 39.7-39.7 54.4 54.4 100.3-100.3 39.7 39.7-140 140z" fill="#020302"/></svg>
																		</td>
																	)
																}
																{
																	!isReady && (
																		<td className="count-table-row-checkmark" title="Nice!"></td>
																	)
																}
																<td>
																	<div className="count-table-product-title-container" title={item.item.name}>
																		<div className="count-table-product-title">{item.item.name}</div>
																		<div className="count-table-product-subtitle">{item.item.description}</div>
																	</div>
																</td>
															
																<td>
										    	        			<div className="count-table-input-container">
										    	        				<div className="field-with-suffix" data-suffix="g">
										    	        					<input type="number" step="0.01" min="0" className="input-field-with-suffix" placeholder="Mængde" value={values.items[i].pack_count_unit.amount} name={`items[${i}].pack_count_unit.amount`} onChange={handleChange} onBlur={(e, handleblur) => customBlur(e, handleblur, par_level, item.item.inventory_units_per_pack, total_amount, item.item.name)} disabled={disabled} />
										    	        					<div className="field-suffix">
										    	        						{item.pack_count_unit.unit.label} ({item.pack_count_unit.inventory_units_per_unit} {item.item.inventory_unit_of_measure.label})
										    	        					</div>
										    	        				</div>
										    	        			</div>
																</td>

																{
																	item.custom_count_units[0] ? (
																		<td>
												    	        			<div className="count-table-input-container">
												    	        				<div className="field-with-suffix" data-suffix="g">
												    	        					<input type="number" step="0.01" min="0" className="input-field-with-suffix" placeholder="Mængde" value={values.items[i].custom_count_units[0].amount} name={`items[${i}].custom_count_units[0].amount`} onChange={handleChange} onBlur={(e, handleblur) => customBlur(e, handleblur, par_level, item.item.inventory_units_per_pack, total_amount, item.item.name)} disabled={disabled}/>
												    	        					<div className="field-suffix">
												    	        						{item.custom_count_units[0].unit.label} ({item.custom_count_units[0].inventory_units_per_unit} {item.item.inventory_unit_of_measure.label})
												    	        					</div>
												    	        				</div>
												    	        			</div>
																		</td>
																	) : (
																		<td></td>
																	)
																}

																{
																	item.custom_count_units[1] ? (
																		<td>
												    	        			<div className="count-table-input-container">
												    	        				<div className="field-with-suffix" data-suffix="g">
												    	        					<input type="number" step="0.01" min="0" className="input-field-with-suffix" placeholder="Mængde" value={values.items[i].custom_count_units[1].amount} name={`items[${i}].custom_count_units[1].amount`} onChange={handleChange} onBlur={(e, handleblur) => customBlur(e, handleblur, par_level, item.item.inventory_units_per_pack, total_amount, item.item.name)} disabled={disabled}/>
												    	        					<div className="field-suffix">
												    	        						{item.custom_count_units[1].unit.label} ({item.custom_count_units[1].inventory_units_per_unit} {item.item.inventory_unit_of_measure.label})
												    	        					</div>
												    	        				</div>
												    	        			</div>
																		</td>
																	) : (
																		<td></td>
																	)
																}
																

																<td className="total-amount-td" title={`Total par amount: ${par_level*item.item.inventory_units_per_pack}`}>{total_amount.toLocaleString("da-DK")} {item.item.inventory_unit_of_measure.label}</td>
																<td className="total-value-td">{calculateItemTotalValue(item).toLocaleString("da-DK")} kr</td>
																
															</tr>
														)

													})
												}
											</tbody>
											<tfoot>
											    <tr>
											      <td colSpan="5">Total</td>
											      <td colSpan="2">{calculateTotalCountValue(values.items).toLocaleString("da-DK")} kr.</td>
											    </tr>
											</tfoot>

										</table>
									</div>
						
									{
										formChange === true && currentCountData.status !== 'completed' && !formSaving ? (
											<div className="fixed-action-bar fixed-action-bar--wide">
												<button type="button" className="standard-submit button button--medium" onClick={() => handleSave(values, setSubmitting)}>Gem</button>
												<button type="submit" className="standard-submit button button--medium" disabled={!(isValid)}>Afslut optælling</button>
											</div>
										) : (
											<div className="fixed-action-bar fixed-action-bar--wide fixed-action-bar--hidden">
												{/* <button type="button" className="standard-submit button button--medium" onClick={() => handleSave(values, setSubmitting)}>Gem</button> */}
												<button type="button" className="standard-submit button button--medium" onClick={() => handleSave(values, setSubmitting, false)}>Åben optælling</button>
											</div>
										)	
									}

									{
										formSaving === true ? (
											<div className="fixed-action-bar fixed-action-bar--wide fixed-action-bar--saving">Gemmer...</div>
										) : (
											<div className="fixed-action-bar fixed-action-bar--wide fixed-action-bar--saving fixed-action-bar--hidden">Gemmer...</div>
										)	
									}

									{
										formSaved === true ? (
											<div className="fixed-action-bar fixed-action-bar--wide fixed-action-bar--success">Gemt!</div>
										) : (
											<div className="fixed-action-bar fixed-action-bar--wide fixed-action-bar--success fixed-action-bar--hidden">Gemt!</div>
										)	
									}

									{
										isValid && currentCountData.status !== 'completed' ? (
											<div className="form-button-group">
												<button type="button" className="standard-submit button button--medium" onClick={() => handleSave(values, setSubmitting)}>Gem</button>
												<button type="submit" className="standard-submit button button--medium" disabled={!(isValid)}>Afslut optælling</button>	
											</div>
										) : (
											<div className="form-button-group">
												{/* <button type="button" className="standard-submit button button--medium" onClick={() => handleSave(values, setSubmitting)}>Gem</button> */}
												<button type="button" className="standard-submit button button--medium" onClick={() => handleSave(values, setSubmitting, false)}>Åben optælling</button>
											</div>
										)
										
									}
									

									<FocusError/>				
								</form>
							)}


							</Formik>

							<div className="form-padding"></div>

						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Count, {
  onRedirecting: () => <Loading />,
});
