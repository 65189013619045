import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import Select from 'react-select'
import FocusError from './FocusError';
import RepeatableItemsTable from '../components/RepeatableItemsTableRecipes';

// Default state
const defaultFormState = {

	_id: undefined,
	
	// Base data
	supplier: '',
	account: '',
	name: '',
	description: '',
	sku: '',
	brand: '',

	items: [],
	
	// minimum_on_hand: '',
	// par_level: '',
	price: '',
	waste_percentage: '',

	count_units: '',
	custom_count_units: '',

	departments: '',
}

const RecipeForm = (props) => {

	// Variables from History (react router)
	const history = useHistory();

	// Form state
	const [formData, setFormData] = useState(defaultFormState);

	// Form state
	const [formChange, setFormChange] = useState(false);

	// Form state
	const [formSaved, setFormSaved] = useState(false);


	/**
	 * Check for change in param ID and prep
	 */ 
	useEffect(() => {
		
		// Figure out if this an existing item, or a new one. 
		if (props.match.params.id) {

			// Find data in props.prep
			const currentRecipe = props.recipes.find(recipe => recipe._id === props.match.params.id);

			// Populate formData state with data
			if (currentRecipe) {

				if (currentRecipe.account_id && currentRecipe.account_id._id) {
					currentRecipe.account = {
						value: currentRecipe.account_id._id,
						label: currentRecipe.account_id.name,
					}
				}
				
				// Remove all null values from DB, since we don't want to set field values to null. 
				// There may be a better way to handle this, But probably a good idea to keep this just to ensure, that we don't set Formik values to null or undefined. Better to use empty string.
				for (var propName in currentRecipe) { 
					if (currentRecipe[propName] === null || currentRecipe[propName] === undefined) {
					  delete currentRecipe[propName];
					}
				}

				setFormData({...defaultFormState, ...currentRecipe});
			} else {
				// @TODO 404 Doesnt exist!?
			}

		} else {
			// New document
			setFormData({...defaultFormState});
		}

	}, [props.recipes, props.match.params.id]);



	/**
	 * Handle form submission
	 */
	const sendData = async (data) => {

		console.log('sendData');

		// Send form data
		const submitResponse = await props.saveRecipe(data);


		// If everything goes well, Redirect to new item URL
		if (submitResponse && submitResponse.status === 200) {
			setFormChange(false);
			formJustSaved();

			// Reload data
			const dataResponse = await props.getRecipes();
			const sorted = dataResponse.data.recipes.sort((a, b) => a.name > b.name ? 1 : -1);
			props.setRecipeData(sorted);

			const id = submitResponse.data.recipe._id;
			history.push(`/menu/recipes/${id}`);
		} else {
			// Error message?
		}

	}

	/**
	 * Handle Delete
	 */
	const handleDelete = async (event) => {
		event.preventDefault();
		
		// Ask if user is sure they want to delete. No going back! 
		if (window.confirm("Er du helt sikker på at du vil slette?")) {
			// Send form data
			const deleteResponse = await props.deleteRecipe({_id: formData._id});

			// Reload data
			const dataResponse = await props.getRecipes();
			const sorted = dataResponse.data.recipes.sort((a, b) => a.name > b.name ? 1 : -1);
			props.setRecipeData(sorted);

			// If everything goes well, Redirect to prep front page
			if (deleteResponse.status === 200) {
				history.push("/menu/recipes");
			}	
		}

	}

	/**
	 * Indicate that form has changes
	 */
	const handleFormChange = () => {
		console.log('change made');
		setFormChange(true);
	}

	/**
	 * Revert changes made
	 */
	const cancelChanges = (resetForm) => {
		setFormChange(false);
		resetForm();
	}

	/**
	 * Indicate that the form was saved successfully
	 */
	const formJustSaved = () => {
		setFormSaved(true);
		setTimeout(() => {
			//alert(JSON.stringify(values, null, 2));
			setFormSaved(false);
		}, 1000);
	}

	/**
	 * Nutrition calculator
	 * This is a recursive function that calls it self. 
	 */
	const nutritionCalc = (item, field_name, level, output) => {

		const options = getOptions();
	    // Find the original dataset of current item
	    const current_item = options.find(current_item => current_item._id === item.item);

	 
	    // Ingredients
	    if (current_item && current_item.onModel === 'Ingredient') {

			// Initially we assume that amount is counted in grams
			let amount = item.amount;
			const grams_per_inventory_unit = current_item.grams_per_inventory_unit || 1;
	
			// If it is not counted in grams, we then multiply by the grams per inventory unit, in order to find the correct gram-count.
			if (current_item.inventory_unit_of_measure.value !== 'g') {
				amount = grams_per_inventory_unit * item.amount;
			}				    	


			// Calculate total value per portion.
			const total_field_value_per_portion = (current_item[field_name] / 100) * amount;
			output = total_field_value_per_portion;
			return output;

	    }


	    // otherwise keep calculating sub items.
	    else if (current_item && current_item.onModel === 'Prep') {

	    	// Since this is not an ingredient, we're going one level deeper.
	    	level += 1;

	    	//Recursive loop lock - calculateCostFromThisItem() is a recursive function that calls itself. If a prep item includes it self as an ingredient, that would result in an infinite loop. This sets a limit as to how many lavels deep we allow it to go.
	    	if (level > 4) {
	    		return -1;
	    	}

	    	if (current_item.items) {
	 
	    		// Go through each item and calculate cost seperately
	    		for (const sub_item of current_item.items) {	
	    			let i = {...sub_item}
	    			output += nutritionCalc(i, field_name, level, output);
	    		}
	    	}
	    	
	    	// calculate cost for this prep item based on amount used. 
	    	if (current_item.inventory_units_per_pack >= 0) {
	    		const total_field_value_per_portion = ( output / current_item.inventory_units_per_pack) * item.amount;
	    		return total_field_value_per_portion;
	    	} else {
	    		return -1;
	    	}
	    }

		return output;

	}

	/**
	 * Calculate recipe total weight
	 */
	const calculateTotalWeight = (items) => {

		let total_weight = 0;

		for(const item of items) {

			const options = getOptions();
		    // Find the original dataset of current item
		    const current_item = options.find(current_item => current_item._id === item.item);

		    // Ingredients
		    if (current_item) {

				
		    	const grams_per_inventory_unit = current_item.grams_per_inventory_unit || 1;

		    	// If it is not counted in grams, we then multiply by the grams per inventory unit, in order to find the correct gram-count.
		    	if (current_item.inventory_unit_of_measure.value !== 'g') {
		    		total_weight += grams_per_inventory_unit * item.amount;
		    	} else {
		    		total_weight += item.amount;
		    	}	
		    }
		}

		return total_weight;
	}


	/**
	 * Nutrition calculataor proxy
	 * The proxy is used, because the actual calculator is a recursive function that calls it self. 
	 */
	const calculateNutritionalInfoFromThisItem = (values, field_name) => {

		// Setup variable
		let total_per_portion = 0;

		const total_weight = calculateTotalWeight(values.items);

		// Go through each item and calculate nutrition for [field_name] per portion.
		for(const item of values.items) {
			total_per_portion += nutritionCalc(item, field_name, 1, 0);
		}

		// Convert to nutrition per 100 grams
		const total_per_100 = (total_per_portion / total_weight) * 100 || 0;
		
		//return total with 2 decimals;
		return parseFloat(total_per_100.toFixed(2));
	}


	/**
	 * Get options for ingredients and prep lists.
	 */
	const getOptions = () => {

		let options = [];

		// Add ingredients to options list
		for (const item of props.ingredients) {
			item.onModel = 'Ingredient';

			if (item._id !== props.match.params.id) {
				options.push(item);
			}
		} 

		// Add prep to options list
		for (const item of props.prep) {
			item.onModel = 'Prep';
		
			if (item._id !== props.match.params.id) {
				options.push(item);	
			}
			
		} 

		// Order by name
		options.sort((a, b) => (a.name > b.name) ? 1 : -1)

		return options;

	}

	return (
		<div className="page-content-inner">

			<div className="page-content-inner-centering">

				{
					props.match.params.id ? (
						<div className="breadcrumbs">/menu/opskrifter/{formData.name}</div>
					) : (
						<div className="breadcrumbs">/menu/opskrifter/ny ingrediens</div>
					)
				}
				
				<Formik
					initialValues={{ 
						_id: props.match.params.id || '',
						// name: formData.name,
						// supplier: formData.supplier,
						// account: formData.category,
						// sku: formData.sku,
						...formData
					}}
				   

					enableReinitialize={true}

					validate={ values => {

						const errors = {};

						// Required fields
						const required_fields = [
						'name',
						'account',
						'sku',
						'price',
						// 'pack_unit_of_measure',
						// 'inventory_unit_of_measure',
						// 'inventory_units_per_pack',
						// 'price_per_pack',
						// 'waste_percentage',

	
						];

						for(const required_field of required_fields) {
							if (values[required_field] === '') {
								errors[required_field] = 'Dette felt er påkrævet';
							}	
						}

						// Number validation
						const numbers_fields = [
							// 'co2_per_inventory_unit',
							// 'inventory_units_per_pack',
							// 'price_per_pack',
							// 'waste_percentage',
							// 'energy_kj',
							// 'energy_kcal',
							// 'fat',
							// 'saturated_fat',
							// 'carbohydrate',
							// 'sugar',
							// 'protein',
							// 'salt',
							// 'fiber',
						]

						for(const number_field of numbers_fields) {
							if ( isNaN(values[number_field])) {
								errors[number_field] = 'Dette felt skal være et tal';
							}	
						}

						// console.log(values.waste_percentage);
						// if (values.waste_percentage === '') {
						// 	errors.waste_percentage = 'Dette felt er påkrævet';
						//}	

						return errors;
					}}
				   
					onSubmit={(values, { setSubmitting }) => {
							

						let submitted_values = {...values};

						if (values.account.value) {
							submitted_values.account_id = values.account.value;	
						}

						submitted_values.items = [];
						for(const item of values.items) {
							if (item.item) {

								submitted_values.items.push({
									item: item.item,
									amount: item.amount,
									onModel: item.onModel,
								})
							}
						}


						sendData(submitted_values);

						setTimeout(() => {
							//alert(JSON.stringify(values, null, 2));
							setSubmitting(false);
						}, 2000);
				   }}
				>

				{({
					 values,
					 errors,
					 touched,
					 handleChange,
					 handleBlur,
					 handleSubmit,
					 isSubmitting,
					 setFieldValue,
					 resetForm,
					 /* and other goodies */
				}) => (
					<form onSubmit={handleSubmit} onChange={() => handleFormChange()}>
					
						<div className="title-field" data-field="name">
							<label className="title-field-label">Navn</label>
							<div className="title-field-input-container">
								<input type="text" className="title-field-input" placeholder="Indtast navn" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
							</div>
							{errors.name && touched.name && ( <div className="standard-field-input-error">{errors.name}</div> )}
						</div>

						<div className="standard-field standard-field--wide" data-field="description">
							<div className="standard-field-label-container">
								<label className="standard-field-label">Beskrivelse</label>
							</div>
							<div className="standard-field-input-container">
								<textarea name="description" id="" rows="3" value={values.description} onChange={handleChange} onBlur={handleBlur}></textarea>
								{errors.description && touched.description && ( <div className="standard-field-input-error">{errors.description}</div> )}
							</div>	    	        		
						</div>

						<div className="field-section standard-field-section" data-field="account">
						
							<div className="standard-field">
								<div className="standard-field-label-container">
									<label className="standard-field-label">Konto</label>
								</div>
								<div className="standard-field-input-container">
									<Select
										styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
										placeholder="Vælg konto"
										value={values.account}
										onChange={option => {
											setFieldValue("account", option);
											handleFormChange();
										}}
										onBlur={handleBlur}
										options={
											props.accounts.map(account => {
												return {
													value: account._id, label: account.name,
												}
											})
										}
									/>
									{errors.account && touched.account && ( <div className="standard-field-input-error">{errors.account}</div> )}
								</div>
							</div>
							
							<div className="standard-field" data-field="sku">
								<div className="standard-field-label-container">
									<label className="standard-field-label">SKU</label>
								</div>
								<div className="standard-field-input-container">
									<input type="text" className="standard-field-input" placeholder="Indtast navn" name="sku" value={values.sku} onChange={handleChange} onBlur={handleBlur}/>
									{errors.sku && touched.sku && ( <div className="standard-field-input-error">{errors.sku}</div> )}
								</div>	    	        		
							</div>

							<div className="standard-field" data-field="price">
								<div className="standard-field-label-container">
									<label className="standard-field-label">Pris</label>
								</div>
								<div className="standard-field-input-container">
									<input type="number" className="standard-field-input" placeholder="Indtast pris" name="price" value={values.price} onChange={handleChange} onBlur={handleBlur}/>
									{errors.price && touched.price && ( <div className="standard-field-input-error">{errors.price}</div> )}
								</div>	    	        		
							</div>

						</div>



						<div className="field-section standard-field-section">


							<div className="field-section-header">OPSKRIFT</div>
						
							<RepeatableItemsTable 
								handleChange={handleChange} 
								handleBlur={handleBlur} 
								handleFormChange={handleFormChange} 
								items={values.items}
								options={getOptions()}
								setFieldValue={setFieldValue}
								/>
							
						</div>
		
						<div className="field-section">

							<div className="field-section-header">Næringsindhold pr 100g</div>

							<div className="info-card">
								<h2 className="info-card-title">{values.name}</h2>
								
								<div className="info-card-description">
									<p>{values.description}</p>
								</div>
								
								<div className="info-card-nutrition-info">
									<div className="info-card-nutrition-info-header">
										<h3>Næringsindhold pr. 100g</h3>
									</div>	

									<div className="info-card-nutrition-info-content">
										<ul className="info-card-nutrition-info-list">
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">Energi</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseInt(calculateNutritionalInfoFromThisItem(values, 'energy_kj'))}kJ / {parseInt(calculateNutritionalInfoFromThisItem(values, 'energy_kcal'))}kcal</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">Fedt</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'fat'), 1)}g</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">- heraf mættede fedtsyrer</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'saturated_fat'), 1)}g</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">Kulhydrater</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'carbohydrate'), 1)}g</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">- heraf sukkerarter</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'sugar'), 1)}g</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">Protein</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'protein'), 1)}g</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">Salt</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'salt'), 1)}g</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">Kostfibre</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'fiber'), 1)}g</div>
											</li>
										</ul>
									</div>	
									<br/>
									<div className="">Nettovægt: {parseInt(calculateTotalWeight(values.items))}g</div>
									
								</div>
							</div>
						</div>


						{
							formChange === true ? (
								<div className="fixed-action-bar">
									<button type="button" className="button button--ghost button--medium" onClick={() => cancelChanges(resetForm)}>ANNULLER</button>
									<button type="submit" className="standard-submit button button--medium" disabled={isSubmitting}>GEM</button>
								</div>
							) : (
								<div className="fixed-action-bar fixed-action-bar--hidden">
									<button type="button" className="button button--ghost button button--medium" onClick={() => cancelChanges(resetForm)}>ANNULLER</button>
									<button type="submit" className="standard-submit button button--medium" disabled={isSubmitting}>GEM</button>
								</div>
							)	
						}

						{
							formSaved === true ? (
								<div className="fixed-action-bar fixed-action-bar--success">Gemt!</div>
							) : (
								<div className="fixed-action-bar fixed-action-bar--success fixed-action-bar--hidden">Gemt!</div>
							)	
						}
						
						
					<FocusError />
					 </form>
				   )}


				</Formik>

			

				{
					props.match.params.id && (
						<div className="danger-zone">

							<div className="danger-zone-section-heading">
								<h2 className="danger-zone-section-title">Danger Zone</h2>
							</div>

							<div className="danger-zone-content">
								<h3 className="danger-zone-title">Slet denne opskrift?</h3>
								<p>Når du sletter den, vil du ikke kunne få den tilbage. Du vil være nødt til at oprette den igen forfra</p>
								<button className="button button--warning" onClick={handleDelete}>SLET</button>
							</div>
						</div>
					)
				}


				<div className="form-padding"></div>

				

			</div>
		</div>

	); 
};

export default RecipeForm;
