import React, { useState, useEffect, Fragment } from "react";
import axios from 'axios';
import { useHistory, Link, } from "react-router-dom";
import { Formik, Field } from 'formik';
import Select from 'react-select';
import FocusError from './FocusError';
import RepeatableItemsTable from '../components/RepeatableItemsTableRecipes';
import RepeatableUnits from '../components/RepeatableUnits';

const api_url = process.env.REACT_APP_GROED_API_URL;

// Default state
const defaultFormState = {

	_id: undefined,
	
	// Base data
	supplier: '',
	account: '',
	name: '',
	sku: '',
	brand: '',

	items: [],

	// Inventory
	pack_unit_of_measure: '',
	inventory_unit_of_measure: '',
	inventory_units_per_pack: '',
	packs_per_case: '',
	
	// minimum_on_hand: '',
	par_level: '',
	custom_units: [],
	price_per_pack: '',
	waste_percentage: '',

	count_units: '',
	custom_count_units: '',

	inventories: [],

	departments: '',

}

const PrepForm = (props) => {

	// Variables from History (react router)
	const history = useHistory();

	// Form state
	const [formData, setFormData] = useState(defaultFormState);

	// Form state
	const [formChange, setFormChange] = useState(false);

	// Form state
	const [formSaved, setFormSaved] = useState(false);


	// STATE: Related items (preps and recipes that contains this ingredient)
	const [relatedPrepData, setRelatedPrepData] = useState([]);
	const [relatedRecipesData, setRelatedRecipesData] = useState([]);

	/**
	 * Check for change in param ID and prep
	 */ 
	useEffect(() => {
		
		// Figure out if this an existing item, or a new one. 
		if (props.match.params.id) {

			// Find data in props.prep
			const currentPrep = props.prep.find(prep => prep._id === props.match.params.id);
			// Populate formData state with data
			if (currentPrep) {

				if (currentPrep.account_id && currentPrep.account_id._id) {
					currentPrep.account = {
						value: currentPrep.account_id._id,
						label: currentPrep.account_id.name,
					}
				}
				
				// Remove all null values from DB, since we don't want to set field values to null. 
				// There may be a better way to handle this, But probably a good idea to keep this just to ensure, that we don't set Formik values to null or undefined. Better to use empty string.
				for (var propName in currentPrep) { 
					if (currentPrep[propName] === null || currentPrep[propName] === undefined) {
					  delete currentPrep[propName];
					}
				}

				setFormData({...defaultFormState, ...currentPrep});
			} else {
				// @TODO 404 Doesnt exist!?
			}

		} else {
			// New document
			setFormData({...defaultFormState});
		}

	}, [props.prep, props.match.params.id]);

	// Find related items
	useEffect(() => {
		
		(async () => {
			try {

				// Get access token
				const access_token = await props.getAccessTokenSilently();


				if (props.match.params.id) {
					
					// Get related items
					const relatedPrepResponse = await axios.get(`${api_url}/prep`, {
						headers: { 'Authorization': `Bearer ${access_token}`},
						params: {
							contains: [props.match.params.id],
						}
					});
					setRelatedPrepData(relatedPrepResponse.data.prep);

					const relatedRecipesResponse = await axios.get(`${api_url}/recipe`, {
						headers: { 'Authorization': `Bearer ${access_token}`},
						params: {
							contains: [props.match.params.id],
						}
					});
					setRelatedRecipesData(relatedRecipesResponse.data.recipes);

				}

			} catch (e) {
        		console.error(e);
      		}

		})();
		
	}, [props.getAccessTokenSilently, props.match.params.id]);

	/**
	 * Handle form submission
	 */
	const sendData = async (data) => {

		console.log('sendData');

		// Send form data
		const submitResponse = await props.savePrep(data);


		// If everything goes well, Redirect to new item URL
		if (submitResponse && submitResponse.status === 200) {
			setFormChange(false);
			formJustSaved();

			// Reload data
			const dataResponse = await props.getPrep();
			const sorted = dataResponse.data.prep.sort((a, b) => a.name > b.name ? 1 : -1);
			props.setPrepData(sorted);

			const id = submitResponse.data.prep._id;
			history.push(`/menu/prep/${id}`);
		} else {
			// Error message?
		}

	}

	/**
	 * Handle Delete
	 */
	const handleDelete = async (event) => {
		event.preventDefault();
		
		// Ask if user is sure they want to delete. No going back! 
		if (window.confirm("Er du helt sikker på at du vil slette?")) {
			// Send form data
			const deleteResponse = await props.deletePrep({_id: formData._id});

			// Reload data
			const dataResponse = await props.getPrep();
			const sorted = dataResponse.data.prep.sort((a, b) => a.name > b.name ? 1 : -1);
			props.setPrepData(sorted);

			// If everything goes well, Redirect to prep front page
			if (deleteResponse.status === 200) {
				history.push("/menu/prep");
			}	
		}

	}

	/**
	 * Indicate that form has changes
	 */
	const handleFormChange = () => {
		console.log('change made');
		setFormChange(true);
	}

	/**
	 * Revert changes made
	 */
	const cancelChanges = (resetForm) => {
		setFormChange(false);
		resetForm();
	}

	/**
	 * Indicate that the form was saved successfully
	 */
	const formJustSaved = () => {
		setFormSaved(true);
		setTimeout(() => {
			//alert(JSON.stringify(values, null, 2));
			setFormSaved(false);
		}, 1000);
	}



	/**
	 * Nutrition calculator
	 * This is a recursive function that calls it self. 
	 */
	const nutritionCalc = (item, field_name, level, output) => {

		const options = getOptions();
	    // Find the original dataset of current item
	    const current_item = options.find(current_item => current_item._id === item.item);

	 
	    // Ingredients
	    if (current_item && current_item.onModel === 'Ingredient') {

			// Initially we assume that amount is counted in grams
			let amount = item.amount;
			const grams_per_inventory_unit = current_item.grams_per_inventory_unit || 1;
	
			// If it is not counted in grams, we then multiply by the grams per inventory unit, in order to find the correct gram-count.
			if (current_item.inventory_unit_of_measure.value !== 'g') {
				amount = grams_per_inventory_unit * item.amount;
			}				    	


			// Calculate total value per portion.
			const total_field_value_per_portion = (current_item[field_name] / 100) * amount;
			output = total_field_value_per_portion;
			return output;

	    }


	    // otherwise keep calculating sub items.
	    else if (current_item && current_item.onModel === 'Prep') {

	    	// Since this is not an ingredient, we're going one level deeper.
	    	level += 1;

	    	//Recursive loop lock - calculateCostFromThisItem() is a recursive function that calls itself. If a prep item includes it self as an ingredient, that would result in an infinite loop. This sets a limit as to how many lavels deep we allow it to go.
	    	if (level > 4) {
	    		return -1;
	    	}

	    	if (current_item.items) {
	 
	    		// Go through each item and calculate cost seperately
	    		for (const sub_item of current_item.items) {	
	    			let i = {...sub_item}
	    			output += nutritionCalc(i, field_name, level, output);
	    		}
	    	}
	    	
	    	// calculate cost for this prep item based on amount used. 
	    	if (current_item.inventory_units_per_pack >= 0) {
	    		const total_field_value_per_portion = ( output / current_item.inventory_units_per_pack) * item.amount;
	    		return total_field_value_per_portion;
	    	} else {
	    		return -1;
	    	}
	    }

		return output;

	}


	/**
	 * Nutrition calculataor proxy
	 * The proxy is used, because the actual calculator is a recursive function that calls it self. 
	 */
	const calculateNutritionalInfoFromThisItem = (values, field_name) => {

		// Setup variable
		let total_per_portion = 0;

		// Go through each item and calculate nutrition for [field_name] per portion.
		for(const item of values.items) {
			total_per_portion += nutritionCalc(item, field_name, 1, 0);
		}

		// Convert to nutrition per 100 grams
		const total_per_100 = (total_per_portion / values.inventory_units_per_pack) * 100 || 0;
		
		//return total with 2 decimals;
		return parseFloat(total_per_100.toFixed(2));
	}



	/**
	 * Get options for ingredients and prep lists.
	 */
	const getOptions = () => {

		let options = [];

		// Add ingredients to options list
		for (const item of props.ingredients) {
			item.onModel = 'Ingredient';

			if (item._id !== props.match.params.id) {
				options.push(item);
			}
		} 

		// Add prep to options list
		for (const item of props.prep) {
			item.onModel = 'Prep';
		
			if (item._id !== props.match.params.id) {
				options.push(item);	
			}
			
		} 

		// Order by name
		options.sort((a, b) => (a.name > b.name) ? 1 : -1)

		return options;

	}

	const calculateCostFromThisItem = (item, level, cost) => {
		
	  	const options = getOptions();
	    // Find the original dataset of current item
	    const current_item = options.find(current_item => current_item._id === item.item);

	    // Ingredients
	    if (current_item && current_item.onModel === 'Ingredient') {
	    	if (current_item.price_per_pack >= 0 && current_item.inventory_units_per_pack) {
	    		cost = current_item.price_per_pack / current_item.inventory_units_per_pack * item.amount || 0;
	    		return cost;	
	    	} else {
	    		return -1;
	    	}
	    }

		// otherwise keep calculating sub items.
		else if (current_item && current_item.onModel === 'Prep') {

			// Since this is not an ingredient, we're going one level deeper.
			level += 1;

			//Recursive loop lock - calculateCostFromThisItem() is a recursive function that calls itself. If a prep item includes it self as an ingredient, that would result in an infinite loop. This sets a limit as to how many lavels deep we allow it to go.
			if (level > 4) {
				return -1;
			}

			if (current_item.items) {
				// Go through each item and calculate cost seperately
				for (const sub_item of current_item.items) {	
					//console.log('si', sub_item);
					let i = {...sub_item}
					cost += calculateCostFromThisItem(i, level, cost);
				}
			}
			
			// calculate cost for this prep item based on amount used. 
			if (current_item.inventory_units_per_pack >= 0) {
				return cost / current_item.inventory_units_per_pack * item.amount || 0;	
			} else {
				return -1;
			}
		}

		// Return cost. 
		return cost;
	}


	/**
	 * Calculate total cost. 
	 */
	const getTotalCost = (items) => {

		let total = 0;

		for(const item of items) {

			let price = calculateCostFromThisItem(item, 1, 0);
	
				// Add to toal cost.
				total += price;
		}	

		return parseFloat(total.toFixed(2));
	}


	return (
		<div className="page-content-inner">

			<div className="page-content-inner-centering">

				{
					props.match.params.id ? (
						<div className="breadcrumbs">/menu/prep/{formData.name}</div>
					) : (
						<div className="breadcrumbs">/menu/prep/ny ingrediens</div>
					)
				}
				
				<Formik
					initialValues={{ 
						_id: props.match.params.id || '',
						// name: formData.name,
						// supplier: formData.supplier,
						// account: formData.category,
						// sku: formData.sku,
						...formData
					}}
				   

					enableReinitialize={true}

					validate={ values => {

						console.log('f', values);

						const errors = {};

						// Required fields
						const required_fields = [
						'name',
						'account',
						'sku',
						
						'pack_unit_of_measure',
						'inventory_unit_of_measure',
						'inventory_units_per_pack',
						// 'price_per_pack',
						'waste_percentage',

	
						];

						for(const required_field of required_fields) {
							if (values[required_field] === '') {
								errors[required_field] = 'Dette felt er påkrævet';
							}	
						}

						// Number validation
						const numbers_fields = [
							// 'co2_per_inventory_unit',
							// 'inventory_units_per_pack',
							// 'price_per_pack',
							// 'waste_percentage',
							// 'energy_kj',
							// 'energy_kcal',
							// 'fat',
							// 'saturated_fat',
							// 'carbohydrate',
							// 'sugar',
							// 'protein',
							// 'salt',
							// 'fiber',
						]

						for(const number_field of numbers_fields) {
							if ( isNaN(values[number_field])) {
								errors[number_field] = 'Dette felt skal være et tal';
							}	
						}

						// console.log(values.waste_percentage);
						// if (values.waste_percentage === '') {
						// 	errors.waste_percentage = 'Dette felt er påkrævet';
						//}	

						return errors;
					}}
				   
					onSubmit={(values, { setSubmitting }) => {
						

						let submitted_values = {...values};

						submitted_values.price_per_pack = getTotalCost(values.items);

						console.log('t', submitted_values);

						if (values.account.value) {
							submitted_values.account_id = values.account.value;	
						}

						

						submitted_values.items = [];
						for(const item of values.items) {
							if (item.item) {

								submitted_values.items.push({
									item: item.item,
									amount: item.amount,
									onModel: item.onModel,
								})
							}
						}


						sendData(submitted_values);

						setTimeout(() => {
							//alert(JSON.stringify(values, null, 2));
							setSubmitting(false);
						}, 2000);
				   }}
				>

				{({
					 values,
					 errors,
					 touched,
					 handleChange,
					 handleBlur,
					 handleSubmit,
					 isSubmitting,
					 setFieldValue,
					 resetForm,
					 /* and other goodies */
				}) => (
					<form onSubmit={handleSubmit} onChange={() => handleFormChange()}>
					
						<div className="title-field" data-field="name">
							<label className="title-field-label">Navn</label>
							<div className="title-field-input-container">
								<input type="text" className="title-field-input" placeholder="Indtast navn" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
							</div>
							{errors.name && touched.name && ( <div className="standard-field-input-error">{errors.name}</div> )}
						</div>


						<div className="field-section standard-field-section">

						
							<div className="standard-field" data-field="account">
								<div className="standard-field-label-container">
									<label className="standard-field-label">Konto</label>
								</div>
								<div className="standard-field-input-container">
									<Select
										styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
										placeholder="Vælg konto"
										value={values.account}
										onChange={option => {
											setFieldValue("account", option);
											handleFormChange();
										}}
										onBlur={handleBlur}
										options={
											props.accounts.map(account => {
												return {
													value: account._id, label: account.name,
												}
											})
										}
									/>
									{errors.account && touched.account && ( <div className="standard-field-input-error">{errors.account}</div> )}
								</div>
							</div>
							
							<div className="standard-field" data-field="sku">
								<div className="standard-field-label-container">
									<label className="standard-field-label">SKU</label>
								</div>
								<div className="standard-field-input-container">
									<input type="text" className="standard-field-input" placeholder="Indtast sku" name="sku" value={values.sku} onChange={handleChange} onBlur={handleBlur}/>
									{errors.sku && touched.sku && ( <div className="standard-field-input-error">{errors.sku}</div> )}
								</div>	    	        		
							</div>

						</div>



						<div className="field-section standard-field-section">


							<div className="field-section-header">OPSKRIFT</div>
						
						
							<RepeatableItemsTable 
								handleChange={handleChange} 
								handleBlur={handleBlur} 
								handleFormChange={handleFormChange} 
								items={values.items}
								options={getOptions()}
								setFieldValue={setFieldValue}
								/>
							
						</div>

					
						<div className="field-section standard-field-section">

							<div className="field-section-header">LAGER</div>

							<div className="standard-field" data-field="pack_unit_of_measure">
								<div className="standard-field-label-container">
									<label className="standard-field-label">Pakkeenhed</label>
									<div className="standard-field-label-tooltipper">
										<div className="standard-field-label-tooltip-icon" data-text='Den enhed som varen kommer i. Hvis ikke enheden er vægt eller volumetric, så brug "stk".'>i</div>
									</div>	
								</div>
								<div className="standard-field-input-container">
									<Select
										styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
										placeholder="Vælg pakkeenhed"
										value={values.pack_unit_of_measure}
										onChange={option => {
											setFieldValue("pack_unit_of_measure", option)
											handleFormChange();
										}}
										onBlur={handleBlur}
										options={[
											{ value: 'stk', label: 'Stk'},
											{ value: 'g', label: 'g'},
											{ value: 'kg', label: 'Kg'},
											{ value: 'ml', label: 'ml'},
											{ value: 'cl', label: 'cl'},
											{ value: 'l', label: 'L'},
											{ value: 'BT', label: 'BT'},
											{ value: 'gastro', label: 'Gastro'},
											{ value: 'index-5l', label: 'Index 5L'},
											{ value: 'Index 1L', label: 'Index 1L'},
		    	        					{ value: 'Index 3L', label: 'Index 3L'},
		    	        					{ value: 'BDT', label: 'BUNDT'},
		    	        					{ value: 'Kantine 1/3', label: 'Kantine 1/3'},
		    	        					{ value: 'Kantine 1/6', label: 'Kantine 1/6'},

											{ value: 'KRT', label: 'KRT'},
											{ value: 'BK', label: 'BK'},
											{ value: 'PS', label: 'PS'},
											{ value: 'PK', label: 'PK'},
											{ value: 'DS', label: 'DS'},
											{ value: 'GL', label: 'GL'},
											{ value: 'FL', label: 'FL'},
											{ value: 'DK', label: 'DK'},
											{ value: 'BT', label: 'BT'},
										]} 
									/>
									{errors.pack_unit_of_measure && touched.pack_unit_of_measure && ( <div className="standard-field-input-error">{errors.pack_unit_of_measure}</div> )}
								</div>
							</div>

							<div className="standard-field" data-field="inventory_unit_of_measure">
								<div className="standard-field-label-container">
									<label className="standard-field-label">Lagerenhed</label>
									<div className="standard-field-label-tooltipper">
										<div className="standard-field-label-tooltip-icon" data-text='Den mindste enhed som mængden på lageret måles i.'>i</div>
									</div>
								</div>
								<div className="standard-field-input-container">
									<Select 
										styles={{menu: provided => ({ ...provided, zIndex: 9999 })}} 
										placeholder="Vælg lagerenhed"
										value={values.inventory_unit_of_measure}
										onChange={option => {
											setFieldValue("inventory_unit_of_measure", option);
											handleFormChange();
										}}
										onBlur={handleBlur}
										options={[
											//{ value: 'stk', label: 'Stk'},
											{ value: 'g', label: 'g'},
											//{ value: 'ml', label: 'ml'},
										]} 
									/>
									{errors.inventory_unit_of_measure && touched.inventory_unit_of_measure && ( <div className="standard-field-input-error">{errors.inventory_unit_of_measure}</div> )}
								</div>
							</div>

							<div className="standard-field" data-field="inventory_units_per_pack">
								<div className="standard-field-label-container">
									<label className="standard-field-label">lagerenheder pr. pakke</label>
									<div className="standard-field-label-tooltipper">
										<div className="standard-field-label-tooltip-icon" data-text='Hvor meget af lagerenheden er der i hver pakkeenhed?'>i</div>
									</div>
								</div>
								<div className="standard-field-input-container">
									<input type="number" step="1" className="standard-field-input" placeholder="Antal" name="inventory_units_per_pack" value={values.inventory_units_per_pack} onChange={handleChange} onBlur={handleBlur}/>
									{errors.inventory_units_per_pack && touched.inventory_units_per_pack && ( <div className="standard-field-input-error">{errors.inventory_units_per_pack}</div> )}	    	        			
								</div>
							</div>


							<div className="standard-field" data-field="waste_percentage">
								<div className="standard-field-label-container">
									<label className="standard-field-label">Spildprocent</label>
									<div className="standard-field-label-tooltipper">
										<div className="standard-field-label-tooltip-icon" data-text='Den generelle spildprocent for denne vare'>i</div>
									</div>
								</div>
								<div className="standard-field-input-container">
									<input type="number" step="1" className="standard-field-input" placeholder="Procent" name="waste_percentage" value={values.waste_percentage} onChange={handleChange} onBlur={handleBlur}/>
									{errors.waste_percentage && touched.waste_percentage && ( <div className="standard-field-input-error">{errors.waste_percentage}</div> )}
								</div>
							</div>
							<div className="standard-field">
	    	        			<div className="standard-field-label-container" data-field="par_level">
	    	        				<label className="standard-field-label">PAR Niveau</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='Periodic automatic replenishment: Antal pakkeenheder der helst skal være på lager'>i</div>
	    	        				</div>
	    	        			</div>
	    	        			<div className="standard-field-input-container">
	    	        				<input type="number" className="standard-field-input" placeholder="Antal" name="par_level" value={values.par_level} onChange={handleChange} onBlur={handleBlur}/>
									{errors.par_level && touched.par_level && ( <div className="standard-field-input-error">{errors.par_level}</div> )}
	    	        			</div>
	    	        		</div>
							{/*
							<div className="standard-field standard-field--wide standard-field--disabled" data-field="">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Optællingsenheder (kommer snart)</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='Enheder som skal kunne bruges til optællinger i forbindelse med status'>i</div>
	    	        				</div>
	    	        			</div>
	    	        			<div className="block-select-container">
	    	        				<ul className="block-select">
	    	        					<li className="block-select-item">
	    									<label className="block-select-item-container">
												<Field type="checkbox" name="count_units"/>
	    									  	<div className="block-select-item-element">ml</div>
	    									</label>
	    								</li>
	    								<li className="block-select-item">
	    									<label className="block-select-item-container">
												<Field type="checkbox" name="count_units"/>
	    									  	<div className="block-select-item-element">L</div>
	    									</label>
	    								</li>
	    	        				</ul>
	    	        			</div>
	    	        		</div>
	    	        		*/}


	    	        		<div className="standard-field standard-field--wide" data-field="">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Brugerdefinerede optællingsenheder</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='(Max 2) enheder som skal kunne bruges til optællinger i forbindelse med status (ud over lager og pakkeenhed)'>i</div>
	    	        				</div>
	    	        			</div>
							
	    	        			<RepeatableUnits 
	    	        				handleChange={handleChange} 
	    	        				handleBlur={handleBlur} 
	    	        				handleFormChange={handleFormChange}
	    	        				custom_units={values.custom_units}
	    	        				setFieldValue={setFieldValue}
	    	        				values={values}
	    	        				units={props.units}
	    	        			/>
	    	        			<br/>
	    	        		</div>


	    	        		<div className="standard-field standard-field--wide" data-field="">
	    	        			<div className="standard-field-label-container">
	    	        				<label className="standard-field-label">Lagre</label>
	    	        				<div className="standard-field-label-tooltipper">
	    	        					<div className="standard-field-label-tooltip-icon" data-text='Enheder som skal kunne bruges til optællinger i forbindelse med status'>i</div>
	    	        				</div>
	    	        			</div>
	    	        			<div className="block-select-container">
	    	        				<ul className="block-select">
	    	        					{
	    	        						props.inventoryData.map((inventory, i) => {

	    	        							return (
	    	        								<li key={i} className="block-select-item">
	    	        									<label className="block-select-item-container">
															<Field type="checkbox" name="inventories" value={inventory._id} />
	    	        									  	<div className="block-select-item-element">{inventory.name}</div>
	    	        									</label>
	    	        								</li>
	    	        							)
	    	        						})

	    	        					}
	    	        				</ul>
	    	        			</div>
	    	        		</div>

						</div>


						<div className="field-section">

							<div className="field-section-header">Næringsindhold pr 100g</div>

							<div className="info-card">
								<h2 className="info-card-title">{values.name}</h2>
								{/*
								<div className="info-card-description">
									<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Est, aut.</p>
								</div>
								*/}
								
								<div className="info-card-nutrition-info">
									<div className="info-card-nutrition-info-header">
										<h3>Næringsindhold pr. 100g</h3>
									</div>	

									<div className="info-card-nutrition-info-content">
										<ul className="info-card-nutrition-info-list">
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">Energi</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseInt(calculateNutritionalInfoFromThisItem(values, 'energy_kj'))}kJ / {parseInt(calculateNutritionalInfoFromThisItem(values, 'energy_kcal'))}kcal</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">Fedt</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'fat'), 1)}g</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">- heraf mættede fedtsyrer</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'saturated_fat'), 1)}g</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">Kulhydrater</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'carbohydrate'), 1)}g</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">- heraf sukkerarter</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'sugar'), 1)}g</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">Protein</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'protein'), 1)}g</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">Salt</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'salt'), 1)}g</div>
											</li>
											<li className="info-card-nutrition-info-item">
												<div className="info-card-nutrition-info-item-title">Kostfibre</div>
												<div className="info-card-nutrition-info-item-fill"></div>
												<div className="info-card-nutrition-info-item-value">{parseFloat(calculateNutritionalInfoFromThisItem(values, 'fiber'), 1)}g</div>
											</li>
										</ul>

									</div>	
								</div>
							</div>
						</div>
		

						{
							formChange === true ? (
								<div className="fixed-action-bar">
									<button type="button" className="button button--ghost button--medium" onClick={() => cancelChanges(resetForm)}>ANNULER</button>
									<button type="submit" className="standard-submit button button--medium" disabled={isSubmitting}>GEM</button>
								</div>
							) : (
								<div className="fixed-action-bar fixed-action-bar--hidden">
									<button type="button" className="button button--ghost button button--medium" onClick={() => cancelChanges(resetForm)}>ANNULER</button>
									<button type="submit" className="standard-submit button button--medium" disabled={isSubmitting}>GEM</button>
								</div>
							)	
						}

						{
							formSaved === true ? (
								<div className="fixed-action-bar fixed-action-bar--success">Gemt!</div>
							) : (
								<div className="fixed-action-bar fixed-action-bar--success fixed-action-bar--hidden">Gemt!</div>
							)	
						}
						
						
					<FocusError/>				
					 </form>
				   )}


				</Formik>

			

				{
					props.match.params.id && (
						<div className="danger-zone">

							<div className="danger-zone-section-heading">
								<h2 className="danger-zone-section-title">Danger Zone</h2>
							</div>

							{
								relatedPrepData.length === 0 && relatedRecipesData.length === 0 ? (
									<div className="danger-zone-content">
										<h3 className="danger-zone-title">Slet denne prep</h3>
											<p>Når du sletter den, vil du ikke kunne få den tilbage. Du vil være nødt til at oprette den igen forfra</p>
											<button className="button button--warning" onClick={handleDelete}>SLET</button>
									</div>
								) : (
									<div className="danger-zone-content">
										<h3 className="danger-zone-title">Slet denne prep</h3>
										<p>Denne ingredient kan ikke slettes, da den er benyttet i følgende opskrifter. Fjern først denne ingredient fra opskrifter, før du kan slette den.</p>
										
										
										{
											relatedPrepData.length > 0 && (
												<Fragment>
													<h4>Prep</h4>
													<ul>
														{
															relatedPrepData.map((item, i) => {
																return (
																	<li key={i}>
																		<Link to={`/menu/prep/${item._id}`}>
																			{item.name}
																		</Link>
																	</li>
																)
															})
														}
													</ul>
												</Fragment>

											)
										}
										

										{
											relatedRecipesData.length > 0 && (
												<Fragment>
													<h4>Opskrifter</h4>
													<ul>
														{
															relatedRecipesData.map((item, i) => {
																return (
																	<li key={i}>
																		<Link to={`/menu/recipes/${item._id}`}>
																			{item.name}
																		</Link>
																	</li>
																)
															})
														}
													</ul>
												</Fragment>

											)
										}

										<button className="button button--warning" onClick={handleDelete} disabled="disabled">SLET</button>
									</div>
								)

							}
						</div>
					)
				}


				<div className="form-padding"></div>

				

			</div>
		</div>

	); 
};

export default PrepForm;
