import React from "react";
import Select from 'react-select'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const formatOptionLabel = ({ value, label, onModel}) => (
  <div style={{ display: "flex", justifyContent: 'space-between' }}>
    <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{label}</div>
    <div style={{ marginLeft: "10px", color: "#ccc" }}>
      {onModel}
    </div>
  </div>
);

export const RepeatableItemsTable = (props) => {

	/**
	 * Add row to the table
	 */
	const addTableRow = () => {
		// A temp dragID for new rows.
		const dragId = `dragItem-${Date.now()}`;

		// Create new items array
		let items = Array.from(props.items);
		
		// Add a new item
		items.push({
			_id: dragId,
			item: undefined,
			// item: {
			// 	inventory_unit_of_measure: {
			// 		value: 'g',
			// 		label: 'g',
			// 	},
			// 	inventory_units_per_pack: 0,
			// 	name: '',
			// 	price_per_pack: 0,
			// },
			amount: 0,
			new_item: true,
		})

		// Update form values
		props.setFieldValue('items', items);
		props.handleFormChange();
	}

	/**
	 * Delete specified row
	 */
	const deleteTableRow = (rowId) => {

		// Create new array of items based on existing (before drag)		
		let items = Array.from(props.items);

		const itemIndex = items.findIndex(item => item._id === rowId);

		items.splice(itemIndex, 1);

		// Update form values
		props.setFieldValue('items', items);

		props.handleFormChange();
	}


	/**
	 * Handle rearranging of items after dropping an item.
	 */
	const onDragEnd = (result) => {
		const {destination, source, draggableId} = result;

		// Dropped outside droppable area
		if (!destination) {
			return;
		}

		// Dropped at the same location as it was
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		// Create new array of items based on existing (before drag)		
		let items = Array.from(props.items);

		// Find the dragged item
		let draggedItem = items.find(item => item._id === draggableId);

		// Remove the dragged item from array;
		items.splice(source.index, 1);

		// Add the dragged item into the new position
		items.splice(destination.index, 0, draggedItem);

		// Update items state
		props.setFieldValue('items', items);

		props.handleFormChange();
	}


	const calculateCostFromThisItem = (item, level, cost = 0) => {

		if (!item.item) {
			return cost;
		}

	    // Find the original dataset of current item
	    const current_item = props.options.find(current_item => current_item._id === item.item);
	    console.log('current_item: ', current_item);

	    // Ingredients
	    if (current_item && current_item.onModel === 'Ingredient') {
	    	if (current_item.price_per_pack >= 0 && current_item.inventory_units_per_pack) {
	    		
				// Calculate base cost
				cost = current_item.price_per_pack / current_item.inventory_units_per_pack * item.amount || 0;
	    		
				// Add waste percentage
				cost = cost * (1 + (current_item.waste_percentage / 100));

				return cost;	
	    	} else {
	    		return -1;
	    	}
	    }

		// otherwise keep calculating sub items.
		else if (current_item && current_item.onModel === 'Prep') {

			// Since this is not an ingredient, we're going one level deeper.
			level += 1;

			//Recursive loop lock - calculateCostFromThisItem() is a recursive function that calls itself. If a prep item includes it self as an ingredient, that would result in an infinite loop. This sets a limit as to how many lavels deep we allow it to go.
			if (level > 4) {
				return -1;
			}

			if (current_item.items) {
				//console.log('si');
				// Go through each item and calculate cost seperately
				for (const sub_item of current_item.items) {	
					//console.log('si', sub_item);
					let i = {...sub_item}
					cost += calculateCostFromThisItem(i, level, cost);
				}
			}
			
			// calculate cost for this prep item based on amount used. 
			if (current_item.inventory_units_per_pack >= 0) {
				// Calculate prep base costs
				let prepCost = cost / current_item.inventory_units_per_pack * item.amount || 0;	
				
				// Add prep waste
				prepCost = prepCost * (1 + (current_item.waste_percentage/100))
				return prepCost;
			} else {
				return -1;
			}
		}

		// Return cost. 
		return cost;
	}


	/**
	 * Calculate total cost. 
	 */
	const getTotalCost = () => {

		let total = 0;

		for(const item of props.items) {

			let price = calculateCostFromThisItem(item, 1, 0);
	
				// Add to toal cost.
				total += price;
		}	

		return parseFloat(total.toFixed(2));
	}

	/**
	 * Options object
	 * 
	 * option contains detailed info, 
	 * item contains the bare minimum details from db.
	 */
	
	const optionsObject = (option, item) => {

		const optionsObject = {
			// For select2
			value: option._id, 
			label: option.name,
			onModel: option.onModel,

			// The base data from db
			_id: item._id, // Id of the ref entry in DB (not the actual ingredient)
			item: item.item, // Id of the actual item selected
			amount: item.amount,

			// Detailed data from option
			inventory_unit_of_measure: option.inventory_unit_of_measure,
			inventory_units_per_pack: option.inventory_units_per_pack,
			price_per_pack: option.price_per_pack,

			// list of subItems in case of prep or recipes.
			items: option.items,
		}

		return optionsObject;

	}
	

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			
			<table className="recipe-table">
				<thead>
					<tr>
						<th>Ingrediens</th>
						<th>Mængde</th>
						<th>Pris</th>
						<th></th>
						<th></th>
					</tr>
				</thead>

				<Droppable droppableId="itemArea1">
					{provided => (
						<tbody ref={provided.innerRef} {...provided.droppableProps}>
							
							{
								props.items.map((item, index) => {

									// Defaults
									let amount = item.amount || 0;
									let unit = 'g';
									let price = 0;
									let option = '';

									// If this is not a new item, we start setting som variables
									if (!item.new_item) {

										// See if we can find the detailed option based on the item id (item.item)
										const item_option = props.options.find(option => option._id === item.item);

										// If it doesnt exist, we show an error output
										if (!item_option) {

											option = {
												value: 0,
												label: 'Eksisterer ikke',
												onModel: '- 404'
											}

										} else {
											option = optionsObject(item_option, item);
											unit = option.inventory_unit_of_measure.value;

											// Calculate individual price
											price = calculateCostFromThisItem(item, 1, 0);
											price = parseFloat(price.toFixed(2));
										}


									}
										
									return (
										<Draggable key={item._id} draggableId={item._id} index={index}>
											{provided => (
												<tr className="recipe-table-row" {...provided.draggableProps} ref={provided.innerRef}>
													
													<td className="recipe-table-ingredient">
														
														
														<Select
															styles={{menu: select_provided => ({ ...select_provided, zIndex: 9999 })}} 
															placeholder="Vælg ingrediens"
															value={option}
															onChange={selected_option => {

																const option = {
																	_id: selected_option._id, // id of the entry in DB, not the actual item (used as dragID)
																	item: selected_option.value, // id of the selected item
																	amount: selected_option.amount,
																	onModel: selected_option.onModel,
																}
																// Set current fieldvalue
																props.setFieldValue(`items[${index}]`, option);
																
																// Mark that form input has changes
																props.handleFormChange();
															}}
															onBlur={props.handleBlur}
															formatOptionLabel={formatOptionLabel}
															options={
																props.options.map(option => {
																	return optionsObject(option, item);
																})
															}
														/>
													</td>
													
													
													<td className="recipe-table-measurement">
														<div className="field-with-suffix" data-suffix="g">
															<input type="number" step="1" className="input-field-with-suffix" value={amount} name={`items[${index}].amount`} onChange={props.handleChange} onBlur={props.handleBlur} />
															<div className="field-suffix">{unit}</div>
														</div>
													</td>
													
													<td className="recipe-table-price">
														<div className="field-with-suffix" data-suffix="g">
															<input type="number" step="0.01" className="input-field-with-suffix" readOnly disabled value={price} />
															<div className="field-suffix">Kr.</div>
														</div>
													</td>

													<td className="recipe-table-remove">
														<button type="button" className="recipe-table-remove-button" onClick={() => deleteTableRow(item._id)}>–</button>
													</td>
													<td className="recipe-table-drag-handle" {...provided.dragHandleProps}>
														<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512"><g id="Group_61"><path id="Rectangle_147" d="M135.1 15.6h80.3v80.3h-80.3z"/><path id="Rectangle_148" d="M135.1 216.3h80.3v80.3h-80.3z"/><path id="Rectangle_149"  d="M135.1 417h80.3v80.3h-80.3z"/></g><g id="Group_64" transform="translate(4)"><path id="Rectangle_147-2" d="M291.6 15.6h80.3v80.3h-80.3z"/><path id="Rectangle_148-2" d="M291.6 216.3h80.3v80.3h-80.3z"/><path id="Rectangle_149-2" d="M291.6 417h80.3v80.3h-80.3z"/></g></svg>
													</td>
												</tr>
											)}		
										</Draggable>
									)

								})
							}
							
							{provided.placeholder}
						</tbody>
					)}
				</Droppable>

			</table>
			<button type="button" className="recipe-table-add-row" onClick={() => addTableRow()}>+</button>

			<div className="standard-field">
				<div className="standard-field-label-container">
					<label className="standard-field-label">Samlet kostpris (inkl. spild)</label>
				</div>
				<div className="standard-readonly-field-input-container">
					<input type="text" className="standard-readonly-field-input" value={getTotalCost()} readOnly disabled/>
				</div>	    	        		
			</div>

			{
				props.price && (
					<div className="standard-field">
						<div className="standard-field-label-container">
							<label className="standard-field-label">Dækningsbiddrag (%)</label>
						</div>
						<div className="standard-readonly-field-input-container">
							<input type="text" className="standard-readonly-field-input" value={parseFloat(100 - (getTotalCost() / props.price * 100)).toFixed(2) || 0} readOnly disabled/>
						</div>	    	        		
					</div>
				)
			}

			
		</DragDropContext>
	); 
};

export default RepeatableItemsTable;
