import { useEffect } from "react";
import { useFormikContext } from 'formik';
const FocusError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let keys = Object.keys(errors);
      if (keys.length > 0) {
        console.log(keys[0]);
      	const selector = `[data-field=${keys[0]}]`;
      	const errorElement = document.querySelector(selector);
        console.log(errorElement);
        if (errorElement) {
          //errorElement.focus();
          errorElement.scrollIntoView({behavior: 'smooth'});
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
}

export default FocusError;