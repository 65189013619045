import React, { useState, useEffect } from "react";
import { Route, Switch, Link, useHistory } from "react-router-dom";
import axios from 'axios';

import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

import PrepNoSelection from "../components/PrepNoSelection";
import PrepForm from "../components/PrepForm";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

// import LocalIngredientsData from "../data/ingredients";
//console.log(LocalIngredientsData.ingredients);

const api_url = process.env.REACT_APP_GROED_API_URL;


export const Prep = (props) => {

	// Variables from History (react router)
	const history = useHistory();

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// STATE: search
	const [searchState, setSearchState] = useState({
		searchTerm: '',
	});

	// STATE: Recipes
	//const [recipeData, setRecipeData] = useState([]);

	// STATE: Prep
	const [prepData, setPrepData] = useState([]);
	const [activePrepData, setActivePrepData] = useState([]);

	// STATE: ingredients
	const [ingredientData, setIngredientData] = useState([]);

	// STATE: Accounts
	const [accountData, setAccountData] = useState([]);

	// STATE: Units
	const [unitData, setUnitData] = useState([]);

	// STATE: Inventories
	const [inventoryData, setInventoryData] = useState([]);


	/**
  	 * Get ingredients from DB
  	 */
	useEffect(() => {
		
		(async () => {

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get recipes
				// const recipeResponse = await axios.get(`${api_url}/recipe`, { headers: { 'Authorization': `Bearer ${access_token}`}});	
				// setRecipeData(recipeResponse.data.recipes);

				// Get prep
				const prepResponse = await axios.get(`${api_url}/prep`, {headers: { 'Authorization': `Bearer ${access_token}`}});	
				const sortedPrep = prepResponse.data.prep.sort((a, b) => a.name > b.name ? 1 : -1);
				setPrepData(sortedPrep);
				setActivePrepData(sortedPrep);

				// Get ingredients
				const ingredientsResponse = await axios.get(`${api_url}/ingredient`, {headers: { 'Authorization': `Bearer ${access_token}`}});
				setIngredientData(ingredientsResponse.data.ingredients);

				// Get accounts
				const accountsResponse = await axios.get(`${api_url}/account`, {headers: { 'Authorization': `Bearer ${access_token}`}});
				setAccountData(accountsResponse.data.accounts);

				// Get units
				const unitResponse = await axios.get(`${api_url}/unit`, {headers: { 'Authorization': `Bearer ${access_token}`}});	
				setUnitData(unitResponse.data.units);

				// Get inventories
				const inventoriesResponse = await axios.get(`${api_url}/inventory`, {headers: { 'Authorization': `Bearer ${access_token}`}});
				setInventoryData(inventoriesResponse.data.inventories);

				setIsLoading(false);

			} catch (e) {
        		console.error(e);
      		}

		})();		

	}, [getAccessTokenSilently]);


	/**
  	 * Change filtered list data, everytime search term or prep data changes
  	 */
	useEffect(() => {
		const filteredPrepData = prepData.filter( prep => prep.name.search(new RegExp(searchState.searchTerm, "i")) !== -1);
		setActivePrepData(filteredPrepData);
	}, [searchState, prepData]);	

	// Create new Prep
  	const newPrep = () => {
  		//@TODO Check if unsaved edits have been to an existing document. 

  		// Go to new ingredient URL. 
  		history.push("/menu/prep/new");
  	}

  	/**
  	 * Get prep
  	 */
  	const getPrep = async () => {

  		try {
  			// Get access token
  			const access_token = await getAccessTokenSilently();

  			let config = {headers: { 'Authorization': `Bearer ${access_token}`}}
  			const axiosPromise = axios.get(`${api_url}/prep`, config);	
  			const response = axiosPromise;

  			return response
  			
  	  
  		} catch (e) {
  	    	console.error(e);
  		}
  	}

  	/**
  	 * Save a prep
  	 */
	const savePrep = async (data) => {

		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {headers: { 'Authorization': `Bearer ${access_token}`}}
			const axiosPromise = axios.post(`${api_url}/prep`, data, config);	
			const response = axiosPromise;
			
			return response;

		} catch (e) {
			console.error(e);
		}
	}

	/**
  	 * Delete a prep
  	 */
	const deletePrep = async (data) => {
	
		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {
				headers: { 'Authorization': `Bearer ${access_token}`},
				data: { _id: data._id }
			}
			const axiosPromise = axios.delete(`${api_url}/prep`, config);	
			const response = axiosPromise;

			return response;
			      
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * Handle searchbar value
	 */
  	const handleSearchChange = (event) => {
  		const target = event.target;
  		const value = target.type === 'checkbox' ? target.checked : target.value;
  		//const name = target.name;

  		// Set state
  		setSearchState({
  			searchTerm: value
  		});
  	}


	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Ingredienser"/>
			</div>
			
			<div className="app-content app-content--with-panel">
				
				<div className="app-panel">

					<div className="item-list-searchbar">
						<div className="item-list-searchfield-container">
							<div className="item-list-searchfield-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M8.8 8.1c.7-.9 1.1-2 1.1-3.1 0-2.7-2.2-5-5-5S0 2.2 0 5c0 2.7 2.2 5 5 5 1.1 0 2.2-.4 3.1-1.1l3.2 3.2.7-.7-3.2-3.3zM5 8.9c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4c-.1 2.2-1.9 4-4 4z" fill="#020302"/></svg>
							</div>
							<input type="text" className="item-list-search-input" name="searchValue" value={searchState.searchTerm} onChange={handleSearchChange} placeholder="Søg"/>
						</div>
						
					</div>


					<div className="item-list-scrollable">

						{
							isLoading ? (
								<ul className="item-list">
									{
										['','','','','','','','','',''].map((x, i) => {
											return (
												<li key={i} className="item-list-item">
													<div className="item-list-item-skeleton"></div>
												</li>	
											)
										})
									}
								</ul>
							) : (
								<ul className="item-list">
									{
										activePrepData.map((prep, i) => {

											let selectedClass = '';
											if (props.location.pathname.includes(prep._id)) {
												selectedClass = 'item-list-item--active';	
											}
											
											return (

												<li key={i} className={`item-list-item ${selectedClass}`}>
													<Link to={`/menu/prep/${prep._id}`}>
														
														<div className="item-list-item-title">{prep.name}</div>
														<div className="item-list-item-subtitle">
															{
																prep.brand && (
																	prep.brand
																)														
															}


															{
																prep.pack_unit_of_measure && prep.pack_unit_of_measure.label && (
																	` (${prep.pack_unit_of_measure.label})`
																)														
															}

														</div>

													</Link>
												</li>
											)
										})
									}

								</ul>
							)
						}
					</div>

					<div className="item-list-primary-button-container">
						<button className="item-list-primary-button" onClick={ ()=> newPrep() }>Opret ny prep</button>
					</div>
					

				</div>

				<div className="page-content">
					<Switch>
					  <Route path="/menu/prep" exact component={PrepNoSelection} />
					  
					
						<Route path="/menu/prep/new" 
							exact 
					  		render={(props) => 
					  		<PrepForm {...props} 
					  			prep={prepData}
					  			ingredients={ingredientData}
					  			accounts={accountData}
					  			units={unitData}
					  			inventoryData={inventoryData}
					  			getPrep={getPrep}
					  			setPrepData={setPrepData}
					  			savePrep={savePrep}
					  			deletePrep={deletePrep}
					  			getAccessTokenSilently={getAccessTokenSilently}
					  		/>
						}/>
					  
						<Route path="/menu/prep/:id" 
							exact 
					  		render={(props) => 
					  		<PrepForm {...props} 
					  			prep={prepData}
					  			ingredients={ingredientData}
					  			accounts={accountData}
					  			units={unitData}
					  			inventoryData={inventoryData}
					  			getPrep={getPrep}
					  			setPrepData={setPrepData}
					  			savePrep={savePrep}
					  			deletePrep={deletePrep} 
					  			getAccessTokenSilently={getAccessTokenSilently}
					  		/>
						}/>

					</Switch>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Prep, {
  onRedirecting: () => <Loading />,
});
