import React, { useState, useEffect } from "react";
import { Route, Switch, Link, useHistory } from "react-router-dom";
import axios from 'axios';

import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

import RecipeNoSelection from "../components/RecipeNoSelection";
import RecipeForm from "../components/RecipeForm";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const api_url = process.env.REACT_APP_GROED_API_URL;


export const Recipes = (props) => {

	// Variables from History (react router)
	const history = useHistory();

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// STATE: search
	const [searchState, setSearchState] = useState({
		searchTerm: '',
	});

	// STATE: Recipe
	const [recipeData, setRecipeData] = useState([]);
	const [activeRecipeData, setActiveRecipeData] = useState([]);

	// STATE: Prep
	const [prepData, setPrepData] = useState([]);

	// STATE: Ingredients
	const [ingredientData, setIngredientData] = useState([]);

	// STATE: Accounts
	const [accountData, setAccountData] = useState([]);

	/**
  	 * Get ingredients from DB
  	 */
	useEffect(() => {
		
		(async () => {

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get recipes
				const recipeResponse = await axios.get(`${api_url}/recipe`, { headers: { 'Authorization': `Bearer ${access_token}`}});	
				const sortedRecipes = recipeResponse.data.recipes.sort((a, b) => a.name > b.name ? 1 : -1);
				setRecipeData(sortedRecipes);
				setActiveRecipeData(sortedRecipes);

				// Get prep
				const prepResponse = await axios.get(`${api_url}/prep`, {headers: { 'Authorization': `Bearer ${access_token}`}});	
				setPrepData(prepResponse.data.prep);

				// Get ingredients
				const ingredientsResponse = await axios.get(`${api_url}/ingredient`, {headers: { 'Authorization': `Bearer ${access_token}`}});
				setIngredientData(ingredientsResponse.data.ingredients);

				// Get accounts
				const accountsResponse = await axios.get(`${api_url}/account`, {headers: { 'Authorization': `Bearer ${access_token}`}});
				setAccountData(accountsResponse.data.accounts);

				setIsLoading(false);

			} catch (e) {
        		console.error(e);
      		}

		})();

	}, [getAccessTokenSilently]);


	/**
  	 * Change filtered list data, everytime search term or prep data changes
  	 */
	useEffect(() => {
		const filteredRecipesData = recipeData.filter( recipe => recipe.name.search(new RegExp(searchState.searchTerm, "i")) !== -1);
		setActiveRecipeData(filteredRecipesData);
	}, [searchState, recipeData]);	

	// Create new Prep
  	const newRecipe = () => {
  		//@TODO Check if unsaved edits have been made to an existing document, and warn user before leaving existing page.

  		// Go to new ingredient URL. 
  		history.push("/menu/recipes/new");
  	}

  	/**
  	 * Get recipes
  	 */
  	const getRecipes = async () => {

  		try {
  			// Get access token
  			const access_token = await getAccessTokenSilently();

  			let config = { headers: { 'Authorization': `Bearer ${access_token}`} }
  			const axiosPromise = axios.get(`${api_url}/recipe`, config);	
  			const response = axiosPromise;

  			return response
  	  
  		} catch (e) {
  	    	console.error(e);
  		}
  	}

  	/**
  	 * Save a recipe
  	 */
	const saveRecipe = async (data) => {

		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {headers: { 'Authorization': `Bearer ${access_token}`}}
			const axiosPromise = axios.post(`${api_url}/recipe`, data, config);	
			const response = axiosPromise;
			
			return response;

		} catch (e) {
			console.error(e);
		}
	}
	
	/**
  	 * Delete a recipe
  	 */
	const deleteRecipe = async (data) => {
	
		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {
				headers: { 'Authorization': `Bearer ${access_token}`},
				data: { _id: data._id }
			}
			const axiosPromise = axios.delete(`${api_url}/recipe`, config);	
			const response = axiosPromise;

			return response;
			      
		} catch (e) {
			console.error(e);
		}
	}

	/**
  	 * Get prep
  	 */
  	const getPrep = async () => {

  		try {
  			// Get access token
  			const access_token = await getAccessTokenSilently();

  			let config = { headers: { 'Authorization': `Bearer ${access_token}`} }
  			const axiosPromise = axios.get(`${api_url}/prep`, config);	
  			const response = axiosPromise;

  			return response
  			
  		} catch (e) {
  	    	console.error(e);
  		}
  	}

	/**
	 * Handle searchbar value
	 */
  	const handleSearchChange = (event) => {
  		const target = event.target;
  		const value = target.type === 'checkbox' ? target.checked : target.value;
  		//const name = target.name;

  		// Set state
  		setSearchState({
  			searchTerm: value
  		});
  	}

	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Ingredienser"/>
			</div>
			
			<div className="app-content app-content--with-panel">
				
				<div className="app-panel">

					<div className="item-list-searchbar">
						<div className="item-list-searchfield-container">
							<div className="item-list-searchfield-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M8.8 8.1c.7-.9 1.1-2 1.1-3.1 0-2.7-2.2-5-5-5S0 2.2 0 5c0 2.7 2.2 5 5 5 1.1 0 2.2-.4 3.1-1.1l3.2 3.2.7-.7-3.2-3.3zM5 8.9c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4c-.1 2.2-1.9 4-4 4z" fill="#020302"/></svg>
							</div>
							<input type="text" className="item-list-search-input" name="searchValue" value={searchState.searchTerm} onChange={handleSearchChange} placeholder="Søg"/>
						</div>
						
					</div>


					<div className="item-list-scrollable">
						
						{
							isLoading ? (
								<ul className="item-list">
									{
										['','','','','','','','','',''].map((x, i) => {
											return (
												<li key={i} className="item-list-item">
													<div className="item-list-item-skeleton"></div>
												</li>	
											)
										})
									}
								</ul>
							) : (
								<ul className="item-list">
									{
										activeRecipeData.map((recipe, i) => {

											let selectedClass = '';
											if (props.location.pathname.includes(recipe._id)) {
												selectedClass = 'item-list-item--active';	
											}
											
											return (

												<li key={i} className={`item-list-item ${selectedClass}`}>
													<Link to={`/menu/recipes/${recipe._id}`}>
														
														<div className="item-list-item-title">{recipe.name}</div>
														<div className="item-list-item-subtitle">
															{
																recipe.brand && (
																	recipe.brand
																)														
															}


															{
																recipe.pack_unit_of_measure && recipe.pack_unit_of_measure.label && (
																	` (${recipe.pack_unit_of_measure.label})`
																)														
															}

														</div>

													</Link>
												</li>
											)
										})
									}
								</ul>
							)
						}
					</div>

					<div className="item-list-primary-button-container">
						<button className="item-list-primary-button" onClick={ ()=> newRecipe() }>Opret ny opskrift</button>
					</div>
					

				</div>

				<div className="page-content">
					<Switch>
						<Route path="/menu/recipes" exact component={RecipeNoSelection} />
					  
					
						<Route path="/menu/recipes/new" 
							exact 
					  		render={(props) => 
					  		<RecipeForm {...props} 
					  			recipes={recipeData}
					  			getRecipes={getRecipes}
					  			setRecipeData={setRecipeData}
					  			saveRecipe={saveRecipe}
					  			deleteRecipe={deleteRecipe}

					  			prep={prepData}
					  			getPrep={getPrep}

					  			ingredients={ingredientData}
					  			accounts={accountData}
					  		/>
						}/>
					  
						<Route path="/menu/recipes/:id" 
							exact 
					  		render={(props) => 
					  		<RecipeForm {...props} 
					  			recipes={recipeData}
					  			getRecipes={getRecipes}
					  			setRecipeData={setRecipeData}
					  			saveRecipe={saveRecipe}
					  			deleteRecipe={deleteRecipe}

					  			prep={prepData}
					  			getPrep={getPrep}

					  			ingredients={ingredientData}
					  			accounts={accountData}
					  		/>
						}/>

					</Switch>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Recipes, {
  onRedirecting: () => <Loading />,
});
