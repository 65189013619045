import React from "react";
import { useHistory } from "react-router-dom";

export const OrdersNoEntries = () => {

	// Variables from History (react router)
	const history = useHistory();

	// Create new ingredient
  	const newOrder = () => {
  		//@TODO Check if unsaved edits have been to an existing document. 

  		// Go to new ingredient URL. 
  		history.push("/inventory/order");
  	}

	return (
		<div className="intro-section">

			<div className="intro-section-content">
			
				<div className="intro-section-image">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M445.4 104.4L292.6 16.2c-22.6-13-50.6-13-73.1 0L66.6 104.4C44 117.4 30 141.7 30 167.8v176.5c0 26 14 50.3 36.6 63.3l152.8 88.2c11.3 6.5 23.9 9.8 36.6 9.8 12.6 0 25.3-3.3 36.6-9.8l152.8-88.2c22.6-13 36.6-37.3 36.6-63.3V167.8c0-26.1-14-50.4-36.6-63.4zm-205.6-53c5-2.9 10.6-4.3 16.2-4.3s11.2 1.4 16.2 4.3l148.7 85.9-43.5 25.1-164.9-95.2 27.3-15.8zm107.9 202.2v-27l15.8-9.1v27l-15.8 9.1zM256 232.5L91.1 137.3l46.3-26.8 164.9 95.2-46.3 26.8zm76.9-44.4L168 92.9l14-8.1L346.9 180l-14 8.1zm-246 184.2c-10-5.8-16.2-16.5-16.2-28.1V172.5l164.9 95.2v190.4L86.9 372.3zm338.2 0l-148.7 85.9V267.8l40.8-23.6v62.2l76.9-44.5v-62.1l47.2-27.3v171.7c0 11.6-6.2 22.4-16.2 28.1z"/></svg>
				</div>

				<h1 className="intro-section-title">Ordrer</h1>

				<div className="intro-section-description">
					<p>Du har ikke lagt en bestilling endnu. Så snart du har lagt mindst én bestilling kan du følge dine ordrer, samt lagerfører dine vare her.</p>
				</div>

				<div className="intro-section-action">
					<button className="button button--wide" onClick={ ()=> newOrder() }>Bestil vare</button>
				</div>
			</div>
		</div>
	); 
};

export default OrdersNoEntries;
