import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';
import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

import CountsNoEntries from "../components/CountsNoEntries";

const api_url = process.env.REACT_APP_GROED_API_URL;

export const Counts = () => {
	
	// Variables from History (react router)
	const history = useHistory();

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// STATE: Invetory data
	const [countData, setCountData] = useState([]);

	/**
  	 * Get data from DB
  	 */
	useEffect(() => {
		(async () => {

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get counts Aggregated by accounts (categories)
				const countAggregatedResponse = await axios.get(`${api_url}/countsAggregatedByAccounts`, { headers: { 'Authorization': `Bearer ${access_token}`}});
				const countData = countAggregatedResponse.data.counts;

				setIsLoading(false);
				setCountData(countData);

				
			} catch (e) {
        		console.error(e);
      		}

		})();		

	}, [getAccessTokenSilently]);

	// Create new Prep
  	const newCount = () => {
  		//@TODO Check if unsaved edits have been to an existing document. 

  		// Go to new ingredient URL. 
  		history.push("/inventory/counts/new");
  	}

  	const calculateItemTotalAmount = (item) => {

  		let amount = 0;

  		if (item.inventory_count_unit) {
			amount += item.inventory_count_unit.amount * item.inventory_count_unit.inventory_units_per_unit;
  		}

  		if (item.pack_count_unit) {
			amount += item.pack_count_unit.amount * item.pack_count_unit.inventory_units_per_unit;
  		}

  		if (item.custom_count_units) {
  			for (const custom_count_unit of item.custom_count_units) {
  				amount += custom_count_unit.amount * custom_count_unit.inventory_units_per_unit;
  			}
  		}

  		return amount;
  	}

  	const calculateItemTotalValue = (item) => {

  		const amount = calculateItemTotalAmount(item);
  		
  		let price_per_inventory_unit = 0;
  		if (item.item && item.item.price_per_pack) {
  			price_per_inventory_unit = item.item.price_per_pack / item.item.inventory_units_per_pack;
  		}
  	
  		const value = amount * price_per_inventory_unit;

  		return parseFloat(value.toFixed(2));
  	}

  	const calculateTotalCountValue = (items) => {

  		let value = 0;

  		for (const item of items) {
  			value += calculateItemTotalValue(item);
  		}

  		return parseFloat(value.toFixed(2));
  	}

  	const openTable = (item) => {

  		const countDataIndex = countData.findIndex(i => i._id === item._id);
  		
  		const newCountData = countData.map((item, i) => {
  			if (i !== countDataIndex) {
  				item.isOpen = false;
  			}
  			return item;
  		}); 
  		
  		if (countDataIndex !== -1) {
  			newCountData[countDataIndex].isOpen = newCountData[countDataIndex].isOpen ? false : true;	
  		}
  		
  		setCountData(newCountData);
  	}

	/**
  	 * Delete count
  	 */
	const deleteCount = async (data) => {
	
		try {
			// Get access token
			const access_token = await getAccessTokenSilently();

			let config = {
				headers: { 'Authorization': `Bearer ${access_token}`},
				data: { _id: data._id }
			}
			const axiosPromise = axios.delete(`${api_url}/count`, config);	
			const response = axiosPromise;

			return response;
			      
		} catch (e) {
			console.error(e);
		}
	}

  	/**
  	 * Handle Delete
  	 */
  	const handleDelete = async (event, count_id) => {
  		event.preventDefault();
  		
  		// Ask if user is sure they want to delete. No going back! 
  		if (window.confirm("Er du helt sikker på at du vil slette?")) {
  			setIsLoading(true);
  			// Send form data
  			const deleteResponse = await deleteCount({_id: count_id});

  			// If everything goes well, Redirect to prep front page
  			if (deleteResponse.status === 200) {

  				// Get access token
				const access_token = await getAccessTokenSilently();

  				// Get counts
  				const countAggregatedResponse = await axios.get(`${api_url}/countsAggregatedByAccounts`, { headers: { 'Authorization': `Bearer ${access_token}`}});
  				const countData = countAggregatedResponse.data.counts;
  				setIsLoading(false);
  				setCountData(countData);

  				//history.push("/inventory/counts");
  			}	
  		}

  	}

  	if (isLoading) {
  		return (
  			<div className="app-container">
  				<div className="app-sidebar">
  					<BrandBar/>
  					<Navigation page_title="Inventory"/>
  				</div>
  				
  				<div className="app-content">
  					<div className="page-content">
  						<Loading />
					</div>
				</div>
			</div>
  		);	
  	}

	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Inventory"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">
					
					{
						countData.length === 0 ? (
							<CountsNoEntries/>
						) : (
							<div className="page-content-inner">
								<div className="page-content-inner-centering--wide">
									<div className="breadcrumbs">/lager/optællinger</div>
									
									<div className="page-content-header">
										<h1 className="page-title">Optællinger</h1>

										<button type="button" className="button" onClick={ ()=> newCount() }>Ny optælling</button>
									</div>
									<div className="page-description">
										<p>
											Her kan du se en oversigt over de seneste 18 optællinger.
										</p>
									</div>


									<div className="counts-list-container">
										{
											countData.map((item, i) => {

												// Handle open state
												const open_class = item.isOpen ? 'count-list-item--open' : '';
												const completed_class = item.status === 'completed' ? 'counts-list-item--completed' : '';

												const type_string = item.type === 'before-opening' ? 'før åben' : 'efter luk';

												return (
													<div key={i} className={`counts-list-item ${open_class} ${completed_class}`}>
														<div className="counts-list-item-header">
															<div className="counts-list-item-icon-container">
																<div className="counts-list-item-icon">
																	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M445.4 104.4L292.6 16.2c-22.6-13-50.6-13-73.1 0L66.6 104.4C44 117.4 30 141.7 30 167.8v176.5c0 26 14 50.3 36.6 63.3l152.8 88.2c11.3 6.5 23.9 9.8 36.6 9.8 12.6 0 25.3-3.3 36.6-9.8l152.8-88.2c22.6-13 36.6-37.3 36.6-63.3V167.8c0-26.1-14-50.4-36.6-63.4zm-205.6-53c5-2.9 10.6-4.3 16.2-4.3s11.2 1.4 16.2 4.3l148.7 85.9-43.5 25.1-164.9-95.2 27.3-15.8zm107.9 202.2v-27l15.8-9.1v27l-15.8 9.1zM256 232.5L91.1 137.3l46.3-26.8 164.9 95.2-46.3 26.8zm76.9-44.4L168 92.9l14-8.1L346.9 180l-14 8.1zm-246 184.2c-10-5.8-16.2-16.5-16.2-28.1V172.5l164.9 95.2v190.4L86.9 372.3zm338.2 0l-148.7 85.9V267.8l40.8-23.6v62.2l76.9-44.5v-62.1l47.2-27.3v171.7c0 11.6-6.2 22.4-16.2 28.1z"/></svg>
																</div>
															</div>
															<div className="counts-list-item-title-block">
																<div className="counts-list-item-pretitle">

																	{
																		item.status === 'completed' && (
																			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512"><path d="M381.4 206.2h-3.8V143c0-67.3-54.8-122.1-122.1-122.1S133.4 75.7 133.4 143v63.2h-3.8c-22 0-39.9 17.9-39.9 39.9v209.1c0 20.3 16.5 36.7 36.7 36.7h258.1c20.3 0 36.7-16.5 36.7-36.7V246.1c.1-22-17.8-39.9-39.8-39.9zm-208-63.2c0-45.3 36.8-82.1 82.1-82.1s82.1 36.8 82.1 82.1v63.2H173.4V143zm207.9 308.9H129.7V246.2h251.5v205.7z"/><path d="M234.7 367v26.6c0 11.5 9.3 20.8 20.8 20.8s20.8-9.3 20.8-20.8V367c19.9-11.5 26.8-37 15.3-56.9s-37-26.8-56.9-15.3-26.8 37-15.3 56.9c3.7 6.4 8.9 11.6 15.3 15.3z"/></svg>
																		)
																	}

																	{
																		item.date_completed ? (
																			moment(item.date_completed).format('DD. MMM YYYY')
																		) : (
																			moment(item.date).format('DD. MMM YYYY')
																		)
																	}

																</div>
																<div className="counts-list-item-title">
																	<span onClick={() => openTable(item)}>{item.inventory.name}</span>

																	{
																		item.status === 'completed' ? (
																			<Link to={`/inventory/counts/${item._id}`} className="title-icon">
																				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256.5 419.4s-.1 0 0 0c-104.6 0-177.4-80.8-216.6-124.2-8.3-9.2-15.5-17.2-19.8-20.7-5.7-4.6-8.9-11.5-8.9-18.8 0-7.3 3.2-14.2 8.9-18.8 4.3-3.5 11.5-11.5 19.8-20.7C79.1 172.7 151.9 92 256.4 91.9h.1C361 92 433.9 172.7 473 216.1c8.3 9.2 15.5 17.2 19.8 20.7 5.7 4.6 8.9 11.5 8.9 18.8 0 7.3-3.2 14.2-8.9 18.8-4.3 3.5-11.5 11.5-19.8 20.7-39.1 43.5-112 124.3-216.5 124.3zM56.6 255.7c3.7 3.9 7.8 8.4 12.3 13.4 18.5 20.5 43.9 48.7 76 71.7 37.2 26.6 73.7 39.6 111.5 39.6s74.3-13 111.5-39.6c32.1-23 57.5-51.1 76-71.7 4.5-5 8.6-9.5 12.3-13.4-3.7-3.9-7.8-8.4-12.3-13.4-18.5-20.5-43.9-48.7-76-71.7C330.7 144 294.2 131 256.4 131s-74.3 13-111.5 39.6c-32.1 23-57.5 51.1-76 71.7-4.5 5-8.5 9.5-12.3 13.4z"/><path d="M256.4 351.8c-52.9 0-95.9-43-95.9-95.9s43-95.9 95.9-95.9 95.9 43 95.9 95.9-43 95.9-95.9 95.9zm0-152.7c-31.3 0-56.8 25.5-56.8 56.8s25.5 56.8 56.8 56.8 56.8-25.5 56.8-56.8-25.4-56.8-56.8-56.8z"/></svg>
																			</Link>	
																		) : (
																			<Fragment>
																				<Link to={`/inventory/counts/${item._id}`} className="title-icon">
																					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M147.5 367.4l10.4-99.5c1-9.4 5.4-18.5 12.5-25.6L375.7 36.9c10.3-10.3 24.5-15.5 38-13.9l1.7.2c39.4 4.9 68.8 33.2 75.1 72.2l1.3 8c2.1 12.9-2.7 26.6-12.7 36.6L274.7 344.4c-7 7-16.1 11.4-25.5 12.4l-101.7 10.6zM408.7 62.7c-.7 0-2.7.5-4.6 2.5L198.6 270.6c-.7.7-.9 1.3-1 1.5l-5.2 50.4 52.7-5.5c.1 0 .7-.3 1.3-.9l204.4-204.4c1-1 1.3-1.9 1.4-2.3l-1.2-7.7c-3.4-21.6-18.6-36.1-40.5-38.8l-1.7-.2h-.1z"/><path d="M429.2 490H55.9c-20.3 0-36.7-16.5-36.7-36.7v-373c0-20.3 16.5-36.7 36.7-36.7h178v40H59.1V450h366.8V300.5h40v152.7c0 20.3-16.5 36.8-36.7 36.8z"/></svg>
																				</Link>
																				<span className="title-icon" onClick={(event) => handleDelete(event, item._id)}>
																					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.6 100.9H360V60c0-17.7-14.3-32-32-32H184.1c-17.7 0-32 14.3-32 32v40.9H52.4c-11.4 0-20.7 9.3-20.7 20.7s9.3 20.7 20.7 20.7h39.1v307.1c0 19.1 15.5 34.6 34.6 34.6H386c19.1 0 34.6-15.5 34.6-34.6V142.3h39.1c11.4 0 20.7-9.3 20.7-20.7s-9.3-20.7-20.8-20.7zM193.4 69.4h125.2v31.5H193.4V69.4zm185.8 373.2H132.8V143.1h246.4v299.5z"/><path d="M301.5 415c11.4 0 20.7-9.3 20.7-20.7V214.1c0-11.4-9.3-20.7-20.7-20.7s-20.7 9.3-20.7 20.7v180.2c0 11.4 9.2 20.7 20.7 20.7zM210.5 415c11.4 0 20.7-9.3 20.7-20.7V214.1c0-11.4-9.3-20.7-20.7-20.7s-20.7 9.3-20.7 20.7v180.2c0 11.4 9.3 20.7 20.7 20.7z"/></svg>
																				</span>
																			</Fragment>
																		)	
																	}


																	
																</div>
																<div className="counts-list-item-subtitle">
																	{
																		item.status === 'completed' ? (
																			`Optælling foretaget kl. ${moment(item.date).format('HH.mm')} - ${moment(item.date_completed).format('HH.mm')} (${type_string})`
																		) : (
																			`Optælling begyndt kl. ${moment(item.date).format('HH.mm')} (${type_string})`
																		)
																	}

																	

																</div>
															</div>

															<div className="counts-list-item-open-trigger-container">
																<div className="counts-list-item-open-trigger" onClick={() => openTable(item)}>
																	<div className="counts-list-item-open-trigger-icon"></div>
																</div>
															</div>

														</div>
														
														<div className="counts-list-item-body">
															{
																item.accounts.map((account, i) => {
																	return (
																		<div key={i} className="counts-item-row">
																			<div className="counts-item-row-header">{account.account.name}</div>
																			<div className="counts-item-row-value">{account.total.toLocaleString("da-DK")} kr.</div>
																		</div>
																	)
																})	
															}
														</div>

														<div className="counts-list-item-footer">
															<div className="counts-item-row">
																<div className="counts-item-row-header">Samlet værdi</div>
																<div className="counts-item-row-value">{ item.total.toLocaleString("da-DK") } kr.</div>
															</div>
														</div>
													</div>
												)
											})
										}

									</div>
								</div>
							</div>
						)
					}
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Counts, {
  onRedirecting: () => <Loading />,
});
